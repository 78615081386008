import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./style.css";

import { getCurrentPartner } from "./api.js";
(async () => {
  try {
    const partner = await getCurrentPartner();
    createApp(App)
      .use(router)
      .use(store)
      .provide("primaryColor", partner.colors.primary == '' ? '#dc0f37' : partner.colors.primary == undefined ? '#dc0f37' : partner.colors.primary)
      .provide('logo', partner.logo)
      .mount("#app");
  } catch (err) {
    console.log("Erro ao pegar current color: ", err);
  }
})();

/* createApp(App).use(router).use(store).mount('#app') */
