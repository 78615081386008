<script setup>
import BotaoMenuAdmin from "./botao-menu-admin";
import { defineProps, computed, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";

const store = useStore();
const empresas = computed(() => {
  return store.state.empresaModule.empresas;
});
onMounted(() => {
  store.dispatch('empresaModule/getAllEmpresas')
})
const selectedEmpresa = ref(empresas.value[0])

const currentEmpresa = computed(() => {
  return selectedEmpresa.value;
})
watch(currentEmpresa, () => {
  store.dispatch('userModule/getAdminUserEmpresa', currentEmpresa.value.id)
});

const props = defineProps({
  button_class: {
    type: String,
    required: true
  },
  minifyed: {
    type: Boolean
  }
});
</script>

<template>
  <div class="contedo-menu-contedo" v-bind:class="String">
    <span class="divider-text">Admin</span>
    <div class="divider"></div>
    <div v-if="!minifyed" class="select-container">
      <span>Empresa: </span>
      <select id="opcoes" v-model="selectedEmpresa">
        <option
          v-for="empresa in empresas"
          :key="empresa.id"
          :value="empresa"
        >
          {{ empresa.name }}
        </option>
      </select>
    </div>
    <botao-menu-admin
      text="Empresas"
      icon="business-outline"
      rootClassName="botao-menu-root-class-name3"
      :class_name="button_class"
    ></botao-menu-admin>
    <botao-menu-admin
      text="Usuários"
      icon="person-add-outline"
      rootClassName="botao-menu-root-class-name2"
      :class_name="button_class"
    ></botao-menu-admin>
    <botao-menu-admin
      text="Oportunitys"
      icon="briefcase-outline"
      rootClassName="botao-menu-root-class-name1"
      :class_name="button_class"
    ></botao-menu-admin>
    <botao-menu-admin
      text="Cadência"
      icon="mail-outline"
      rootClassName="botao-menu-root-class-name1"
      :class_name="button_class"
    ></botao-menu-admin>
    <botao-menu-admin
      text="Helpdesk"
      src="https://api.iconify.design/material-symbols/support-agent-rounded.svg?color=white"
      rootClassName="botao-menu-root-class-name1"
      :class_name="button_class"
    ></botao-menu-admin>
  </div>
</template>

<style scoped>
.contedo-menu-contedo {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.divider-text {
  padding-left: 15px;
  color: white;
  font-size: 15px;
  padding-bottom: -10px;
}
.divider {
  height: 1px;
  width: 100%;
  background-color: white;
}
.select-container {
  background-color: white;
  height: 40px;
  width: fit-content;
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-color: white;
  border-radius: 10px;
  padding: 5px;
}
.select-container select {
  margin-left: 5px;
}
</style>
