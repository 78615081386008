<script setup>
import { computed, onMounted, ref } from "vue";
import InputText from "@/components/common/InputText.vue";
import { useStore } from "vuex";
import { updateProfile } from "firebase/auth";
import { auth } from "@/firebase";
import CardEmpresa from "../card-empresa.vue";

const store = useStore();
const nome = ref("");
const email = ref("");
const user = computed(() => {
  return store.state.userModule.currentUser
})

onMounted(() => {
  if(user.value != null) {
    nome.value = user.value.name;
  email.value = user.value.email
  }
})

const empresa = computed(() => {
  return store.state.empresaModule.currentUserEmpresa
});

const oportunitys = computed(() => {
  return store.state.oportunityModule.oportunitys
})

function getInitials(displayName) {
  const names = displayName.split(" ");
  if (names.length >= 2) {
    return names[0][0] + names[1][0];
  } else if (names.length === 1) {
    return names[0][0];
  } else {
    return "";
  }
}

const editUser = () => {
  if (email.value != "" || nome.value != "") {
    auth.currentUser == null
      ? store.dispatch("userModule/getCurrentUserOnFirebase")
      : console.log("O usuário está logado no firebase");
    if (nome.value != "") {
      store.dispatch("userModule/editUser", {
        name: nome.value,
        uid: user.value.uid,
      });
      updateProfile(auth.currentUser, { displayName: nome.value });
      nome.value = "";
    }
    if (email.value != "") {
      store.dispatch("userModule/getCurrentUserOnFirebase")
      store.commit('userModule/SET_EMAIL_UPDATE', email.value)
      store.commit('SET_POPUP_EMAIL', true)
      email.value = "";
    }
  }
};

const resetPassword = () => {
  if (auth.currentUser == null) {
    store.dispatch("userModule/getCurrentUserOnFirebase");
  }
  store.dispatch("userModule/updateUserPassword", auth.currentUser.email);
};
</script>

<template>
  <div v-bind:class="String">
    <div class="contedo-settings-group">
      <div class="contedo-settings-header">
        <div class="contedo-settings-display">
          <span class="titulo">Configurações da conta</span>
        </div>
      </div>
      <div class="contedo-settings-configuraes">
        <div class="contedo-settings-box">
          <div class="contedo-settings-default">
            <div class="contedo-settings-foto">
              <div class="contedo-settings-image">
                {{ getInitials(user.name) }}
              </div>
              <div class="contedo-settings-container1">
                <img
                  alt="image"
                  src="https://api.iconify.design/zondicons/camera.svg?color=white"
                  class="contedo-settings-image1"
                />
              </div>
            </div>
            <div class="contedo-settings-container2">
              <span class="contedo-settings-text01">{{ user.name }}</span>
              <span class="contedo-settings-text02">{{ user.email }}</span>
            </div>
          </div>
          <div class="user-config-plano">
            <span class="user-config-text02">{{ empresa.empresa_plan.name }}</span>
            <span class="user-config-text03">{{ `${oportunitys.length} de ${empresa.empresa_plan.credits} reuniões` }}</span>
            <div class="user-config-barrafundo">
              <div class="user-config-progresso"></div>
            </div>
          </div>
          <card-empresa :empresa="empresa"></card-empresa>
          <div class="change-info-container">
            <input-text label="Nome" v-model:value="nome"></input-text>
            <input-text label="E-mail" v-model:value="email"></input-text>
            <!-- <input-text
              label="Telefone"
              v-model:value="data.telefone"
            ></input-text> -->
            <div class="contedo-settings-alterar-senha">
              <div @click="resetPassword" class="contedo-settings-conteudo">
                <img
                  alt="image"
                  src="https://api.iconify.design/solar/password-outline.svg?color=%23737373"
                  class="contedo-settings-image02"
                />
                <span class="contedo-settings-text11">Alterar senha</span>
              </div>
            </div>
            <div class="contedo-settings-button">
              <div class="contedo-settings-buttonset">
                <button @click="editUser" class="contedo-settings-buttonaction">
                  <span class="contedo-settings-text12">Salvar alterações</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.contedo-settings-group {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-settings-header {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.contedo-settings-display {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.contedo-settings-configuraes {
  min-width: 300px;
  max-width: 700px;
  display: flex;
  align-self: center;
  margin-top: 60px;
  align-items: flex-start;
  justify-self: center;
  flex-direction: column;
}
.contedo-settings-box {
  gap: 15px;
  display: flex;
  flex-direction: column;
}
.contedo-settings-default {
  gap: 20px;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: row;
}
.contedo-settings-foto {
  flex: 0 0 auto;
  width: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.contedo-settings-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  background-color: white;
  border-radius: 50%;
  border-width: 2px;
  border-color: var(--color-theme-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: var(--color-theme-primary);
}
.contedo-settings-container1 {
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  align-items: center;
  border-radius: 100%;
  margin-bottom: -20;
  justify-content: center;
  background-color: var(--color-theme-primary);
  margin-bottom: -15px;
}
.contedo-settings-container1 :hover {
  cursor: pointer;
}
.contedo-settings-image1 {
  width: 60%;
  object-fit: cover;
}
.contedo-settings-container2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-settings-text01 {
  color: #737373;
  font-size: 38px;
  font-family: Fivo Sans Bold;
}
.contedo-settings-text02 {
  color: #737373;
}
.user-config-plano {
  gap: 5px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 15px;
  margin-top: 20px;
  position: relative;
  align-items: flex-start;
  border-color: var(--color-theme-primary);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.user-config-text02 {
  color: var(--color-theme-primary);
  font-size: 20px;
  font-family: "Fivo Sans regular";
}
.user-config-text03 {
  color: var(--color-theme-primary);
  font-size: 12px;
  margin-top: 10px;
  font-family: "Fivo Sans Medium";
}
.user-config-barrafundo {
  flex: 0 0 auto;
  width: 100%;
  height: 15px;
  display: flex;
  align-items: flex-start;
  border-radius: 15px;
  background-color: #e3e3e3;
}
.user-config-progresso {
  flex: 0 0 auto;
  width: 85%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: 15px;
  background-color: var(--color-theme-primary);
}
.change-info-container {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.change-info-labels {
  color: #737373;
  font-size: 18px;
  font-family: Fivo Sans Medium;
}
.contedo-settings-nome {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
}
.contedo-settings-label {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 120px;
  flex-direction: column;
}
.contedo-settings-text03 {
  color: #737373;
  font-size: 18px;
  font-family: Fivo Sans Bold;
}
.contedo-settings-input {
  flex: 0 0 auto;
  width: calc(100% - 120px);
  height: 35px;
  display: flex;
  align-items: flex-start;
  margin-left: 120px;
  border-color: #b3b3b3;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-gray-white);
}
.contedo-settings-text04 {
  color: #b3b3b3;
  font-size: 18px;
  align-self: center;
  font-family: Fivo Sans Medium;
  padding-left: 10px;
}
.contedo-settings-sobrenome {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
}
.contedo-settings-label1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 120px;
  flex-direction: column;
}
.contedo-settings-text05 {
  color: #737373;
  font-size: 18px;
  font-family: Fivo Sans Bold;
}
.contedo-settings-input1 {
  flex: 0 0 auto;
  width: calc(100% - 120px);
  height: 35px;
  display: flex;
  align-items: flex-start;
  margin-left: 120px;
  border-color: #b3b3b3;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-gray-white);
}
.contedo-settings-text06 {
  color: #b3b3b3;
  font-size: 18px;
  align-self: center;
  font-family: Fivo Sans Medium;
  padding-left: 10px;
}
.contedo-settings-email {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
}
.contedo-settings-label2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 120px;
  flex-direction: column;
}
.contedo-settings-text07 {
  color: #737373;
  font-size: 18px;
  font-family: Fivo Sans Bold;
}
.contedo-settings-input2 {
  flex: 0 0 auto;
  width: calc(100% - 120px);
  height: 35px;
  display: flex;
  align-items: flex-start;
  margin-left: 120px;
  border-color: #b3b3b3;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-gray-white);
}
.contedo-settings-text08 {
  color: #b3b3b3;
  font-size: 18px;
  align-self: center;
  font-family: Fivo Sans Medium;
  padding-left: 10px;
}
.contedo-settings-telefone {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
}
.contedo-settings-label3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 120px;
  flex-direction: column;
}
.contedo-settings-text09 {
  color: #737373;
  font-size: 18px;
  font-family: Fivo Sans Bold;
}
.contedo-settings-input3 {
  flex: 0 0 auto;
  width: calc(100% - 120px);
  height: 35px;
  display: flex;
  align-items: flex-start;
  margin-left: 120px;
  border-color: #b3b3b3;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-gray-white);
}
.contedo-settings-text10 {
  color: #b3b3b3;
  font-size: 18px;
  align-self: center;
  font-family: Fivo Sans Medium;
  padding-left: 10px;
}
.contedo-settings-alterar-senha {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
}
.contedo-settings-conteudo {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contedo-settings-conteudo :hover {
  cursor: pointer;
}
.contedo-settings-image2 {
  width: 35px;
  object-fit: cover;
}
.contedo-settings-text11 {
  color: #737373;
  font-size: 20px;
  font-family: Fivo Sans Bold;
  padding-left: 10px;
}
.contedo-settings-button {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.contedo-settings-buttonset :hover {
  cursor: pointer;
}
.contedo-settings-buttonset {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}
.contedo-settings-buttonaction {
  flex: 0 0 auto;
  width: 70%;
  height: 50px;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: var(--color-theme-primary);
}
.contedo-settings-text12 {
  color: #ffffff;
  font-size: 18px;
  align-self: center;
  text-align: center;
  font-family: Fivo Sans Medium;
}

@media (max-width: 767px) {
  .titulo {
    font-size: 35px;
  }
  .contedo-settings-box {
    align-items: center;
  }
  .change-info-container {
    width: 100%;
    align-items: center;
  }
  .contedo-settings-text01 {
    font-size: 30px;
  }
  .contedo-settings-button {
    gap: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .contedo-settings-buttonset {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 650px) {
  .contedo-settings-default {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 479px) {
  .titulo {
    font-size: 30px;
  }
  .contedo-settings-text01 {
    font-size: 25px;
  }
  .contedo-settings-container2 {
    width: 80%;
  }
}
</style>