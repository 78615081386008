<script setup>
import LogoMenu from "./logo-menu";
import ContedoMenu from "./contedo-menu";
import AppProfile from "./profile";
import { defineProps, ref, computed } from "vue";
import { signOut } from "firebase/auth";
import { auth } from "@/firebase/index";
import router from "@/router/index";
import buttonHideBar from "./button-hide-bar.vue";
import contedoMenuAdmin from "./contedo-menu-admin.vue";
import { useStore } from "vuex";

const store = useStore();

const currentUser = computed(() => {
  return store.state.userModule.currentUser;
});
const logout = () => {
  signOut(auth);
  localStorage.clear();
  router.push("/");
};

const minifyed = ref(false);

const toggleMenu = () => {
  const menu = document.querySelector("#menu-lateral");
  const button = document.querySelector(".button-hidebar-button-hidebar");
  const button_image = document.querySelector(".button-hidebar-image");
  if (menu.style.width === "80px") {
    menu.style = "width:300px; min-width:300px";
    button.style = "max-width: 290px";
    button_image.style = "transform: none";
    minifyed.value = false;
  } else {
    menu.style = "width:80px;min-width:80px";
    button.style = "max-width: 70px";
    button_image.style = "transform: rotate(180deg);";
    minifyed.value = true;
  }
};

const props = defineProps({
  image_alt1: {
    type: String,
    default: "image",
  },
  rootClassName: String,
  image_alt: {
    type: String,
    default: "image",
  },
  image_src: {
    type: String,
    default: "https://play.teleporthq.io/static/svg/default-img.svg",
  },
  image_src1: {
    type: String,
    default: "https://play.teleporthq.io/static/svg/default-img.svg",
  },
});
</script>

<template>
  <div v-bind:class="rootClassName">
    <logo-menu></logo-menu>
    <button-hide-bar @click="toggleMenu()"></button-hide-bar>
    <div class="menu-lateral-barra">
      <div class="menu-lateral-sections"><contedo-menu></contedo-menu></div>
      <div v-if="currentUser.status == 'ADMIN'" class="menu-lateral-sections">
        <contedo-menu-admin :minifyed="minifyed" button_class="botao-menu-text"></contedo-menu-admin>
      </div>
      <div class="menu-lateral-contentbotton">
        <app-profile
          v-if="!minifyed"
          id="app-profile"
          rootClassName="profile-root-class-name"
        ></app-profile>
        <div v-else class="profile-icon" @click="logout()">
          <ion-icon name="log-in-outline"></ion-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.menu-lateral-barra {
  gap: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 50px 0px 35px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.toggle-menu {
  padding: 0 0 0 15px;
}
.menu-lateral-sections {
  margin-top: 5px;
}
.menu-lateral-contentbotton {
  padding-bottom: 20px;
  gap: 50px;
  width: 100%;
  height: 100%;
  display: flex;
  max-height: 400px;
  flex-direction: column;
  justify-content: space-between;
}
.menu-lateral-acount {
  padding: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.menu-lateral-root-class-name {
  left: 0px;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  background-color: var(--color-theme-primary);
}
.menu-lateral-root-class-name1 {
  left: 0px;
}
.menu-lateral-root-class-name2 {
  left: 0px;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  background-color: var(--color-theme-primary);
}
.menu-lateral-root-class-name3 {
  left: 0px;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  background-color: var(--color-theme-primary);
}
.menu-lateral-root-class-name4 {
  left: 0px;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  background-color: var(--color-theme-primary);
}
.menu-lateral-root-class-name5 {
  left: 0px;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  background-color: var(--color-theme-primary);
}
.menu-lateral-root-class-name6 {
  left: 0px;
  height: 100%;
  max-height: 100%;
  min-height: 100vh;
  background-color: var(--color-theme-primary);
}
.profile-icon {
  flex: 0 0 auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.profile-icon :hover {
  cursor: pointer;
}
ion-icon {
  color: white;
  font-size: 35px;
}
</style>
