<script setup>
import InputText from "@/components/common/InputText.vue";
import InputTextPassword from "@/components/common/InputTextPassword.vue";
import { ref } from "vue";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "@/firebase/index";
import router from "@/router/index";
import { useStore } from "vuex";
import { updateProfile } from "firebase/auth";

const store = useStore();

const name = ref("");
const email = ref("");
const password = ref("");
const empresa_id = ref(null);

const cadastrar = () => {
  if (password.value != "" && email.value != "" && name.value != "" && empresa_id.value != null) {
    try {
      createUserWithEmailAndPassword(auth, email.value, password.value)
        .then(async (firebaseUser) => {
          updateProfile(firebaseUser.user, { displayName: name.value });
          localStorage.setItem("name", name.value);
          localStorage.setItem("email", firebaseUser.user.email);
          localStorage.setItem("uid", firebaseUser.user.uid);
          localStorage.setItem("accessToken", firebaseUser.user.accessToken);
          await store.dispatch("userModule/createUser", {
            name: name.value,
            email: email.value,
            uid: firebaseUser.user.uid,
            empresa_id: empresa_id.value
          });
          store.dispatch("setDialog", {
            message: "Usuário criado com sucesso",
            type: "success",
            icon: "person-add",
          });
          router.push("/dashboard/dash");
        })
        .catch((error) => {
          const e = error.message;
          const text =
            e == "Firebase: Error (auth/invalid-email)."
              ? "Erro: E-mail inválido."
              : e ==
                "Firebase: Password should be at least 6 characters (auth/weak-password)."
              ? "Erro: a senha deve ter ao menos 6 caracteres"
              : e == "Firebase: Error (auth/email-already-in-use)."
              ? "Erro: e-mail já em uso."
              : "Erro ao criar um novo usuário, tente novamente mais tarde.";
          store.dispatch("setDialog", {
            message: text,
            type: "danger",
            icon: "alert-circle-outline",
          });
        });
    } catch (e) {
      store.dispatch("setDialog", {
        message: "Erro ao criar novo usuário, tente novamente mais tarde",
        type: "danger",
        icon: "alert-circle-outline",
      });
    }
  } else {
    store.dispatch("setDialog", {
      message: "Erro! Todos os campos são obrigatórios",
      type: "danger",
      icon: "alert-circle-outline",
    });
  }
};
</script>

<template>
  <div class="login-container" :class="String">
    <div class="login-logo">
      <img
        alt="image"
        src="https://res.cloudinary.com/dd9puomgu/image/upload/v1695736645/dzhofokf4nj4kbtplv3x.svg"
        class="login-image"
      />
    </div>
    <div class="login-sectionname">
      <span class="login-text">Cadastre-se</span>
    </div>
    <div class="login-email">
      <input-text v-model:value="name" label="Nome completo"></input-text>
    </div>
    <div class="login-email">
      <input-text v-model:value="email" label="E-mail"></input-text>
    </div>
    <div class="login-senha">
      <input-text-password
        v-model:value="password"
        label="Senha"
      ></input-text-password>
    </div>
    <!-- <div class="contedo-helpdesk-container4">
      <select v-model="empresa_id" class="contedo-helpdesk-select Optionmenu">
        <option :value="7">EAD Criativa</option>
        <option :value="6">Fizzing</option>
        <option :value="5">Grape</option>
        <option :value="4">Profissas</option>
        <option :value="3">SLcomm</option>
      </select>
    </div> -->
    <div class="login-botao">
      <div class="login-botaoaction" @click="cadastrar">
        <span class="login-text5">Cadastre-se</span>
      </div>
    </div>
    <div class="login-cadastrar" @click="store.commit('SET_URL', 'login')">
      <span class="login-text4"> Já tem uma conta? Faça login! </span>
    </div>
  </div>
</template>

<style scoped>
.login-container {
  gap: 10px;
  width: 100%;
  display: flex;
  padding: 40px;
  position: relative;
  max-width: 450px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.login-logo {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.login-image {
  width: 100%;
  object-fit: cover;
  margin-left: -10px;
}
.login-sectionname {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 30px;
  align-items: flex-start;
  flex-direction: column;
}
.login-text {
  color: var(--dl-color-gray-black);
  font-size: 30px;
  font-family: Fivo Sans Bold;
}
.login-email {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 10px;
  align-items: flex-start;
  flex-direction: column;
}
.login-text1 {
  color: var(--color-theme-primary);
  font-size: 16px;
  font-family: Fivo Sans Bold;
}
.login-textinput {
  width: 100%;
  border-color: #737373;
}
.login-senha {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding-top: 10px;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-helpdesk-container4 {
  height: 40px;
  display: flex;
  width: 100%;
  align-self: flex-start;
  align-items: center;
  border-color: var(--color-theme-primary);
  border-width: 2px;
  border-radius: 10px;
  background-color: var(--dl-color-gray-white);
}
.contedo-helpdesk-select {
  width: 100%;
padding-left: 10px;
padding-right: 10px;
}
.login-text2 {
  color: var(--color-theme-primary);
  font-family: Fivo Sans Bold;
}
.login-textinput1 {
  width: 100%;
  border-color: #737373;
}
.login-logininfo {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.login-senhainfo {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 10px;
  flex-direction: row;
  justify-content: space-between;
}
.login-manterconectado {
  display: flex;
  align-items: center;
  padding-left: 10px;
  flex-direction: row;
  justify-content: space-between;
}
.login-checkbox {
  width: 18px;
  height: 18px;
}
.login-text3 {
  color: var(--color-theme-primary);
  font-size: 16px;
  font-family: Fivo Sans Bold;
  margin-left: 10px;
}
.login-alterarsenha {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.login-text4 {
  color: #737373;
  font-size: 16px;
  font-family: Fivo Sans Bold;
  margin-right: 10px;
}
.login-botao {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  flex-direction: row;
}
.login-botao :hover {
  cursor: pointer;
}
.login-cadastrar :hover {
  cursor: pointer;
}
.login-botaoaction {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: var(--color-theme-primary);
}
.login-text5 {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  align-self: center;
  text-align: center;
  font-family: Fivo Sans Medium;
}
@media (max-width: 957px) {
  .login-cadastrar {
    padding-top: 10px;
  }
}

@media (max-width: 479px) {
  .login-cadastrar {
    padding-top: 10px;
  }
  .login-container {
    padding: 20px;
  }
  .login-sectionname {
    margin-top: 20px;
  }
  .login-text {
    font-size: 20px;
  }
  .login-text3 {
    font-size: 12px;
  }
  .login-text4 {
    font-size: 12px;
  }
}
</style>
