<script setup>
import InputText from "@/components/common/InputText.vue";
import detalhesContatoReunioes from "./detalhes-contato-reunioes.vue";
import spinnerLoading from "@/components/common/spinner-loading.vue";
/* import timelineTituloData from "@/components/common/timeline-titulo-data.vue"; */
import { computed, onMounted, inject } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import router from "@/router";

const primaryColor = inject('primaryColor').replace('#', '')
const route = useRoute();
onMounted(() => {
  if (
    selectedContato.value == null ||
    selectedContato.value.id != route.params.id
  ) {
    store.dispatch("oportunityModule/getOportunityById", route.params.id);
  }
});

const selectedContato = computed(() => {
  const selectedContato = store.state.oportunityModule.selectedOportunity;
  const currentEmpresa = store.state.empresaModule.currentUserEmpresa.id;
  if (selectedContato != null) {
    if (selectedContato.empresa_id == currentEmpresa) {
      return selectedContato;
    } else {
      router.push("/dashboard/contatos");
      store.dispatch("setDialog", {
        message:
          "Acesso negado! O contato selecionado não faz parte de sua lista de contatos",
        type: "danger",
        icon: "alert-circle-outline",
      });
    }
  } else {
    return selectedContato;
  }
});
const loading = computed(() => {
  return store.state.oportunityModule.loading;
});

const store = useStore();
/* const props = defineProps({
  contato: {
    type: Object,
    required: true,
  },
  voltar: {
    type: Function,
    required: true,
  },
}); */

async function editOportunity() {
  await store.dispatch("oportunityModule/editOportunity", {
    op: {
      nome: selectedContato.value.nome,
      email: selectedContato.value.email,
      telefone: selectedContato.value.telefone,
      comments: selectedContato.value.comments
    },
    id: selectedContato.value.id,
  });
}

function voltar() {
  router.push("/dashboard/contatos");
}
</script>

<template>
  <div class="contedo-settings-container" v-bind:class="String">
    <div v-if="loading == true || selectedContato == null">
      <spinner-loading></spinner-loading>
    </div>
    <div v-else class="contedo-settings-group">
      <div class="contedo-settings-header">
        <div class="contedo-settings-display">
          <span class="contedo-settings-text titulo">{{
            selectedContato.nome
          }}</span>
        </div>
        <div class="acompanhar-chamado-voltar">
          <div class="acompanhar-chamado-container">
            <button
              @click="voltar"
              type="button"
              class="acompanhar-chamado-button"
            >
              <img
                :src="`https://api.iconify.design/material-symbols/arrow-back-rounded.svg?color=%23${primaryColor}`"
                class="acompanhar-chamado-image"
              />
              <span class="acompanhar-chamado-text02">Voltar</span>
            </button>
          </div>
        </div>
      </div>
      <div class="contedo-settings-configuraes">
        <div class="contedo-settings-box">
          <!-- <div class="contedo-settings-foto">
            <img
              alt="image"
              src="https://play.teleporthq.io/static/svg/default-img.svg"
              class="contedo-settings-image"
            />
          </div> -->
          <div class="contedo-settings-user-info">
            <div class="contedo-settings-container01">
              <span class="contedo-settings-text01">{{
                selectedContato.nome
              }}</span>
              <span class="contedo-settings-text02">{{
                selectedContato.email
              }}</span>
            </div>
          </div>
          <div class="contedo-settings-container02">
            <InputText
              label="Nome e Sobrenome"
              v-model:value="selectedContato.nome"
            ></InputText>
            <InputText
              label="E-mail"
              v-model:value="selectedContato.email"
            ></InputText>
            <InputText
              label="Telefone"
              v-model:value="selectedContato.telefone"
            ></InputText>
            <textarea class="observacoes-contato" v-model="selectedContato.comments" placeholder="Observações"></textarea>
            <div class="contedo-settings-button">
              <div class="contedo-settings-buttonset">
                <div
                  @click="editOportunity"
                  class="contedo-settings-buttonaction"
                >
                  <span class="contedo-settings-text12">Salvar alterações</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="contedo-settings-container03">
          <detalhes-contato-reunioes
            :contato="selectedContato"
          ></detalhes-contato-reunioes>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.contedo-settings-container {
  width: 100%;
  display: flex;
  overflow: auto;
  position: relative;
  min-height: 100%;
  justify-content: center;
  background-color: #f5f5f5;
}
.contedo-settings-group {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 5px;
}
.contedo-settings-header {
  width: 80%;
  display: flex;
  align-items: flex-start;
}
.acompanhar-chamado-voltar {
  flex: 0 0 auto;
  width: 20%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.acompanhar-chamado-button {
  color: #ffffff;
  height: 50px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  font-family: Fivo Sans Medium;
  border-color: var(--color-theme-primary);
  border-width: 0px;
  padding-left: 20px;
  border-radius: 7px;
  padding-right: 20px;
  flex-direction: row;
  background-color: var(--color-theme-primary);
}
.acompanhar-chamado-image {
  filter: brightness(0) invert(1);
  width: 25px;
  height: 30px;
  object-fit: cover;
  transition: 0.3s;
}
.acompanhar-chamado-button:hover {
  color: var(--color-theme-primary);
  border-width: 1px;
  background-color: var(--dl-color-gray-white);
}
.acompanhar-chamado-button:hover .acompanhar-chamado-image {
  filter: none;
}
.acompanhar-chamado-container {
  display: flex;
  position: relative;
}
.acompanhar-chamado-text02 {
  font-size: 18px;
  align-self: center;
  margin-top: 1px;
  transition: 0.3s;
  align-items: center;
  margin-left: 5px;
}
.acompanhar-chamado-text02:hover {
  color: var(--color-theme-primary);
}
.contedo-settings-display {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.contedo-settings-text {
  color: var(--color-theme-primary);
  font-size: 45px;
  font-family: Fivo Sans Medium;
}
.contedo-settings-configuraes {
  gap: 20px;
  width: 100%;
  display: flex;
  align-self: center;
  margin-top: 50px;
  align-items: flex-start;
  justify-self: center;
  flex-direction: row;
}
.contedo-settings-box {
  gap: 10px;
  width: 40%;
  height: 100%;
  display: flex;
  padding: 40px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.contedo-settings-foto {
  flex: 0 0 auto;
  width: 70%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.contedo-settings-image {
  width: 100%;
  max-width: 200px;
  object-fit: cover;
  border-radius: 50%;
}
.contedo-settings-image01 {
  width: 60%;
  object-fit: cover;
}
.contedo-settings-user-info {
  width: 100%;
  gap: 20px;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-self: center;
  flex-direction: row;
}
.contedo-settings-container01 {
  flex: 0 0 auto;
  width: calc(100% - 5px);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-settings-text01 {
  word-wrap: break-word;
  display: block;
  max-width: 100%;
  color: #737373;
  font-size: 35px;
  font-family: Fivo Sans Medium;
}
.contedo-settings-text02 {
  color: #737373;
}
.contedo-settings-container02 {
  gap: 10px;
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.observacoes-contato {
  color: var(--color-main);
  width: 100%;
  outline: none;
  height: fit-content;
  min-height: 100px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--color-theme-primary);
}
.observacoes-contato:focus {
  border: 1px solid var(--color-theme-primary);
}
.contedo-settings-alterar-senha {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
}
.contedo-settings-conteudo {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.contedo-settings-image02 {
  width: 35px;
  object-fit: cover;
}
.contedo-settings-text11 {
  color: #737373;
  font-size: 20px;
  font-family: Fivo Sans Medium;
  padding-left: 10px;
}
.contedo-settings-button {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
}
.contedo-settings-buttonset {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.contedo-settings-buttonaction {
  flex: 0 0 auto;
  width: 70%;
  height: 50px;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: var(--color-theme-primary);
}
.contedo-settings-buttonaction :hover {
  cursor: pointer;
}
.contedo-settings-text12 {
  color: #ffffff;
  font-size: 18px;
  align-self: center;
  text-align: center;
  font-family: Fivo Sans Medium;
}
.contedo-settings-container03 {
  gap: 20px;
  width: 60%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-settings-container05 {
  width: 100%;
  height: auto;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  overflow-y: auto;
  align-items: flex-start;
  border-radius: 15px;
  padding: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
#timeline {
  visibility: hidden;
  display: none;
}
.contedo-settings-content-left {
  flex: 0 0 auto;
  width: 45%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-settings-container06 {
  gap: 5px;
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-self: flex-end;
  align-items: flex-end;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.contedo-settings-text26 {
  color: #737373;
  font-size: 14px;
  text-align: right;
  font-family: "Fivo Sans Medium";
}
.contedo-settings-text27 {
  color: var(--color-theme-primary);
  font-size: 22px;
  text-align: right;
  font-family: "Fivo Sans Medium";
}
.contedo-settings-text28 {
  color: #737373;
  font-size: 14px;
  text-align: right;
  font-family: "Fivo Sans Medium";
}
.contedo-settings-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.contedo-settings-container08 {
  gap: 5px;
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-end;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.contedo-settings-text29 {
  color: #737373;
  font-size: 14px;
  text-align: right;
  font-family: "Fivo Sans Medium";
}
.contedo-settings-text30 {
  color: var(--color-theme-primary);
  font-size: 22px;
  text-align: right;
  font-family: "Fivo Sans Medium";
}
.contedo-settings-text31 {
  color: #737373;
  font-size: 14px;
  text-align: right;
  font-family: "Fivo Sans Medium";
}
.contedo-settings-timeline {
  flex: 0 0 auto;
  width: 5%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.contedo-settings-step1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.contedo-settings-container09 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-radius: 100%;
  background-color: var(--color-theme-primary);
}
.contedo-settings-container10 {
  flex: 0 0 auto;
  width: 5px;
  height: 100px;
  margin: 2px;
  display: flex;
  align-items: flex-start;
  background-color: #d3d3d3;
}
.contedo-settings-step2 {
  display: flex;
  margin-top: -2;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.contedo-settings-container11 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-radius: 100%;
  background-color: var(--color-theme-primary);
}
.contedo-settings-container12 {
  flex: 0 0 auto;
  width: 5px;
  height: 100px;
  margin: 2px;
  display: flex;
  align-items: flex-start;
  background-color: #d3d3d3;
}
.contedo-settings-step3 {
  display: flex;
  margin-top: -2px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.contedo-settings-container13 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-radius: 100%;
  background-color: var(--color-theme-primary);
}
.contedo-settings-container14 {
  flex: 0 0 auto;
  width: 5px;
  height: 100px;
  margin: 2px;
  display: flex;
  align-items: flex-start;
  background-color: #d3d3d3;
}
.contedo-settings-step4 {
  display: flex;
  margin-top: -2px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.contedo-settings-container15 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-radius: 100%;
  background-color: var(--color-theme-primary);
}
.contedo-settings-content-right {
  flex: 0 0 auto;
  width: 45%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-settings-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.contedo-settings-container17 {
  gap: 5px;
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.contedo-settings-text32 {
  color: #737373;
  font-size: 14px;
  font-family: "Fivo Sans Medium";
}
.contedo-settings-text33 {
  color: var(--color-theme-primary);
  font-size: 22px;
  font-family: "Fivo Sans Medium";
}
.contedo-settings-text34 {
  color: #737373;
  font-size: 14px;
  font-family: "Fivo Sans Medium";
}
.contedo-settings-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.contedo-settings-container19 {
  gap: 5px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.contedo-settings-text35 {
  color: #737373;
  font-size: 14px;
  font-family: "Fivo Sans Medium";
}
.contedo-settings-text36 {
  color: var(--color-theme-primary);
  font-size: 22px;
  font-family: "Fivo Sans Medium";
}
.contedo-settings-text37 {
  color: #737373;
  font-size: 14px;
  font-family: "Fivo Sans Medium";
}
@media (max-width: 1440px) {
  .contedo-settings-box {
    position: relative;
  }
  .contedo-settings-foto {
    width: 60%;
    align-self: center;
  }
  .contedo-settings-user-info {
    margin-top: 20px;
  }
}
@media (max-width: 1220px) {
  .contedo-settings-container05 {
    padding: 15px;
    overflow-y: scroll;
  }
  .contedo-settings-content-right {
    margin-bottom: 10px;
  }
  .contedo-settings-text01 {
    font-size: 25px;
  }
}
@media (max-width: 1040px) {
  .contedo-settings-box {
    width: 30%;
  }
  .contedo-settings-container03 {
    width: 70%;
  }
  .contedo-settings-text01 {
    font-size: 15px;
  }
}
@media (max-width: 950px) {
  .contedo-settings-text {
    font-size: 35px;
  }
  .contedo-settings-configuraes {
    flex-wrap: wrap;
  }
  .contedo-settings-box {
    width: 100%;
  }
  .contedo-settings-container03 {
    width: 100%;
  }
  .contedo-settings-text01 {
    font-size: 30px;
  }
}
@media (max-width: 657px) {
  .contedo-settings-header {
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .acompanhar-chamado-voltar {
    width: 100%;
    align-items: flex-start;
  }
  .contedo-settings-box {
    padding: 15px;
  }
  #timeline {
    visibility: visible;
    display: flex;
  }
  .contedo-settings-content-left {
    visibility: hidden;
    display: none;
  }
  .contedo-settings-timeline {
    visibility: hidden;
    display: none;
  }
  .contedo-settings-content-right {
    visibility: hidden;
    display: none;
  }
}
@media (max-width: 479px) {
  .contedo-settings-text {
    font-size: 30px;
  }
  .contedo-settings-text01 {
    font-size: 18px;
  }
  .contedo-settings-text02 {
    font-size: 15px;
  }
  .contedo-settings-button {
    width: 100%;
  }
  .contedo-settings-buttonset {
    width: 100%;
  }
  .contedo-settings-buttonaction {
    width: 100%;
  }
  .contedo-settings-conteudo {
    flex-direction: column;
  }
}
</style>
