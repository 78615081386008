import axios from "axios";

export async function getCurrentPartner() {
  try {
    let origin = window.location.hostname;
    return await axios
      .get(
        "https://flash-growth-db.azurewebsites.net/partners/by-origin/?origin=" +
          origin,
        { headers: { Authorization: "VjNMgnNt9JaRtDc2tcPDWdGzni13" } }
      )
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return { colors: { primary: '' }, logo: '' }
      });
  } catch (erro) {
    console.error("Erro ao obter dados de personalização:", erro);
    throw erro;
  }
}
