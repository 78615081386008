import OportunityController from "@/controllers/OportunityController";
import Oportunity from "@/models/Oportunity";

const opController = new OportunityController();

const oportunityStore = {
  namespaced: true,
  state: () => ({
    oportunitys: [],
    selectedOportunity: null,
    loading: false,
    popupEvaluation: false,
    currentEvaluation: null,
    popupCriarOportunity: false,
  }),
  mutations: {
    SET_OPORTUNITYS(state, payload) {
      state.oportunitys = payload;
    },
    SET_SELECTED_OPORTUNITY(state, payload) {
      state.selectedOportunity = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_POPUP_EVALUATION(state, payload) {
      state.popupEvaluation = payload;
    },
    SET_CURRENT_EVALUATION(state, payload) {
      state.currentEvaluation = payload;
    },
    SET_POPUP_CRIAR_OPORTUNITY(state, payload) {
      state.popupCriarOportunity = payload;
    },
  },
  actions: {
    setPopupEvaluation({ commit }, payload) {
      commit("SET_CURRENT_EVALUATION", payload.evaluation);
      commit("SET_POPUP_EVALUATION", payload.value);
    },
    async createOportunity({ dispatch }, payload) {
      try {
        await opController.createOportunity(payload);
        dispatch(
          "setDialog",
          {
            message:
              "Oportunity criada com sucesso!",
            type: "success",
            icon: "briefcase-outline",
          },
          { root: true }
        );
        dispatch('getAllOportunity')
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message:
              "Não foi possível criar uma nova oportunity, por favor tente novamente mais tarde",
            type: "danger",
            icon: "alert-circle-outline",
          },
          { root: true }
        );
      }
    },
    async saveEvaluation({ dispatch }, payload) {
      try {
        await opController.updateOportunity(payload.id, payload.op);
        dispatch(
          "setDialog",
          {
            message: "Avaliação salva com sucesso!",
            type: "success",
            icon: "checkmark-circle-outline",
          },
          { root: true }
        );
        location.reload();
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message:
              "Não foi possível salvar a avaliação do lead, por favor tente novamente mais tarde",
            type: "danger",
            icon: "alert-circle-outline",
          },
          { root: true }
        );
      }
    },
    async editOportunity({ dispatch }, payload) {
      try {
        await opController.updateOportunity(payload.id, payload.op);
        dispatch(
          "setDialog",
          {
            message: "As informações do contato foram atualizadas com sucesso!",
            type: "success",
            icon: "checkmark-circle-outline",
          },
          { root: true }
        );
        location.reload();
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message:
              "Não foi possível alterar as informações do contato, por favor tente novamente mais tarde",
            type: "danger",
            icon: "alert-circle-outline",
          },
          { root: true }
        );
      }
    },
    async getAllOportunity({ commit, dispatch }) {
      try {
        const ops = await opController.getAllOportunitys();
        let finalOps = ops.data.map((op) => {
          return new Oportunity(op);
        });
        commit("SET_OPORTUNITYS", finalOps);
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message:
              "Não foi possível pegar os dados de oportunitys, por favor tente novamente mais tarde",
            type: "danger",
            icon: "alert-circle-outline",
          },
          { root: true }
        );
      }
    },
    async getOportunityById({ dispatch, commit }, payload) {
      commit("SET_LOADING", true);
      try {
        const op = await opController.getOportunityById(payload).then((res) => {
          return new Oportunity(res.data);
        });
        commit("SET_SELECTED_OPORTUNITY", op);
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message:
              "Naõ foi possível pegar os dados da oportunity selecionada, por favor tente novamente mais tarde",
            type: "danger",
            icon: "alert-circle-outline",
          },
          { root: true }
        );
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async getOportunitysByEmpresaId({ dispatch, commit }, payload) {
      try {
        commit("SET_LOADING", true);
        const ops = await opController.getOportunityByEmpresaId(payload);
        let finalOps = ops.data.map((op) => {
          return new Oportunity(op);
        });
        commit("SET_OPORTUNITYS", finalOps);
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message:
              "Não foi possível pegar as oportunitys da empresa selecionada, por favor tente novamente mais tarde",
            type: "danger",
            icon: "alert-circle-outline",
          },
          { root: true }
        );
      } finally {
        commit("SET_LOADING", false);
      }
    },
  },
};

export default oportunityStore;
