<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { updateProfile } from "firebase/auth";
import InputText from "@/components/common/InputText.vue";
import { auth } from "@/firebase";

const store = useStore();
const name = ref("");
const isVisible = computed(() => {
  return store.state.popupUser;
});

const togglePopup = () => {
  store.commit("SET_POPUP_USER", false);
};

const updateDisplayname = () => {
  if (auth.currentUser == null) {
    store.dispatch("userModule/getCurrentUserOnFirebase");
  }
  if (name.value != "") {
    updateProfile(auth.currentUser, { displayName: name.value });
    togglePopup();
    store.dispatch("setDialog", {
      message: "Nome de usuário atualizado com sucesso.",
      type: "success",
      icon: "checkmark-circle-outline",
    });
  } else {
    store.dispatch("setDialog", {
      message: "Erro! O nome não pode ser nulo",
      type: "danger",
      icon: "alert-circle-outline",
    });
  }
};
</script>

<template>
  <div class="modal" v-if="isVisible">
    <div class="novo-chamado-pop-upintro">
      <div class="novo-chamado-barra">
        <span class="novo-chamado-novo-chamado"
          >Ops! Parece que ainda não sabemos seu nome.</span
        >
        <button @click="togglePopup">
          <img
            alt="image"
            src="https://api.iconify.design/icon-park-outline/close-one.svg?color=white"
            class="novo-chamado-image"
          />
        </button>
      </div>
      <div class="novo-chamado-entrada">
        <div class="novo-chamado-container">
          <input-text v-model:value="name" label="Nome completo"></input-text>
        </div>
        <div class="novo-chamado-boto">
          <button
            @click="updateDisplayname"
            type="button"
            class="novo-chamado-button"
          >
            <span class="novo-chamado-text5">Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 900;
}
.novo-chamado-pop-upintro {
  width: 100%;
  height: 350px;
  margin: 0px;
  display: flex;
  position: relative;
  max-width: 800px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  max-height: 600px;
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.novo-chamado-barra {
  flex: 0 0 auto;
  width: 100%;
  height: 40px;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: center;
  background-color: var(--color-theme-primary);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.novo-chamado-novo-chamado {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  text-align: center;
  font-family: "Fivo Sans Medium";
}
.novo-chamado-image {
  top: 8px;
  right: 0px;
  width: 25px;
  position: absolute;
  justify-self: flex-end;
  margin-right: 10px;
}
.novo-chamado-entrada {
  flex: 0 0 auto;
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  flex-direction: column;
  padding-bottom: 30px;
  justify-content: flex-start;
}
.novo-chamado-text {
  font-size: 18px;
  font-family: "Fivo Sans Medium";
}
.novo-chamado-tipo-de-chamado {
  gap: 10px;
  width: 100%;
  display: flex;
  margin-top: 30px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.novo-chamado-container {
  gap: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.novo-chamado-text1 {
  color: #737373;
  font-family: "Fivo Sans Medium";
}
.novo-chamado-select {
  padding: 5px;
  border-color: #cd0f37;
  border-width: 1px;
  border-radius: 7px;
}
.novo-chamado-textarea {
  width: 100%;
  height: 200px;
  margin-top: 30px;
}
.novo-chamado-priority {
  gap: 15px;
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  align-items: center;
}
.novo-chamado-urgente {
  gap: 5px;
  display: flex;
  align-items: center;
}
.novo-chamado-text2 {
  font-family: "Fivo Sans Medium";
}
.novo-chamado-pouco-urgente {
  gap: 5px;
  display: flex;
  align-items: center;
}
.novo-chamado-text3 {
  font-family: "Fivo Sans Medium";
}
.novo-chamado-nao-urgente {
  gap: 5px;
  display: flex;
  align-items: center;
}
.novo-chamado-text4 {
  font-family: "Fivo Sans Medium";
}
.novo-chamado-boto {
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 40px;
  margin: auto;
  display: flex;
  padding: 0px;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.novo-chamado-button {
  color: #ffffff;
  height: 50px;
  display: flex;
  transition: 0.3s;
  font-family: Fivo Sans Medium;
  border-color: var(--color-theme-primary);
  border-width: 0px;
  padding-left: 20px;
  border-radius: 7px;
  padding-right: 20px;
  flex-direction: row;
  background-color: var(--color-theme-primary);
}
.novo-chamado-button:hover {
  color: var(--color-theme-primary);
  border-width: 1px;
  background-color: var(--dl-color-gray-white);
}
.novo-chamado-text5 {
  font-size: 20px;
  align-self: center;
  margin-top: 1px;
  transition: 0.3s;
  align-items: center;
  margin-left: 5px;
}
.novo-chamado-text5:hover {
  color: var(--color-theme-primary);
}
@media (max-width: 957px) {
  .novo-chamado-pop-upintro {
    margin: 0px;
    display: flex;
  }
}
@media (max-width: 767px) {
  .novo-chamado-pop-upintro {
    display: flex;
  }
}
@media (max-width: 479px) {
  .novo-chamado-pop-upintro {
    display: flex;
    max-height: 800px;
  }
  .novo-chamado-entrada {
    padding: 20px;
  }
}
</style>
