<script setup>
import InputTextPassword from "@/components/common/InputTextPassword.vue";
import toastAlert from "@/components/common/toast-alert.vue";
import { ref } from "vue";
import { useStore } from "vuex";

const pass = ref("");
const pass2 = ref("");
const store = useStore();

const resetPassword = () => {
  if (pass.value == pass2.value) {
    const urlParams = new URLSearchParams(window.location.search);
    const oobCode = urlParams.get("oobCode");
    store.dispatch("userModule/resetUserPassword", {
      password: pass.value,
      oobCode: oobCode,
    });
  } else {
    store.dispatch("setDialog", {
      message: "Erro: as senhas devem ser iguais.",
      type: "danger",
      icon: "alert-circle-outline",
    });
  }
};
</script>

<template>
  <div class="flash-growth-login-container">
    <toast-alert></toast-alert>
    <div class="flash-growth-login-content-left">
      <div class="flash-growth-login-imagehero">
        <img
          alt="image"
          src="https://res.cloudinary.com/dd9puomgu/image/upload/v1694793656/l9mubmkcl3gb4g66m9cb.svg"
          class="flash-growth-login-image"
        />
      </div>
    </div>
    <div class="flash-growth-login-content-right">
      <div class="recuperar-senha-container" v-bind:class="String">
        <div class="recuperar-senha-logo">
          <img
            alt="image"
            src="https://res.cloudinary.com/dd9puomgu/image/upload/v1695736645/dzhofokf4nj4kbtplv3x.svg"
            class="recuperar-senha-image"
          />
        </div>
        <div class="recuperar-senha-recuperacao">
          <span class="recuperar-senha-text">Redefinição de senha</span>
        </div>
        <div class="recuperar-senha-senha">
          <input-text-password
            v-model:value="pass"
            label="Senha"
          ></input-text-password>
        </div>
        <div class="recuperar-senha-senha">
          <input-text-password
            v-model:value="pass2"
            label="Confirme a senha"
          ></input-text-password>
        </div>
        <div @click="resetPassword" class="recuperar-senha-botao">
          <span class="recuperar-senha-text3">Redefinir senha</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.flash-growth-login-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: row;
  background-color: F5F5F5;
}
.flash-growth-login-content-left {
  flex: 0 0 auto;
  width: 50%;
  height: 100vh;
  display: flex;
  padding: 100px;
  align-self: center;
  visibility: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: F5F5F5;
}
.flash-growth-login-imagehero {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  min-width: 500px;
  align-items: center;
  flex-direction: column;
}
.flash-growth-login-image {
  margin-top: -50px;
  object-fit: cover;
}
.flash-growth-login-content-right {
  flex: 0 0 auto;
  width: 50%;
  height: 100vh;
  display: flex;
  padding: 40px;
  align-self: center;
  align-items: center;
  justify-content: center;
  background-color: var(--color-theme-primary);
}
@media (max-width: 957px) {
  .flash-growth-login-content-left {
    visibility: hidden;
  }
  .flash-growth-login-content-right {
    width: 100%;
    position: absolute;
    margin-right: 0px;
  }
  .login-cadastrar {
    padding-top: 10px;
  }
}
@media (max-width: 479px) {
  .flash-growth-login-content-right {
    width: 100%;
    padding: 40px;
  }
  .recuperar-senha-text {
    font-size: 27px;
  }
  .login-cadastrar {
    padding-top: 10px;
  }
}
.recuperar-senha-container {
  gap: 20px;
  width: 100%;
  display: flex;
  padding: 40px;
  position: relative;
  max-width: 450px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.recuperar-senha-logo {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.recuperar-senha-image {
  width: 100%;
  object-fit: cover;
  margin-left: -10px;
}
.recuperar-senha-recuperacao {
  gap: 30px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.recuperar-senha-text {
  color: var(--dl-color-gray-black);
  font-size: 30px;
  font-family: Fivo Sans Bold;
}
.recuperar-senha-text1 {
  color: #737373;
  font-family: Fivo Sans Medium;
}
.recuperar-senha-senha {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.login-text4 {
  color: #737373;
  font-size: 16px;
  font-family: Fivo Sans Bold;
  margin-right: 10px;
}
.recuperar-senha-botao {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: var(--color-theme-primary);
}
.recuperar-senha-botao:active {
  background-color: #3aaa9b;
}
.recuperar-senha-botao:hover {
  cursor: pointer;
}
.login-cadastrar :hover {
  cursor: pointer;
}
.recuperar-senha-text3 {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  align-self: center;
  text-align: center;
  transition: 0.3s;
  font-family: Fivo Sans Medium;
}
.recuperar-senha-text3:active {
  color: var(--dl-color-gray-white);
}
</style>
