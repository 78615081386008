<script setup>
import { inject } from 'vue';

const primaryColor = inject("primaryColor").replace('#', '')
</script>

<template>
  <div class="button-hidebar-button-hidebar" style="max-width: 290px">
    <div class="button-hidebar-hide">
      <img
        :alt="image_alt2"
        :src="`https://api.iconify.design/ion/arrow-back.svg?color=%23${primaryColor}`"
        class="button-hidebar-image"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonHidebar',
  props: {
    image_alt2: {
      type: String,
      default: 'image',
    },
    rootClassName: String,
  },
}
</script>

<style scoped>
.button-hidebar-button-hidebar {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  margin-top: 70px;
  z-index: 100;
  transition: all ease 0.3s;
}
.button-hidebar-hide {
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.15);
  align-items: flex-end;
  margin-right: -14px;
  border-radius: 100%;
  justify-content: flex-end;
  background-color: var(--dl-color-gray-white);
}
.button-hidebar-image {
  width: 200px;
  object-fit: cover;
  transition: all ease 0.3s;
}

@media(max-width: 1440px) {
  .button-hidebar-root-class-name {
    top: 100px;
    right: 20px;
    position: static;
    margin-left: 20px;
    margin-right: 0px;
  }
  .button-hidebar-root-class-name1 {
    top: 100px;
    right: 20px;
    position: static;
    margin-left: 20px;
    margin-right: 0px;
  }
}
</style>
