<script setup>
import { defineProps, ref, computed, inject } from "vue";
import timelineTituloData from "@/components/common/timeline-titulo-data.vue";
import { useStore } from "vuex";
import moment from "moment";
import statusBadge from "@/components/common/status-badge.vue";

const primaryColor = inject("primaryColor").replace("#", "");
const store = useStore();

const props = defineProps({
  chamadoId: {
    type: String,
    required: true,
  },
  voltar: {
    type: Function,
    required: true,
  },
  type: {
    type: String,
  }
});

const currentUser = computed(() => {
  return store.state.userModule.currentUser;
});

const currentChamado = computed(() => {
  let chamado = props.type == 'user' ? store.state.helpdeskModule.selectedUserHelpdesk.find(
    (item) => item.id == props.chamadoId
  ) : store.state.helpdeskModule.chamados.find(
    (item) => item.id == props.chamadoId
  )
  let finalMessages = [];
  for (var i = 0; i < chamado.messages.length; i++) {
    let title =
      i == 0
        ? "Usuário abriu o chamado"
        : `${chamado.messages[i].user_type} respondeu ao chamado`;
    finalMessages.push({
      timelineTitle: title,
      data: moment(chamado.messages[i].creation_date)
        .locale("pt")
        .format("LLLL"),
      title: chamado.messages[i].user_type,
      text: chamado.messages[i].message,
    });
  }
  /* finalMessages = chamado.messages.map((message) => {
    return {
      title: message.user_type,
      text: message.message,
      data: moment(message.creation_date).locale("pt").format("LLLL"),
      timelineTitle:
        i == 0
          ? "Usuário abriu o chamado"
          : `${chamado.messages[i].user_type} respondeu ao chamado`,
    };
  }); */
  return { messages: finalMessages, status: chamado.status };
});

const newMessage = ref("");

const addMessage = () => {
  try {
    const now = Date.now();
    store.dispatch("helpdeskModule/addMessage", {
      docId: props.chamadoId,
      message: {
        creation_date: now,
        message: newMessage.value,
        user_id: localStorage.getItem("uid"),
        user_type: currentUser.value.status == "ADMIN" ? "Suporte" : "Usuário",
      },
    });
  } catch (e) {
    console.log(e);
  } finally {
    newMessage.value = "";
  }
};
</script>

<template>
  <div class="acompanhar-chamado-pop-upintro" v-bind:class="String">
    <div class="acompanhar-chamado-chamados">
      <div class="acompanhar-chamado-chamado">
        <p class="acompanhar-chamado-text titulo">Chamado {{ chamadoId }}</p>
        <status-badge :status="currentChamado.status"></status-badge>
      </div>
      <div class="acompanhar-chamado-voltar">
        <div class="acompanhar-chamado-container">
          <button
            @click="props.voltar"
            type="button"
            class="acompanhar-chamado-button"
          >
            <img
              :src="`https://api.iconify.design/material-symbols/arrow-back-rounded.svg?color=%23${primaryColor}`"
              class="acompanhar-chamado-image"
            />
            <span class="acompanhar-chamado-text02">Voltar</span>
          </button>
        </div>
      </div>
    </div>
    <div class="acompanhar-chamado-linha"></div>
    <div class="acompanhar-chamado-container1">
      <div class="acompanhar-chamado-chat">
        <div class="mensagens">
          <div
            class="acompanhar-chamado-mensagem-enviada"
            v-for="(item, index) in currentChamado.messages"
            :key="item.data"
          >
            <div class="mensagem-container">
              <div class="acompanhar-chamado-mensagem-hora">
                <div class="acompanhar-chamado-dev-flash-growth">
                  <div class="acompanhar-chamado-container2">
                    <img
                      alt="image"
                      src="https://res.cloudinary.com/dd9puomgu/image/upload/v1696944693/fxpt7oz7c4j0extebst1.svg"
                      class="acompanhar-chamado-image1"
                    />
                    <span class="acompanhar-chamado-text03">{{
                      item.title
                    }}</span>
                  </div>
                  <span class="acompanhar-chamado-text04">{{ item.data }}</span>
                </div>
                <div class="acompanhar-chamado-mensagem-dev">
                  <p class="acompanhar-chamado-text05">{{ item.text }}</p>
                </div>
              </div>
              <div class="contedo-dashboard-timeline">
                <div class="contedo-dashboard-point">
                  <img
                    alt="image_alt3"
                    src="https://api.iconify.design/tabler/point-filled.svg?color=white"
                    class="contedo-dashboard-image5"
                  />
                </div>
                <div :final="index == currentChamado.messages.length - 1 ? true : false" class="contedo-timeline-line"></div>
              </div>
            </div>
            <div class="contedo-dashboard-news">
              <div class="contedo-dashboard-container12">
                <span class="textboxlead">{{ item.timelineTitle }}</span>
                <span class="Textbox">{{ item.sub }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="acompanhar-chamado-linha1"></div>
      </div>
      <textarea
        placeholder="Deixe aqui sua mensagem"
        class="novo-chamado-textarea textarea"
        v-model="newMessage"
      ></textarea>
      <div class="boto-container">
        <button @click="addMessage" type="button" class="boto-button button">
          <span class="boto-text textbutton">Enviar</span>
        </button>
      </div>
      <timeline-titulo-data
        :data="currentChamado.messages.timeline"
      ></timeline-titulo-data>
    </div>
  </div>
</template>

<style scoped>
.acompanhar-chamado-pop-upintro {
  width: 100%;
  height: 100%;
  margin: 0px;
  display: flex;
  position: relative;
  border-radius: 15px;
  flex-direction: column;
  background-color: #f5f5f5;
}
.acompanhar-chamado-chamados {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.acompanhar-chamado-chamado {
  flex: 0 0 auto;
  width: 90%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.acompanhar-chamado-text {
  width: 90%;
  font-family: "Fivo Sans Medium";
  font-weight: bold;
}
.acompanhar-chamado-statusset {
  flex: 0 0 auto;
  height: 20px;
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-left: 20px;
  border-radius: 25px;
  padding-right: 20px;
  padding-bottom: 15px;
  justify-content: center;
  background-color: #00a89c;
}
.acompanhar-chamado-text01 {
  font-size: 16px;
  align-items: center;
}
.acompanhar-chamado-voltar {
  gap: 5px;
  flex: 0 0 auto;
  width: 10%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.acompanhar-chamado-container {
  display: flex;
}
.acompanhar-chamado-button {
  color: #ffffff;
  height: 50px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  font-family: Fivo Sans Medium;
  border-color: var(--color-theme-primary);
  border-width: 0px;
  padding-left: 20px;
  border-radius: 7px;
  padding-right: 20px;
  flex-direction: row;
  background-color: var(--color-theme-primary);
}
.acompanhar-chamado-button:hover {
  color: var(--color-theme-primary);
  border-width: 1px;
  background-color: var(--dl-color-gray-white);
}
.acompanhar-chamado-image {
  filter: brightness(0) invert(1);
  width: 25px;
  height: 30px;
  object-fit: cover;
  transition: 0.3s;
}
.acompanhar-chamado-button:hover .acompanhar-chamado-image {
  filter: none;
}

.acompanhar-chamado-text02 {
  font-size: 18px;
  align-self: center;
  margin-top: 1px;
  transition: 0.3s;
  align-items: center;
  margin-left: 5px;
}
.acompanhar-chamado-text02:hover {
  color: var(--color-theme-primary);
}
.acompanhar-chamado-linha {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: flex;
  align-self: center;
  margin-top: 30px;
  align-items: flex-start;
  background-color: var(--color-theme-primary);
}
.acompanhar-chamado-container1 {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.acompanhar-chamado-chat {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 40px;
  flex-direction: column;
  padding-bottom: 40px;
}
.mensagens {
  width: 100%;
  max-height: 800px;
  overflow-y: scroll;
  padding-right: 20px;
}
.acompanhar-chamado-mensagem-enviada {
  gap: 20px;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.mensagem-container {
  position: relative;
  display: flex;
  width: 75%;
  flex-direction: row;
}
.acompanhar-chamado-mensagem-hora {
  flex-direction: column;
  display: flex;
  padding-right: 30px;
}
.acompanhar-chamado-dev-flash-growth {
  gap: 5px;
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
}
.acompanhar-chamado-container2 {
  gap: 5px;
  display: flex;
  align-items: center;
}
.acompanhar-chamado-image1 {
  width: 30px;
  margin-top: -10px;
  object-fit: cover;
}
.acompanhar-chamado-text03 {
  font-size: 20px;
  font-family: "Fivo Sans Medium";
}
.acompanhar-chamado-text04 {
  display: flex;
  font-size: 14px;
}
.acompanhar-chamado-mensagem-dev {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  padding-top: 0px;
  padding-bottom: 20px;
}
.acompanhar-chamado-text05 {
  color: #737373;
  font-size: 18px;
  font-family: "Fivo Sans Medium";
  line-height: 1.5;
  overflow-wrap: anywhere;
}
.contedo-dashboard-container11 {
  width: 30%;
  display: flex;
  overflow-x: auto;
  padding-left: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-dashboard-news {
  flex: 0 0 auto;
  width: 25%;
  display: flex;
  align-items: flex-start;
}
.contedo-dashboard-timeline {
  flex: 0 0 auto;
  height: 100%;
  position: absolute;
  top: 10px;
  right: -15px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
}
.contedo-dashboard-point {
  flex: 0 0 auto;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  background-color: var(--color-theme-primary);
}
.contedo-dashboard-image5 {
  width: 200px;
  object-fit: cover;
}
.contedo-timeline-line[final="true"] {
  visibility: hidden;
  display: none;
}
.contedo-timeline-line {
  flex: 0 0 auto;
  width: 5px;
  height: 100%;
  min-height: 35px;
  display: flex;
  margin-top: -1px;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--color-theme-primary);
}
.contedo-dashboard-container12 {
  gap: 10px;
  flex: 0 0 auto;
  width: calc(98% - 30px);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 10px;
}
.textboxlead {
  font-weight: bold;
}

.acompanhar-chamado-linha1 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  background-color: var(--color-theme-primary);
}
.acompanhar-chamado-status-mensagem {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  padding-bottom: 20px;
}
.acompanhar-chamado-text06 {
  color: var(--dl-color-gray-black);
  font-size: 16px;
  font-family: "Fivo Sans Medium";
}
.novo-chamado-textarea {
  margin-bottom: 20px;
  width: 100%;
  height: 200px;
}
.boto-container {
  display: flex;
  position: relative;
}

.boto-container :hover {
  cursor: pointer;
}
.boto-button {
  align-items: center;
  justify-content: center;
  color: #ffffff;
  display: flex;
  font-family: Fivo Sans Medium;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 7px;
  padding-right: 20px;
  flex-direction: row;
  background-color: var(--color-theme-primary);
}
.boto-image {
  width: auto;
  object-fit: cover;
  padding-bottom: 2px;
}
.boto-text {
  padding: 5px;
  font-size: 18px;
  margin-top: 1px;
  margin-left: 5px;
}
@media (max-width: 957px) {
  .acompanhar-chamado-pop-upintro {
    margin: 0px;
    display: flex;
  }
  .acompanhar-chamado-text {
    font-size: 35px;
  }
  .acompanhar-chamado-chat {
    width: 100%;
  }
  .acompanhar-chamado-dev-flash-growth {
    align-items: flex-start;
    flex-direction: column;
  }
  .acompanhar-chamado-text04 {
    align-self: flex-start;
  }
}

@media (max-width: 767px) {
  .acompanhar-chamado-text {
    font-size: 30px;
  }
  .acompanhar-chamado-pop-upintro {
    display: flex;
  }
  .acompanhar-chamado-chat {
    width: 100%;
  }
}
@media (max-width: 657px) {
  .acompanhar-chamado-chamados {
    flex-direction: column;
  }
  .acompanhar-chamado-chamado {
    width: 100%;
  }
  .acompanhar-chamado-voltar {
    width: 100%;
  }
  .acompanhar-chamado-container {
    padding-top: 10px;
    width: 100%;
  }
  .acompanhar-chamado-button {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 479px) {
  .acompanhar-chamado-pop-upintro {
    width: 100%;
    display: flex;
  }
  .acompanhar-chamado-text {
    font-size: 25px;
  }
}

@media (max-width: 757px) {
  .contedo-dashboard-container11 {
    padding: 15px 0;
  }
}
@media (max-width: 479px) {
  .Textbox {
    padding: 0;
  }
}
</style>
