import HelpdeskController from "@/controllers/HelpdeskController";
import { query, onSnapshot, collection } from "firebase/firestore";
import { db } from "@/firebase/index.js";

const hController = new HelpdeskController();

const HelpdeskStore = {
  namespaced: true,
  state: () => ({
    chamados: [],
    selectedUserHelpdesk: []
  }),
  mutations: {
    SET_CHAMADOS(state, payload) {
      state.chamados = payload;
    },
    SET_CHAMADOS_BY_OWNER(state, payload) {
      state.selectedUserHelpdesk = payload;
    },
  },
  actions: {
    getAll({ commit, dispatch }) {
      commit('SET_LOADING', true, { root: true })
      try {
        const q = query(collection(db, "helpdesk"));
        onSnapshot(q, (querySnapshot) => {
          const finalData = [];
          querySnapshot.forEach((doc) => {
            finalData.push({ id: doc.id, ...doc.data() });
          });
          commit("SET_CHAMADOS", finalData);
        });
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message: "Erro ao pegar todos os chamados",
            icon: "alert-circle-outline",
            type: "danger",
          },
          { root: true }
        );
      } finally {
        commit('SET_LOADING', false, { root: true })
      }
    },
    getByOwnerId({ commit, dispatch }, payload) {
      try {
        const q = query(collection(db, "helpdesk"));
        onSnapshot(q, (querySnapshot) => {
          const finalData = [];
          querySnapshot.forEach((doc) => {
            doc.data().owner_id == payload ? finalData.push({ id: doc.id, ...doc.data() }) : null;
          });
          commit("SET_CHAMADOS_BY_OWNER", finalData);
        });
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message: "Erro ao pegar todos os chamados",
            icon: "alert-circle-outline",
            type: "danger",
          },
          { root: true }
        );
      }
    },
    getByEmpresa({ commit, dispatch }, payload) {
      try {
        const q = query(collection(db, "helpdesk"));
        onSnapshot(q, (querySnapshot) => {
          const finalData = [];
          querySnapshot.forEach((doc) => {
            doc.data().empresa_id == payload ? finalData.push({ id: doc.id, ...doc.data() }) : null;
          });
          commit("SET_CHAMADOS", finalData);
        });
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message: "Erro ao pegar todos os chamados da empresa selecionada",
            icon: "alert-circle-outline",
            type: "danger",
          },
          { root: true }
        );
      }
    },
    async createHelpdesk({ dispatch }, payload) {
      try {
        await hController.createHelpdesk(payload);
        dispatch(
          "setDialog",
          {
            message: "Novo chamado criado com sucesso",
            icon: "chatbubbles",
            type: "success",
          },
          { root: true }
        );
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message: "Erro ao criar novo chamado",
            icon: "alert-circle-outline",
            type: "danger",
          },
          { root: true }
        );
      }
    },
    async addMessage({ commit, dispatch }, payload) {
      commit('SET_LOADING', true, { root: true })
      try {
        await hController.addMessages(payload.docId, payload.message);
        dispatch(
          "setDialog",
          {
            message: "Mensagem enviada com sucesso",
            icon: "text",
            type: "success",
          },
          { root: true }
        );
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message: "Erro ao enviar nova mensagem",
            icon: "alert-circle-outline",
            type: "danger",
          },
          { root: true }
        );
      } finally {
        commit("SET_LOADING", false, { root: true })
      }
    },
  },
};

export default HelpdeskStore;
