import CompanyController from "@/controllers/CompanyController";
import Company from "@/models/Company";

const compController = new CompanyController();

const companyStore = {
  namespaced: true,
  state: () => ({
    companys: [],
    selectedCompany: null,
  }),
  mutations: {
    SET_COMPANYS(state, payload) {
      state.companys = payload;
    },
    SET_SELECTED_COMPANY(state, payload) {
      state.selectedCompany = payload;
    },
  },
  actions: {
    async editCompany({ dispatch }, payload) {
      try {
        await compController.updateCompany(payload.id, payload.company);
        dispatch(
          "setDialog",
          {
            message: "Os dados da company foram atualizados com sucesso!",
            type: "success",
            icon: "checkmark-circle-outline",
          },
          { root: true }
        );
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message:
              "Não foi possível alterar os dados da company, por favor tente novamente mais tarde",
            type: "danger",
            icon: "alert-circle-outline",
          },
          { root: true }
        );
      }
    },
    async createCompany({ dispatch }, payload) {
      try {
        await compController.createCompany(payload);
        dispatch(
          "setDialog",
          {
            message:
              "Company criada com sucesso!",
            type: "success",
            icon: "business-outline",
          },
          { root: true }
        );
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message:
              "Não foi possível criar uma nova company, por favor tente novamente mais tarde",
            type: "danger",
            icon: "alert-circle-outline",
          },
          { root: true }
        );
      }
    },
    async getAllCompanys({ commit }) {
      try {
        const comps = await compController.getAllCompany();
        let finalComps = comps.data.map((e) => {
          return new Company(e);
        });
        commit("SET_COMPANYS", finalComps);
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message:
              "Naõ foi possível pegar os dados de companys, por favor tente novamente mais tarde",
            type: "danger",
            icon: "alert-circle-outline",
          },
          { root: true }
        );
      }
    },
    async getCompanyById({ commit }, payload) {
      try {
        const comps = await compController.getCompanyById(payload);
        let finalComps = new Company(comps.data);
        commit("SET_SELECTED_COMPANY", finalComps);
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message:
              "Naõ foi possível pegar os dados da company selecionada, por favor tente novamente mais tarde",
            type: "danger",
            icon: "alert-circle-outline",
          },
          { root: true }
        );
      }
    },
  },
};

export default companyStore;
