<script setup>
import { onMounted, ref, computed, defineEmits } from "vue";
import pagination from "@/components/common/pagination.vue";
import { useStore } from "vuex";
import InputText from "@/components/common/InputText.vue";
import spinnerLoading from "@/components/common/spinner-loading.vue";

const emit = defineEmits(['update:selectedUser'])
const store = useStore();
const loading = ref(true);
const page = ref(1);
const pesquisa = ref("");

const users = computed(() => {
  return store.state.userModule.users;
});
const empresas = computed(() => {
  return store.state.empresaModule.empresas;
})

const listaUsuarios = computed(() => {
  var final = null;
  if (pesquisa.value == "") {
    final = users.value;
  } else {
    final = users.value.filter((user) => {
      return user.name.toLowerCase().includes(pesquisa.value.toLowerCase());
    });
    page.value = 1;
  }
  let finalUsers = final
    .sort(compararDatas)
    .slice(page.value * 10 - 10, page.value * 10);
  return { result: finalUsers, length: final.length };
});
onMounted(() => {
  loading.value = true;
  try {
    store.dispatch("userModule/getAllUsers");
    store.dispatch("empresaModule/getAllEmpresas")
    loading.value = false;
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
});

const nextPage = (newPage) => {
  page.value = newPage;
};

const setPopup = () => {
  store.commit("userModule/SET_POPUP_CRIAR_USUARIO", true);
};

function compararDatas(a, b) {
  const dataA = new Date(a.createdAt);
  const dataB = new Date(b.createdAt);
  return dataB - dataA;
}
function getEmpresa(id) {
  let selected_empresa = empresas.value.find(
    (empresa) => empresa.id === id
  );
  return selected_empresa ? selected_empresa.name : "-";
}

function edit(item) {
  emit('update:selectedUser', item)
  store.commit('userModule/SET_POPUP_CRIAR_USUARIO', true)
}
</script>

<template>
  <div class="content-view">
    <div class="contedo-helpdesk-container1">
      <div class="contedo-helpdesk-header">
        <div class="contedo-helpdesk-container2">
          <div class="contedo-helpdesk-display">
            <span class="contedo-helpdesk-text titulo">Usuários</span>
          </div>
          <div class="contedo-helpdesk-pesquisa">
            <input-text
              label="Pesquisar"
              icon="search-outline"
              v-model:value="pesquisa"
            ></input-text>
          </div>
          <div class="contedo-helpdesk-actions">
            <button @click="setPopup" class="contedo-helpdesk-boto">
              <span class="contedo-helpdesk-text01 textbutton"
                >+ Criar usuário</span
              >
            </button>
          </div>
        </div>
      </div>

      <!-- LISTA DE EMPRESAS -->
      <spinnerLoading v-if="loading"></spinnerLoading>
      <div v-else class="table-container">
        <div class="contedo-helpdesk-clientlist">
          <table class="table">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Empresa</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in listaUsuarios.result"
                :key="index"
                :class="{
                  'linha-separada': index !== listaUsuarios.length - 1,
                }"
              >
                <td>{{ item.name == '' ? '-' : item.name }}</td>
                <td>
                  {{ item.email == '' ? '-' : item.email }}
                </td>
                <td>{{ getEmpresa(item.empresa_id) }}</td>
                <td>{{ item.status == '' ? '-' : item.status}}</td>
                <td>
                  <button class="edit-icon" @click="edit(item)">
                    <ion-icon name="create-outline"></ion-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <pagination
        :currentPage="page"
        :total="listaUsuarios.length"
        @update:currentPage="nextPage"
      ></pagination>
    </div>
  </div>
</template>

<style scoped>
.contedo-helpdesk-container1 {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-helpdesk-header {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.contedo-helpdesk-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contedo-helpdesk-display {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contedo-helpdesk-text {
  color: var(--color-theme-primary);
  font-size: 45px;
  font-family: Fivo Sans Medium;
}
.contedo-helpdesk-actions {
  gap: 20px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.contedo-helpdesk-pesquisa {
  flex: 0 0 auto;
  width: 30%;
  height: 40px;
  display: flex;
  align-items: center;
}
.contedo-helpdesk-boto {
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  align-items: center;
  padding-top: 0px;
  padding-left: 40px;
  border-radius: 10px;
  padding-right: 40px;
  padding-bottom: 0px;
  justify-content: flex-end;
  background-color: var(--color-theme-primary);
}
.contedo-helpdesk-boto :hover {
  cursor: pointer;
}
.contedo-helpdesk-text01 {
  font-size: 15px;
}
.table-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding: 20px 0;
}
.contedo-helpdesk-clientlist {
  flex: 0 0 auto;
  min-height: 400px;
  height: fit-content;
  width: 100%;
  display: flex;
  border-width: 20px;
  border-color: #fff;
  background-color: #ffffff;
  border-radius: 8px;
  align-items: flex-start;
  justify-content: space-between;
  overflow-x: scroll;
  flex-direction: column;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar {
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}
.table {
  width: max-content;
  min-width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th,
td {
  border-bottom: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.linha-separada td {
  border-bottom: 1px solid #dddddd;
}
.edit-icon ion-icon {
  font-size: 25px;
  color: var(--color-theme-primary);
}
.edit-icon :hover {
  cursor: pointer;
}
@media (max-width: 990px) {
  .contedo-helpdesk-container2 {
    flex-wrap: wrap;
  }
  .contedo-helpdesk-pesquisa {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .contedo-helpdesk-container2 {
    gap: 15px;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
  }
  .contedo-helpdesk-text {
    font-size: 35px;
  }
  .contedo-helpdesk-pesquisa {
    width: 100%;
  }
  .contedo-helpdesk-actions {
    width: 100%;
  }
  .contedo-helpdesk-boto {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 479px) {
  .contedo-helpdesk-actions {
    flex-direction: column;
    align-items: flex-start;
  }
  .contedo-helpdesk-text {
    font-size: 30px;
  }
  .contedo-helpdesk-container4 {
    width: 100%;
    padding: 10px;
  }
  .contedo-helpdesk-boto {
    width: 100%;
  }
  .contedo-helpdesk-select {
    width: 100%;
  }
}
</style>
