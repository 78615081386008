<script setup>
import { signOut } from "firebase/auth";
import { auth } from "@/firebase/index";
import router from "@/router/index";
import  { computed, inject } from 'vue';
import { useStore } from "vuex";

const store = useStore();

const logout = () => {
  signOut(auth);
  localStorage.clear();
  router.push("/");
};

function getInitials(displayName) {
  const names = displayName.split(" ");
  if (names.length >= 2) {
    return names[0][0] + names[1][0];
  } else if (names.length === 1) {
    return names[0][0];
  } else {
    return "";
  }
}

const user = computed(() => {
  return store.state.userModule.currentUser;
})
</script>

<template>
  <div class="profile-profile" v-bind:class="rootClassName">
    <div class="profile-foto">
      {{ getInitials(user.name) }}
    </div>
    <div class="profile-user">
      <span class="profile-nome">{{ user.name }}</span>
      <span class="profile-nome1">{{ user.email }}</span>
    </div>
    <div class="profile-icon" @click="logout()">
      <img
        alt="image"
        src="https://api.iconify.design/ci/log-out.svg?color=white"
        class="profile-image1"
      />
    </div>
  </div>
</template>

<style scoped>
.profile-profile {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-self: flex-end;
  align-items: center;
  padding-right: 10px;
}
.profile-foto {
  width: 55px;
  height: 55px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: var(--color-theme-primary);
}
.profile-image {
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.profile-user {
  width: 65%;
  display: flex;
  flex-direction: column;
}
.profile-nome {
  color: #ffffff;
  font-size: 18px;
  text-align: left;
  font-family: Fivo Sans Medium;
  margin-left: 10px;
}
.profile-nome1 {
  color: #ffffff;
  font-size: 14px;
  text-align: left;
  font-family: Fivo Sans Medium;
  margin-left: 10px;
}
.profile-icon {
  flex: 0 0 auto;
  width: 15%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.profile-icon :hover {
  cursor: pointer;
}
.profile-image1 {
  width: 80%;
  align-self: flex-end;
  object-fit: cover;
}
.profile-root-class-name {
  height: auto;
  margin-top: 0px;
}
.profile-root-class-name1 {
  height: auto;
  margin-top: 0px;
}
</style>
