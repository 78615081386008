<script setup>
import MenuLateral from "@/components/dashboard/menu/menu-lateral";
import menuSuperior from "@/components/dashboard/menu/menu-superior.vue";
import toastAlert from "@/components/common/toast-alert.vue";
import listaUsuarios from "@/components/dashboard/admin/usuarios/lista-usuarios.vue";
import InputText from "@/components/common/InputText.vue";
import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";

const store = useStore();
const isVisible = computed(() => {
  return store.state.userModule.popupCriarUsuario;
});
const selectedUser = ref({
  uid: null,
  name: "",
  email: "",
  status: "",
  empresa_id: null,
  status: "ATIVO",
});
const selectedEmpresa = ref({});
const userToEdit = ref(null);
const empresas = computed(() => {
  return store.state.empresaModule.empresas;
});
onMounted(() => {
  store.state.empresaModule.empresas.length == 0
    ? store.dispatch("empresaModule/getAllEmpresas")
    : null;
});

const salvar = () => {
  if (selectedUser.value.uid != null && selectedUser.value.uid != undefined) {
    var editados = {
      name: false,
      email: false,
    };
    editados.name =
      userToEdit.value.name !== selectedUser.value.name ? true : false;
    editados.email =
      userToEdit.value.email !== selectedUser.value.email ? true : false;
    selectedUser.value.empresa_id = selectedEmpresa.value;
    store.dispatch("userModule/adminEditUser", {
      user: selectedUser.value,
      editados: editados,
    });
  } else {
    const user = {
      name: selectedUser.value.name,
      email: selectedUser.value.email,
      status: selectedUser.value.status,
      empresa_id: selectedEmpresa.value,
    };
    store.dispatch("userModule/createAuthUser", {
      user: user,
      password: "flashvolve123",
    });
  }
  clearData();
};
const clearData = () => {
  selectedUser.value = {
    uid: null,
    name: "",
    email: "",
    status: "",
    empresa_id: "",
    status: "ATIVO",
  };
  selectedEmpresa.value = null;
  userToEdit.value = null;
  store.commit("userModule/SET_POPUP_CRIAR_USUARIO", false);
};

function updateUser(value) {
  selectedUser.value = value;
  userToEdit.value = { ...value };
  selectedEmpresa.value = value.empresa_id;
}
</script>

<template>
  <div class="growth-dashboard2-container">
    <toast-alert></toast-alert>
    <menu-lateral
      id="menu-lateral"
      rootClassName="menu-lateral-root-class-name6"
    ></menu-lateral>
    <menu-superior id="menu-superior"></menu-superior>
    <div class="content">
      <lista-usuarios @update:selectedUser="updateUser"></lista-usuarios>
    </div>

    <div class="modal" v-if="isVisible">
      <div class="novo-chamado-pop-upintro">
        <div class="novo-chamado-barra">
          <span class="novo-chamado-novo-chamado">USUÁRIO</span>
          <button @click="clearData">
            <img
              alt="image"
              src="https://api.iconify.design/icon-park-outline/close-one.svg?color=white"
              class="novo-chamado-image"
            />
          </button>
        </div>
        <div class="novo-chamado-entrada">
          <div class="contedo-settings-box">
            <div class="contedo-settings-container02">
              <InputText
                label="Nome"
                v-model:value="selectedUser.name"
              ></InputText>
              <InputText
                label="Email"
                v-model:value="selectedUser.email"
              ></InputText>
              <span class="contedo-settings-text1">Empresa: </span>
              <div class="select-container">
                <select id="opcoes" v-model="selectedEmpresa">
                  <option
                    v-for="empresa in empresas"
                    :key="empresa.id"
                    :value="empresa.id"
                  >
                    {{ empresa.name }}
                  </option>
                </select>
              </div>
              <span class="contedo-settings-text1">Status: </span>
              <div class="select-container">
                <select id="opcoes" v-model="selectedUser.status">
                  <option
                    v-for="item in ['ATIVO', 'INATIVO', 'ADMIN']"
                    :key="item"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
              <span
                v-if="selectedUser.uid == null && selectedUser.uid == undefined"
                class="text-obs"
                >*Por padrão, o usuário será criado com a senha flashvolve123
              </span>
              <div class="contedo-settings-button">
                <div class="contedo-settings-buttonset">
                  <div @click="salvar" class="contedo-settings-buttonaction">
                    <span class="contedo-settings-text12">{{
                      selectedUser.uid != null && selectedUser.uid != undefined
                        ? "Salvar alterações"
                        : "Criar usuário"
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.growth-dashboard2-container {
  width: 100%;
  display: flex;
  max-height: 100vh;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5;
}
#menu-lateral {
  gap: 30px;
  height: 100%;
  width: 300px;
  min-width: 300px;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  padding-left: 10px;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--color-theme-primary);
  box-sizing: border-box;
  transition: all ease 0.3s;
}
.modal {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 900;
}
.novo-chamado-pop-upintro {
  width: 100%;
  height: 650px;
  margin: 0px;
  display: flex;
  position: relative;
  max-width: 800px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  max-height: 600px;
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.novo-chamado-barra {
  flex: 0 0 auto;
  width: 100%;
  height: 40px;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: center;
  background-color: var(--color-theme-primary);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.novo-chamado-novo-chamado {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  text-align: center;
  font-family: "Fivo Sans Medium";
}
.novo-chamado-image {
  top: 8px;
  right: 0px;
  width: 25px;
  position: absolute;
  justify-self: flex-end;
  margin-right: 10px;
}
.novo-chamado-entrada {
  flex: 0 0 auto;
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  flex-direction: column;
  padding-bottom: 30px;
  justify-content: flex-start;
}
.contedo-settings-box {
  gap: 10px;
  width: 100%;
  height: 80%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.contedo-settings-container02 {
  gap: 10px;
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-settings-text1 {
  color: #737373;
  font-size: 18px;
  text-align: flex-start;
  font-family: Fivo Sans Medium;
}
.select-container {
  height: 40px;
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-color: var(--color-theme-primary);
  border-width: 2px;
  border-radius: 10px;
}
.select-container select {
  width: 100%;
  padding: 3px;
}
.text-obs {
  color: #737373;
  font-size: 14px;
  text-align: flex-start;
  font-family: Fivo Sans Medium;
}
.contedo-settings-button {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
}
.contedo-settings-buttonset {
  flex: 0 0 auto;
  width: 80%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.contedo-settings-buttonaction {
  flex: 0 0 auto;
  width: 70%;
  height: 50px;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: var(--color-theme-primary);
}
.contedo-settings-buttonaction :hover {
  cursor: pointer;
}
.contedo-settings-text12 {
  color: #ffffff;
  font-size: 18px;
  align-self: center;
  text-align: center;
  font-family: Fivo Sans Medium;
}
@media (max-width: 1440px) {
  .contedo-settings-box {
    position: relative;
  }
}
@media (max-width: 1040px) {
  .contedo-settings-box {
    width: 80%;
  }
}
@media (max-width: 957px) {
  .contedo-settings-box {
    width: 100%;
  }
  .novo-chamado-pop-upintro {
    margin: 0px;
    display: flex;
  }
}
@media (max-width: 767px) {
  .growth-dashboard2-container {
    flex-direction: column;
  }
  #menu-lateral {
    visibility: hidden;
    display: none;
  }
}
@media (max-width: 479px) {
  .novo-chamado-pop-upintro {
    display: flex;
    max-height: 800px;
  }
  .novo-chamado-entrada {
    padding: 20px;
  }
  .novo-chamado-pop-upintro {
    display: flex;
    max-height: 800px;
  }
  .novo-chamado-entrada {
    padding: 20px;
  }
  .contedo-settings-button {
    width: 100%;
  }
  .contedo-settings-buttonset {
    width: 100%;
  }
  .contedo-settings-buttonaction {
    width: 100%;
  }
}
</style>
