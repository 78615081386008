import { auth } from "@/firebase/index.js";
import BaseController from "./BaseController";
import axios from "axios";
import { sendPasswordResetEmail, confirmPasswordReset } from "firebase/auth";

const functions_url = process.env.VUE_APP_FUNCTIONS_URL;

export default class bdController extends BaseController {
  constructor() {
    super();
  }

  async createUser(user) {
    await super.create("users", user);
  }

  async getUserById(id) {
    return await super.getById("users", id).then((res) => {
      return res
    }).catch((err) => {
      return err.response.data.message
    })
  }
  adminEditUser(uid, payload) {
    super.update("users", uid, payload);
  }

  editUser(name, uid) {
    super.update("users", uid, { name: name });
  }

  editUserEmail(email, uid) {
    super.update("users", uid, { email: email });
  }

  getCurrentUser() {
    return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        unsubscribe();
        resolve(user);
      }, reject);
    });
  }

  async getAllUsers() {
    return await super.getAll("users");
  }

  async updateEmailOnAuth(email, id) {
    return await axios.post(`${functions_url}/editUserEmail`, {
      email: email,
      id: id,
    });
  }

  async adminUpdateUserProfile(email, name, id) {
    return await axios.post(`${functions_url}/adminEditUser`, {
      email: email,
      name: name,
      id: id
    })
  }

  actionCodesettings() {
    return {
      handleCodeInApp: true,
      url: `${window.location.origin}/resetPassword`,
    };
  }

  sendResetPasswordEmailLink(email) {
    sendPasswordResetEmail(auth, email, this.actionCodesettings());
  }
  async updateUserPassword(payload) {
    return await confirmPasswordReset(auth, payload.oobCode, payload.password);
  }
}
