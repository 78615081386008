<script setup>
import { defineProps, onMounted, ref, computed, inject } from "vue";
import { useStore } from "vuex";

const primaryColor = inject('primaryColor').replace('#', '')
const props = defineProps({
  contato: {
    type: Object,
    required: true,
  },
});

const store = useStore();
const call = ref(null);
const qualificado = ref(null);
const score = ref(null);
const resetEvaluation = ref(false);

onMounted(() => {
  call.value = props.contato.call;
  qualificado.value = props.contato.qualified;
  score.value = props.contato.score;
});

const setCall = (value) => {
  if (value == true) {
    call.value == true ? (call.value = null) : (call.value = true);
  }
  if (value == false) {
    call.value == false ? (call.value = null) : (call.value = false);
  }
};

const setQualificado = (value) => {
  if (value == true) {
    qualificado.value == true
      ? (qualificado.value = null)
      : (qualificado.value = true);
  }
  if (value == false) {
    qualificado.value == false
      ? (qualificado.value = null)
      : (qualificado.value = false);
  }
};

const setStar = (value) => {
  if (value != 0) {
    for (var i = 0; i < value; i++) {
      const star = document.getElementById(`estrela_${i}`);
      star.style = "color: var(--color-theme-primary)";
    }
  } else {
    for (var i = 0; i < 5; i++) {
      const star = document.querySelector(`#estrela_${i}`);
      star.style = "color: white";
    }
  }
};

const setScore = (value) => {
  score.value = value;
};

const evaluation = computed(() => {
  if (resetEvaluation) {
    return {
      op: {
        score: score.value == null ? props.contato.score : score.value,
        call: call.value == null ? props.contato.call : call.value,
        qualified:
          qualificado.value == null
            ? props.contato.qualified
            : qualificado.value,
      },
      id: props.contato.id,
      empresa_id: props.contato.empresa_id,
    };
  } else {
    return {
      op: {
        score: score.value,
        call: call.value,
        qualified: qualificado.value,
      },
      id: props.contato.id,
      empresa_id: props.contato.empresa_id,
    };
  }
});

function saveEvaluation() {
  store.dispatch("oportunityModule/setPopupEvaluation", {
    evaluation: evaluation.value,
    value: true,
  });
}

const reavaliar = () => {
  call.value = null;
  qualificado.value = null;
  score.value = null;
  resetEvaluation.value = true;
};

const cancelar = () => {
  call.value = props.contato.call;
  qualificado.value = props.contato.qualified;
  score.value = props.contato.score;
  resetEvaluation.value = false;
};
</script>

<template>
  <div class="contedo-settings-user-data">
    <div class="contedo-settings-reunioes">
      <div
        v-if="
          props.contato.call == null ||
          props.contato.qualified == null ||
          props.contato.score == null
        "
        class="contedo-settings-topcontent"
      >
        <div class="contedo-settings-container03">
          <div class="contedo-settings-cliente1">
            <span class="contedo-settings-text13 dashlead">
              Avalie este lead
            </span>
          </div>
        </div>
      </div>
      <div v-else style="display: flex; width: 100%; justify-content: flex-end">
        <div class="tooltip-container">
          <div class="tooltip-content">Reavaliar lead</div>
          <img
            @click="reavaliar"
            alt="image"
            :src="`https://api.iconify.design/tabler/edit.svg?color=%23${primaryColor}&width=30`"
            class="tooltip-trigger"
          />
        </div>
      </div>
      <div class="contedo-settings-bottoncontent">
        <div class="contedo-settings-container05">
          <div class="contedo-settings-container06">
            <div class="contedo-settings-reuniao">
              <div class="contedo-settings-cliente11">
                <span class="contedo-settings-text16 Clientquestion">
                  Houve reunião?
                </span>
              </div>
            </div>
            <div
              @click="
                props.contato.call == null || resetEvaluation
                  ? setCall(false)
                  : null
              "
              type="call"
              :selected="call"
              :hasValue="
                props.contato.call != null && resetEvaluation == false
                  ? true
                  : false
              "
              class="contedo-settings-container-nao"
            >
              <div class="contedo-settings-container11">
                <span class="contedo-settings-text21">
                  <span>NÃO</span>
                  <br />
                </span>
              </div>
            </div>
            <div
              @click="
                props.contato.call == null || resetEvaluation
                  ? setCall(true)
                  : null
              "
              :disabled="call != null"
              type="call"
              :selected="call"
              :hasValue="
                props.contato.call != null && resetEvaluation == false
                  ? true
                  : false
              "
              class="contedo-settings-container-sim"
            >
              <div class="contedo-settings-container08">
                <span class="contedo-settings-text17">
                  <span>SIM</span>
                  <br />
                </span>
              </div>
            </div>
          </div>
          <div class="contedo-settings-container09">
            <div class="contedo-settings-reuniao1">
              <div class="contedo-settings-cliente12">
                <span class="contedo-settings-text20 Clientquestion">
                  Este lead é qualificado?
                </span>
              </div>
            </div>
            <div
              @click="
                props.contato.qualified == null || resetEvaluation
                  ? setQualificado(false)
                  : null
              "
              type="qualificado"
              :selected="qualificado"
              :hasValue="
                props.contato.qualified != null && resetEvaluation == false
                  ? true
                  : false
              "
              class="contedo-settings-container-nao"
            >
              <div class="contedo-settings-container11">
                <span class="contedo-settings-text21">
                  <span>NÃO</span>
                  <br />
                </span>
              </div>
            </div>
            <div
              @click="
                props.contato.qualified == null || resetEvaluation
                  ? setQualificado(true)
                  : null
              "
              type="qualificado"
              :selected="qualificado"
              :hasValue="
                props.contato.qualified != null && resetEvaluation == false
                  ? true
                  : false
              "
              class="contedo-settings-container-sim"
            >
              <div class="contedo-settings-container088">
                <span class="contedo-settings-text17">
                  <span>SIM</span>
                  <br />
                </span>
              </div>
            </div>
          </div>
          <div class="contedo-settings-leadclass">
            <div class="contedo-settings-score">
              <span class="contedo-settings-text24 Clientquestion">
                Classificação do Lead
              </span>
              <div
                v-if="score != null"
                class="contedo-settings-container-estrelas"
              >
                <div v-for="i in score" :key="i">
                  <ion-icon
                    name="star"
                    class="contedo-settings-image07"
                    type="star-filled"
                  ></ion-icon>
                </div>
                <div v-for="i in 5 - score" :key="i">
                  <ion-icon
                    name="star"
                    class="contedo-settings-image07"
                    type="star"
                  ></ion-icon>
                </div>
              </div>
              <div v-else class="contedo-settings-container-estrelas">
                <ion-icon
                  @mouseover="setStar(1)"
                  @mouseleave="setStar(0)"
                  @click="setScore(1)"
                  name="star"
                  class="contedo-settings-image07"
                  type="null"
                  id="estrela_0"
                ></ion-icon>
                <ion-icon
                  @mouseover="setStar(2)"
                  @mouseleave="setStar(0)"
                  @click="setScore(2)"
                  name="star"
                  class="contedo-settings-image07"
                  type="null"
                  id="estrela_1"
                ></ion-icon>
                <ion-icon
                  @mouseover="setStar(3)"
                  @mouseleave="setStar(0)"
                  @click="setScore(3)"
                  name="star"
                  class="contedo-settings-image07"
                  type="null"
                  id="estrela_2"
                ></ion-icon>
                <ion-icon
                  @mouseover="setStar(4)"
                  @mouseleave="setStar(0)"
                  @click="setScore(4)"
                  name="star"
                  class="contedo-settings-image07"
                  type="null"
                  id="estrela_3"
                ></ion-icon>
                <ion-icon
                  @mouseover="setStar(5)"
                  @mouseleave="setStar(0)"
                  @click="setScore(5)"
                  name="star"
                  class="contedo-settings-image07"
                  type="null"
                  id="estrela_4"
                ></ion-icon>
              </div>
            </div>
          </div>
          <button
            v-if="
              props.contato.call == null ||
              props.contato.qualified == null ||
              props.contato.score == null
            "
            class="contedo-settings-button"
          >
            <div class="contedo-settings-buttonset">
              <div
                :disabled="call == null || qualificado == null || score == null"
                :isDisabled="
                  call == null || qualificado == null || score == null
                "
                @click="saveEvaluation"
                class="contedo-settings-buttonaction"
              >
                <span class="contedo-settings-text12">Salvar avaliação</span>
              </div>
            </div>
          </button>
          <div v-if="resetEvaluation" class="reavaliar-lead-buttons">
            <button class="reavaliar-lead-button">
              <div @click="cancelar" class="reavaliar-lead-buttonset">
                <div class="reavaliar-lead-buttonaction" value="cancelar">
                  <span class="contedo-settings-text12">Cancelar</span>
                </div>
              </div>
            </button>
            <button class="reavaliar-lead-button">
              <div @click="saveEvaluation" class="reavaliar-lead-buttonset">
                <div class="reavaliar-lead-buttonaction" value="salvar">
                  <span class="contedo-settings-text12">Salvar</span>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.contedo-settings-user-data {
  gap: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-settings-reunioes {
  gap: 20px;
  width: 100%;
  height: 80%;
  display: flex;
  padding: 30px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.contedo-settings-topcontent {
  gap: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  border-width: 1px;
  border-color: var(--color-theme-primary);
}
.contedo-settings-reuniao1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: 10px;
  align-items: center;
  border-color: var(--color-theme-primary);
  border-width: 1px;
  border-radius: 10px;
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.contedo-settings-container03 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.contedo-settings-image03 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
}
.contedo-settings-image04 {
  width: 25px;
  object-fit: cover;
}
.contedo-settings-cliente1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  padding-left: 10px;
  flex-direction: column;
}
.contedo-settings-text13 {
  color: rgb(115, 115, 115);
  align-self: flex-start;
}
.tooltip-container {
  width: 50px;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.tooltip-content {
  visibility: hidden;
  width: 120px;
  background-color: var(--color-theme-primary);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.contedo-settings-text14 {
  color: var(--dl-color-gray-black);
  font-size: 14px;
  margin-top: 5px;
}
.contedo-settings-container04 {
  display: flex;
  position: relative;
  align-items: center;
}
.contedo-settings-button1 {
  color: #ffffff;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  font-family: Fivo Sans Medium;
  padding-top: 5px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 7px;
  padding-right: 20px;
  flex-direction: row;
  padding-bottom: 5px;
  background-color: var(--color-theme-primary);
}
.contedo-settings-text15 {
  font-size: 18px;
  padding: 5px;
}
.contedo-settings-bottoncontent {
  gap: 5px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  flex-direction: column;
  justify-content: flex-start;
}
.contedo-settings-container05 {
  gap: 5px;
  width: 100%;
  display: flex;
  border-radius: 15px;
  flex-direction: column;
  justify-content: flex-start;
}
.contedo-settings-container06 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.contedo-settings-reuniao {
  flex: 0 0 auto;
  width: 85%;
  height: 40px;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: 10px;
  border-color: var(--color-theme-primary);
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 20px;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: #eeeeee;
  border-top-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.contedo-settings-cliente11 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-settings-text16 {
  line-height: 1;
}
.contedo-settings-container-sim {
  gap: 5px;
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contedo-settings-container-sim[hasValue="false"] :hover {
  background-color: #00a89c;
}
.contedo-settings-container-sim[selected="true"] .contedo-settings-container08 {
  background-color: #00a89c;
}
.contedo-settings-container-sim[selected="true"]
  .contedo-settings-container088 {
  background-color: #00a89c;
}
.contedo-settings-container08 {
  width: 100%;
  height: 100%;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  background-color: rgb(180, 180, 180);
  justify-content: center;
  border-top-left-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.contedo-settings-container088 {
  width: 100%;
  height: 100%;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  justify-content: center;
  background-color: rgb(180, 180, 180);
}
.contedo-settings-text17 {
  color: var(--dl-color-gray-white);
  font-size: 12px;
  align-self: center;
  text-align: center;
  font-family: "Fivo Sans Medium";
  line-height: 1;
}
.contedo-settings-container09 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.contedo-settings-reuniao1 {
  flex: 0 0 auto;
  width: 85%;
  height: 40px;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: 10px;
  border-color: var(--color-theme-primary);
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 20px;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: #e3e3e3;
  border-top-left-radius: 0px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.contedo-settings-cliente12 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-settings-text20 {
  line-height: 1;
}
.contedo-settings-container-nao {
  gap: 5px;
  width: 15%;
  display: flex;
  align-items: center;
  background-color: rgb(180, 180, 180);
  justify-content: space-between;
}
.contedo-settings-container-nao[hasValue="false"] :hover {
  background-color: var(--color-theme-primary);
}
.contedo-settings-container-nao[selected="false"] {
  background-color: var(--color-theme-primary);
}
.contedo-settings-container11 {
  width: 100%;
  height: 100%;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0px;
  justify-content: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.contedo-settings-text21 {
  color: var(--dl-color-gray-white);
  font-size: 12px;
  align-self: center;
  text-align: center;
  font-family: "Fivo Sans Medium";
  line-height: 1;
}
.contedo-settings-leadclass {
  flex: 0 0 auto;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--color-theme-primary);
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 20px;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: #eeeeee;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.contedo-settings-score {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contedo-settings-text24 {
  color: rgb(115, 115, 115);
  align-self: center;
  text-align: left;
}
.contedo-settings-container-estrelas {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.contedo-settings-image07 {
  width: 22px;
  object-fit: cover;
  font-size: 18px;
}
.contedo-settings-image07[type="star"] {
  color: white;
}
.contedo-settings-image07[type="star-filled"] {
  color: var(--color-theme-primary);
}
.contedo-settings-image07[type="null"] {
  color: white;
}
.contedo-settings-button {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
}
.contedo-settings-buttonset {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.contedo-settings-buttonaction[isDisabled="true"] {
  flex: 0 0 auto;
  width: 70%;
  height: 50px;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: rgb(180, 180, 180);
}
.contedo-settings-buttonaction[isDisabled="false"] {
  flex: 0 0 auto;
  width: 70%;
  height: 50px;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: var(--color-theme-primary);
}
.contedo-settings-buttonaction :hover {
  cursor: pointer;
}
.contedo-settings-text12 {
  color: #ffffff;
  font-size: 18px;
  align-self: center;
  text-align: center;
  font-family: Fivo Sans Medium;
}
.reavaliar-lead-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.reavaliar-lead-button {
  gap: 5px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
}
.reavaliar-lead-buttonset :hover {
  cursor: pointer;
}
.reavaliar-lead-buttonset {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.reavaliar-lead-buttonaction[value="salvar"] {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: #00a89c;
}
.reavaliar-lead-buttonaction[value="cancelar"] {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: var(--color-theme-primary);
}
.contedo-settings-image08 {
  width: 22px;
  object-fit: cover;
}
.contedo-settings-image09 {
  width: 22px;
  object-fit: cover;
}
@media (max-width: 1220px) {
  .contedo-settings-cliente1 {
    padding-left: 15;
  }
  .contedo-settings-cliente11 {
    padding-left: 15;
  }
  .contedo-settings-cliente12 {
    padding-left: 15;
  }
}
@media (max-width: 657px) {
  .contedo-settings-container06 {
    flex-direction: column;
  }
  .contedo-settings-reunioes {
    padding: 15px;
  }
  .contedo-settings-reuniao {
    padding-bottom: 0;
    border-top-right-radius: 10px;
    width: 100%;
  }
  .contedo-settings-container-sim {
    width: 100%;
  }
  .contedo-settings-container08 {
    border-top-right-radius: 0;
    padding: 10px;
  }
  .contedo-settings-container09 {
    flex-direction: column;
  }
  .contedo-settings-reuniao1 {
    width: 100%;
  }
  .contedo-settings-container-nao {
    width: 100%;
  }
  .contedo-settings-container11 {
    padding: 10px;
  }
  .contedo-settings-leadclass {
    height: fit-content;
  }
  .contedo-settings-score {
    flex-direction: column;
  }
}
@media (max-width: 479px) {
  .contedo-settings-image03 {
    visibility: hidden;
    display: none;
  }
}
</style>
