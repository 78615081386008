<script setup>
import AppBoto from "@/components/common/boto";
import pagination from "@/components/common/pagination.vue";
import { onMounted, ref, computed, watch } from "vue";
import moment from "moment";
import InputText from "@/components/common/InputText.vue";
import emptyContatos from "@/components/common/empty-contatos.vue";
import { useStore } from "vuex";
//import statusBadge from "@/components/common/status-badge.vue";
import cardContato from "./card-contato.vue";
import router from "@/router";
import spinnerLoading from "@/components/common/spinner-loading.vue";

const store = useStore();

const contatos = computed(() => {
  return store.state.oportunityModule.oportunitys
})
const currentContato = ref({});
const loading = ref(true);
const isGeneralData = ref(true);
const page = ref(1);
const pesquisa = ref("");
const itemsCSV = ref([]);

const listaContatos = computed(() => {
  var final = null;
  if (pesquisa.value == "") {
    final = contatos.value;
  } else {
    final = contatos.value.filter((contato) => {
      return contato.nome.toLowerCase().includes(pesquisa.value.toLowerCase());
    });
    page.value = 1;
  }
  let finalContatos = final
    .sort(compararDatas)
    .slice(page.value * 12 - 12, page.value * 12);
  return { result: finalContatos, length: final.length };
});
function compararDatas(a, b) {
  const dataA = new Date(a.created_date);
  const dataB = new Date(b.created_date);
  return dataB - dataA;
}

onMounted(async () => {
  loading.value = true;
  updateData();
});

const isContatosEmpty = ref(false);
const empresa = computed(() => {
  return store.state.empresaModule.currentUserEmpresa;
});
const updateData = async () => {
  loading.value = true;
  try {
    await store.dispatch(
      "oportunityModule/getOportunitysByEmpresaId",
      empresa.value.id
    );
    contatos.value = store.state.oportunityModule.oportunitys;
    loading.value = false;
  } catch (e) {
    loading.value = false;
    console.log(e);
  } finally {
    contatos.value.length == 0 ? (isContatosEmpty.value = true) : null;
  }
};

const nextPage = (newPage) => {
  page.value = newPage;
};

function getById(item) {
  loading.value = true;
  router.push(`/dashboard/contatos/${item.id}`);
  loading.value = false;
}

function getAll() {
  loading.value = true;
  isGeneralData.value = true;
  currentContato.value = {};
  loading.value = false;
}

const check = ref(false);
const checkAll = () => {
  if (check.value == true) {
    check.value = false;
    contatos.value.forEach(() => {
      itemsCSV.value.pop();
    });
  } else {
    check.value = true;
    contatos.value.forEach((element) => {
      itemsCSV.value.push(element);
    });
  }
};

const itemToCSV = (item) => {
  if (itemsCSV.value.includes(item)) {
    let itemPraTirar = itemsCSV.value.indexOf(item);
    itemsCSV.value.splice(itemPraTirar, 1);
  } else {
    itemsCSV.value.push(item);
  }
};

function convertToCSV(jsonData) {
  const headers = Object.keys(jsonData[0]);
  const rows = jsonData.map((entry) => headers.map((header) => entry[header]));
  return [headers.join(","), ...rows.map((row) => row.join(","))].join("\n");
}

function downloadCSV(csvData, fileName) {
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(url);
}

function exportarCSV() {
  try {
    itemsCSV.value.map((contato) => {
      contato.reuniao = moment(contato.reuniao).format("DD/MM/YYYY HH:mm");
      contato.created_at = moment(contato.created_at).format(
        "DD/MM/YYYY HH:mm"
      );
      contato.horario = moment(contato.horario).format("DD/MM/YYYY HH:mm");
    });
    const csvData = convertToCSV(itemsCSV.value);

    store.dispatch("setDialog", {
      message: "Contatos exportados com sucesso.",
      type: "success",
      icon: "checkmark-circle-outline",
    });
    return downloadCSV(csvData, `${empresa.value.name}`);
  } catch (e) {
    /* axios.post(
      "https://us-central1-sql-flasvolve.cloudfunctions.net/getErrors",
      {
        function_error: "Convert contatos to CSV",
        error_type: "User error",
        error: "Erro: nenhum contato selecionado!",
        data: moment().format("DD/MM/YYYY HH:mm"),
        status: "Open",
        server_url: window.location.href,
        repository_url:
          "https://github.com/Flashvolve-Plataforma-Criativa/flash-growth-dashboard",
      }
    ); */
    store.dispatch("setDialog", {
      message: "Erro: Nenhum contato selecionado!",
      type: "danger",
      icon: "alert-circle-outline",
    });
  }
}
</script>

<template>
  <div>
    <div class="contedo-contatos-group">
      <div class="contedo-contatos-header">
        <div class="contedo-contatos-title">
          <div class="contedo-contatos-display">
            <span class="contedo-contatos-text titulo">Contatos</span>
          </div>
          <div v-if="!isContatosEmpty" class="contedo-contatos-pesquisa">
            <input-text
              label="Pesquisar"
              icon="search-outline"
              v-model:value="pesquisa"
            ></input-text>
          </div>
          <div v-if="!isContatosEmpty" class="contedo-contatos-actions">
            <span class="contedo-contatos-text01"
              >Mostrando
              {{ listaContatos.length > 12 ? 12 : listaContatos.length }} de
              {{ listaContatos.length }} resultados</span
            >
            <div class="contedo-contatos-boto">
              <app-boto
                :disabled="itemsCSV.length == 0 ? true : false"
                :exportar="exportarCSV"
              ></app-boto>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isContatosEmpty" class="contedo-contatos-checkbox">
        <input
          @click="checkAll"
          :checked="check"
          :value="check"
          type="checkbox"
          class="contedo-contatos-checkbox01"
        />
        <span class="contedo-contatos-text01">Selecionar todos</span>
      </div>
      <!-- LISTA DE CONTATOS -->
      <spinner-loading v-if="loading == true"></spinner-loading>
      <empty-contatos v-else-if="isContatosEmpty"></empty-contatos>
      <div v-else class="contedo-contatos2-clientlist">
        <card-contato
          v-for="item in listaContatos.result"
          :key="item.id"
          :contato="item"
          :checked="itemsCSV.includes(item)"
          @functionCheck="itemToCSV(item)"
          @functionContato="getById(item)"
        ></card-contato>
      </div>
      <pagination
        v-if="!isContatosEmpty"
        :totalPerPage="12"
        :currentPage="page"
        :total="listaContatos.length"
        @update:currentPage="nextPage"
      ></pagination>
    </div>
    <!-- <detalhes-contato
      v-else
      :contato="currentContato"
      :voltar="getAll"
    ></detalhes-contato> -->
  </div>
</template>

<style scoped>
.contedo-contatos-group {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-contatos-header {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.contedo-contatos-title {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contedo-contatos-display {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contedo-contatos-text {
  color: var(--color-theme-primary);
  font-size: 45px;
  font-family: Fivo Sans Medium;
}
.contedo-contatos-actions {
  gap: 10px;
  flex: 0 0 auto;
  display: flex;
  max-width: 525px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.contedo-contatos-text01 {
  color: #737373;
  font-size: 16px;
  align-self: center;
  margin-top: 2px;
  margin-left: 15px;
}
.contedo-contatos2-clientlist {
  gap: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: flex-start;
  justify-content: flex-start;
}
.contedo-contatos-drop {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.contedo-contatos-drop-menu {
  height: 40px;
  display: flex;
  max-width: 525pxpx;
  align-self: flex-start;
  align-items: center;
  border-color: var(--color-theme-primary);
  border-width: 1px;
  border-radius: 10px;
  background-color: var(--dl-color-gray-white);
}
.contedo-contatos-drop-menu :hover {
  cursor: pointer;
}
.contedo-contatos-select {
  padding-left: 10px;
  padding-right: 10px;
}
.contedo-contatos-text02 {
  color: #737373;
  font-size: 16px;
  align-self: center;
  margin-top: 2px;
  margin-left: 5px;
}
.contedo-contatos-boto {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.contedo-contatos-checkbox {
  align-self: flex-end;
}
.contedo-contatos-pesquisa {
  flex: 0 0 auto;
  width: 30%;
  display: flex;
  align-items: flex-start;
}
@media (max-width: 1220px) {
  .contedo-contatos-text01 {
    font-size: 14px;
  }
  .contedo-contatos-text {
    font-size: 35px;
  }
}
@media (max-width: 957px) {
  .contedo-contatos-title {
    flex-wrap: wrap;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .contedo-contatos-title {
    align-items: flex-start;
    width: 100%;
    gap: 15px;
  }
  .contedo-contatos-pesquisa {
    width: 100%;
  }
  .contedo-contatos-actions {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .contedo-contatos-actions {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}
</style>
