<script setup>
import BotoIntegraes from "@/components/common/boto-integraes";
import { ref } from "vue";

const items = ref([
  {
    logo: "https://res.cloudinary.com/dd9puomgu/image/upload/v1695662933/rch2turvipza9xwka2b9.png",
  },
  {
    logo: "https://res.cloudinary.com/dd9puomgu/image/upload/v1695661065/jo3m8neiv2t2tizgq8gi.png",
  },
  {
    logo: "https://res.cloudinary.com/dd9puomgu/image/upload/v1695661065/ymrcnfbhfagk1kroiqeb.png",
  },
  {
    logo: "https://res.cloudinary.com/dd9puomgu/image/upload/v1695661066/hwimr4lii3rx2zgpva77.png",
  },
  {
    logo: "https://res.cloudinary.com/dd9puomgu/image/upload/v1695661066/pqtfcoadpjmmcozip79c.png",
  },
  {
    logo: "https://res.cloudinary.com/dd9puomgu/image/upload/v1695661066/xgp8lvpfa1jefla616ws.png",
  },
  {
    logo: "https://res.cloudinary.com/dd9puomgu/image/upload/v1695661067/ewbyeby9lgtrco0wssyr.png",
  },
  {
    logo: "https://res.cloudinary.com/dd9puomgu/image/upload/v1695661065/ztfi5qkh2xbvuebo4ghq.png",
  },
]);
</script>

<template>
  <div v-bind:class="String">
    <div class="contedo-integraes-group">
      <div class="contedo-integraes-header">
        <div class="contedo-integraes-display">
          <span class="contedo-integraes-text titulo">Integrações</span>
          <span class="subtitulo"
            >Aqui você pode integrar seu banco de dados com as principais
            plataformas de gerenciamento de CRM.</span
          >
        </div>
        <div class="contedo-integraes-button">
          <div class="contedo-integraes-actions">
            <div class="contedo-integraes-boto">
              <boto-integraes
                rootClassName="boto-integraes-root-class-name"
              ></boto-integraes>
            </div>
          </div>
        </div>
      </div>
      <div class="contedo-integraes-integraes">
        <div
          class="contedo-integraes-hubspot"
          v-for="item in items"
          :key="item"
        >
          <div class="contedo-integraes-logo">
            <img :src="item.logo" class="contedo-integraes-image" />
          </div>
          <button class="contedo-integraes-button1">
            <div class="contedo-integraes-container1">
              <span class="textbutton">Configurar</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.contedo-integraes-group {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-integraes-header {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.contedo-integraes-display {
  gap: 10px;
  flex: 0 0 auto;
  width: 80%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-integraes-text {
  color: var(--color-theme-primary);
  font-size: 45px;
  font-family: Fivo Sans Medium;
}
.contedo-integraes-button {
  flex: 0 0 auto;
  width: calc(20% - 10px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.contedo-integraes-actions {
  gap: 10px;
  flex: 0 0 auto;
  display: flex;
  max-width: 525px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.contedo-integraes-boto {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.contedo-integraes-integraes {
  gap: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.contedo-integraes-hubspot {
  width: 32%;
  border: 2px dashed var(--color-theme-primary);
  height: 200px;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  max-width: 350px;
}
.contedo-integraes-logo {
  flex: 0 0 auto;
  width: 90%;
  height: 90%;
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contedo-integraes-logo img {
  width: 95%;
  height: auto;
  max-height: 130px;
  display: block;
  object-fit: cover;
  transition: 0.3s;
  filter: grayscale(100%);
}
.contedo-integraes-button1 {
  flex: 0 0 auto;
  width: 100%;
  height: 30%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  filter: grayscale(100%);
}
.contedo-integraes-button1 :hover {
  cursor: pointer;
}
.contedo-integraes-container1 {
  flex: 0 0 auto;
  width: 50%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-self: flex-end;
  border-radius: 15px;
  justify-content: center;
  background-color: var(--color-theme-primary);
}
@media (max-width: 1200px) {
  .contedo-integraes-hubspot {
    width: 45%;
    border: 2px dashed var(--color-theme-primary);
    height: 200px;
    display: flex;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
    align-items: center;
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-gray-white);
  }
}
@media (max-width: 650px) {
  .subtitulo {
    display: none;
    visibility: hidden;
  }
  .contedo-integraes-header {
    width: 100%;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
  .contedo-integraes-display {
    width: 80%;
    align-items: center;
  }
  .contedo-integraes-button {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contedo-integraes-hubspot {
    width: 100%;
    border: 2px dashed var(--color-theme-primary);
    height: 200px;
    display: flex;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
    align-items: center;
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-gray-white);
  }
  .contedo-integraes-logo {
    height: 70%;
  }
 
}
</style>
