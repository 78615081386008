import EmpresaPlan from "@/models/EmpresaPlan";

export default class Empresa {
  constructor({ id, name, phone, cnpj, plan_id, createdAt, empresa_plan } = {}) {
    this.id = id ?? null;
    this.name = name ?? "";
    this.phone = phone ?? "";
    this.cnpj = cnpj ?? "";
    this.plan_id = plan_id ?? null;
    this.createdAt = createdAt ?? '';
    this.empresa_plan =
      empresa_plan != null
        ? new EmpresaPlan({ ...empresa_plan })
        : new EmpresaPlan();
  }
}
