<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const isVisible = computed(() => {
  return store.state.cadenceModule.popupDelete;
});

const cadence = computed(() => {
  return store.state.cadenceModule.selectedDeleteCadence;
});

const togglePopup = () => {
  store.commit("cadenceModule/SET_DELETE_POPUP", false);
};

async function setDelete(val) {
  val == false
    ? store.dispatch("cadenceModule/setDeleteCadence", {
        cadence: null,
        value: false,
      })
    : store.dispatch("cadenceModule/deleteCadence", cadence.value);
  togglePopup();
}
</script>

<template>
  <div class="modal" v-if="isVisible">
    <div class="novo-chamado-pop-upintro">
      <div class="novo-chamado-barra">
        <span class="novo-chamado-novo-chamado"
          >Tem certeza que deseja deletar a cadência "{{ cadence.name }}"?</span
        >
        <button @click="togglePopup">
          <img
            alt="image"
            src="https://api.iconify.design/icon-park-outline/close-one.svg?color=white"
            class="novo-chamado-image"
          />
        </button>
      </div>
      <div class="novo-chamado-entrada">
        <button
          @click="setDelete(false)"
          type="button"
          class="novo-chamado-button"
        >
          <span class="novo-chamado-text5">NÃO</span>
        </button>
        <button
          @click="setDelete(true)"
          type="button"
          class="novo-chamado-button-sim"
        >
          <span class="novo-chamado-text5-sim">SIM</span>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 900;
}
.novo-chamado-pop-upintro {
  width: 100%;
  height: 150px;
  margin: 0px;
  display: flex;
  position: relative;
  max-width: 600px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  max-height: 600px;
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.novo-chamado-barra {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: center;
  background-color: var(--color-theme-primary);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.novo-chamado-novo-chamado {
  width: calc(100% - 70px);
  color: var(--dl-color-gray-white);
  font-size: 18px;
  text-align: center;
  font-family: "Fivo Sans Medium";
}
.novo-chamado-image {
  top: 8px;
  right: 0px;
  width: 25px;
  position: absolute;
  justify-self: flex-end;
  margin-right: 10px;
}
.novo-chamado-entrada {
  flex: 0 0 auto;
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: row;
  padding-bottom: 30px;
  justify-content: space-evenly;
}
.novo-chamado-text {
  font-size: 18px;
  font-family: "Fivo Sans Medium";
}
.novo-chamado-tipo-de-chamado {
  gap: 10px;
  width: 100%;
  display: flex;
  margin-top: 30px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.novo-chamado-container {
  gap: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.novo-chamado-text1 {
  color: #737373;
  font-family: "Fivo Sans Medium";
}
.novo-chamado-select {
  padding: 5px;
  border-color: #cd0f37;
  border-width: 1px;
  border-radius: 7px;
}
.novo-chamado-textarea {
  width: 100%;
  height: 200px;
  margin-top: 30px;
}
.novo-chamado-priority {
  gap: 15px;
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  align-items: center;
}
.novo-chamado-urgente {
  gap: 5px;
  display: flex;
  align-items: center;
}
.novo-chamado-text2 {
  font-family: "Fivo Sans Medium";
}
.novo-chamado-pouco-urgente {
  gap: 5px;
  display: flex;
  align-items: center;
}
.novo-chamado-text3 {
  font-family: "Fivo Sans Medium";
}
.novo-chamado-nao-urgente {
  gap: 5px;
  display: flex;
  align-items: center;
}
.novo-chamado-text4 {
  font-family: "Fivo Sans Medium";
}
.novo-chamado-boto {
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 40px;
  margin: auto;
  display: flex;
  padding: 0px;
  position: absolute;
  align-items: center;
  justify-content: space-between;
}
.novo-chamado-button {
  color: #ffffff;
  height: 50px;
  display: flex;
  transition: 0.3s;
  font-family: Fivo Sans Medium;
  border-color: #cd0f37;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 7px;
  padding-right: 20px;
  flex-direction: row;
  background-color: #cd0f37;
}
.novo-chamado-button-sim {
  color: #ffffff;
  height: 50px;
  display: flex;
  transition: 0.3s;
  font-family: Fivo Sans Medium;
  border-color: #00a89c;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 7px;
  padding-right: 20px;
  flex-direction: row;
  background-color: #00a89c;
}
.novo-chamado-button-sim:hover {
  color: #00a89c;
  border-width: 1px;
  background-color: var(--dl-color-gray-white);
}
.novo-chamado-button:hover {
  color: #cd0f37;
  border-width: 1px;
  background-color: var(--dl-color-gray-white);
}
.novo-chamado-text5 {
  font-size: 20px;
  align-self: center;
  margin-top: 1px;
  transition: 0.3s;
  align-items: center;
  margin-left: 5px;
}
.novo-chamado-text5:hover {
  color: #cd0f37;
}
.novo-chamado-text5-sim {
  font-size: 20px;
  align-self: center;
  margin-top: 1px;
  transition: 0.3s;
  align-items: center;
  margin-left: 5px;
}
.novo-chamado-text5-sim:hover {
  color: #00a89c;
}
@media (max-width: 957px) {
  .novo-chamado-pop-upintro {
    margin: 0px;
    display: flex;
  }
}
@media (max-width: 767px) {
  .novo-chamado-pop-upintro {
    display: flex;
  }
}
@media (max-width: 479px) {
  .novo-chamado-pop-upintro {
    display: flex;
    max-height: 800px;
  }
  .novo-chamado-entrada {
    padding: 20px;
  }
}
</style>
