<script setup>
import AppLogin from '@/components/auth/login'
import cadastro from '@/components/auth/cadastro.vue';
import RecuperarSenha from '@/components/auth/recuperar-senha';
import toastAlert from '@/components/common/toast-alert.vue';

import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();

const currentURL = computed(() => {
  return store.state.url
})
</script>

<template>
  <div class="flash-growth-login-container">
    <toast-alert></toast-alert>
    <div class="flash-growth-login-content-left">
      <div class="flash-growth-login-imagehero">
        <img
          alt="image"
          src="/image_hero.svg"
          class="flash-growth-login-image"
        />
      </div>
    </div>
    <div class="flash-growth-login-content-right">
      <!-- <cadastro v-if="currentURL === 'cadastro'"></cadastro> -->
      <recuperar-senha v-if="currentURL === 'reset' "></recuperar-senha>
      <app-login v-else class="login-root-class-name"></app-login>
    </div>
  </div>
</template>

<style>
.flash-growth-login-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: row;
  background-color: #F5F5F5;
}
.flash-growth-login-content-left {
  flex: 0 0 auto;
  width: 50%;
  height: 100vh;
  display: flex;
  padding: 100px;
  align-self: center;
  visibility: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: F5F5F5;
}
.flash-growth-login-imagehero {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  min-width: 500px;
  align-items: center;
  flex-direction: column;
}
.flash-growth-login-image {
  color: red;
  margin-top: -50px;
  object-fit: cover;
}
.flash-growth-login-content-right {
  flex: 0 0 auto;
  width: 50%;
  height: 100vh;
  display: flex;
  padding: 40px;
  align-self: center;
  align-items: center;
  justify-content: center;
  background-color: var(--primaryColor);
}
@media(max-width: 957px) {
  .flash-growth-login-content-left {
    visibility: hidden;
  }
  .flash-growth-login-content-right {
    width: 100%;
    position: absolute;
    margin-right: 0px;
  }
}
@media(max-width: 479px) {
  .flash-growth-login-content-right {
    width: 100%;
    padding: 40px;
  }
}
</style>
