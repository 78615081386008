<script setup>
import { onMounted, ref, computed, defineProps, defineEmits, inject } from "vue";
import pagination from "@/components/common/pagination.vue";
import { useStore } from "vuex";
import InputText from "@/components/common/InputText.vue";
import spinnerLoading from "@/components/common/spinner-loading.vue";
import moment from "moment";

const primaryColor = inject('primaryColor').replace('#', '')
const props = defineProps({
  updateOportunity: {
    type: Function
  }
});
const emit = defineEmits(['update:selectedOportunity'])

const store = useStore();
const loading = ref(true);
const page = ref(1);
const pesquisa = ref("");
const sortBy = ref("");

const oportunitys = computed(() => {
  return store.state.oportunityModule.oportunitys;
});
const empresas = computed(() => {
  return store.state.empresaModule.empresas;
});

const listaOportunitys = computed(() => {
  var final = null;
  if (pesquisa.value == "") {
    final = oportunitys.value;
  } else {
    final = oportunitys.value.filter((op) => {
      let currentSort = null;
      switch (sortBy.value) {
        case "nome":
          currentSort = op.nome
            .toLowerCase()
            .includes(pesquisa.value.toLowerCase());
          break;
        case "empresa":
          currentSort = getOportunityEmpresa(op)
            .toLowerCase()
            .includes(pesquisa.value.toLowerCase());
          break;
        default:
          currentSort = op.nome
            .toLowerCase()
            .includes(pesquisa.value.toLowerCase());
          break;
      }
      return currentSort;
    });
    page.value = 1;
  }
  let finalOportunitys = final
    .sort(compararDatas)
    .slice(page.value * 15 - 15, page.value * 15);

  return { result: finalOportunitys, length: final.length };
});
onMounted(() => {
  loading.value = true;
  try {
    store.dispatch("oportunityModule/getAllOportunity");
    store.dispatch("empresaModule/getAllEmpresas");
    loading.value = false;
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
});

const nextPage = (newPage) => {
  page.value = newPage;
};

const setPopup = () => {
  store.commit('oportunityModule/SET_POPUP_CRIAR_OPORTUNITY', true)
};

function compararDatas(a, b) {
  const dataA = new Date(a.created_date);
  const dataB = new Date(b.created_date);
  return dataB - dataA;
}

function getOportunityEmpresa(op) {
  let selected_empresa = empresas.value.find(
    (empresa) => empresa.id === op.empresa_id
  );
  return selected_empresa ? selected_empresa.name : "-";
}
function edit(item) {
  emit('update:selectedOportunity', item)
  store.commit('oportunityModule/SET_POPUP_CRIAR_OPORTUNITY', true)
}
</script>

<template>
  <div class="content-view">
    <div class="contedo-helpdesk-container1">
      <div class="contedo-helpdesk-header">
        <div class="contedo-helpdesk-container2">
          <div class="contedo-helpdesk-display">
            <span class="contedo-helpdesk-text titulo">Oportunitys</span>
          </div>
          <div class="contedo-helpdesk-pesquisa">
            <input-text
              label="Pesquisar"
              icon="search-outline"
              v-model:value="pesquisa"
            ></input-text>
          </div>
          <div v-if="!isHelpdeskEmpty" class="contedo-helpdesk-container4">
            <select v-model="sortBy" class="contedo-helpdesk-select Optionmenu">
              <option value="empresa">Empresa</option>
              <option value="nome">Nome</option>
            </select>
          </div>
          <div class="contedo-helpdesk-actions">
            <button @click="setPopup" class="contedo-helpdesk-boto">
              <span class="contedo-helpdesk-text01 textbutton"
                >+ Criar oportunity</span
              >
            </button>
          </div>
        </div>
      </div>

      <!-- LISTA DE OPORTUNITYS -->
      <spinnerLoading v-if="loading"></spinnerLoading>
      <div v-else class="table-container">
        <div class="contedo-helpdesk-clientlist">
          <table class="table">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Telefone</th>
                <th>Empresa</th>
                <th>Passagem de bastão</th>
                <th>Houve reunião?</th>
                <th>É qualificado?</th>
                <th>Score</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in listaOportunitys.result"
                :key="index"
                :class="{
                  'linha-separada': index !== listaOportunitys.length - 1,
                }"
              >
                <td>{{ item.nome == '' ? '-' : item.nome }}</td>
                <td>{{ item.email == '' ? '-' : item.email }}</td>
                <td>{{ item.telefone ? item.telefone : "-" }}</td>
                <td>{{ getOportunityEmpresa(item) }}</td>
                <td>
                  {{
                    `${moment(item.created_date)
                      .locale("pt")
                      .format("dddd")}, ${moment(item.created_date)
                      .locale("pt")
                      .format("LL")}`
                  }}
                </td>
                <td>
                  {{
                    item.call == true ? "Sim" : item.call == false ? "Não" : "-"
                  }}
                </td>
                <td>
                  {{
                    item.qualified == true
                      ? "Sim"
                      : item.qualified == false
                      ? "Não"
                      : "-"
                  }}
                </td>
                <td>
                  <div class="clientvaluemobile-stars">
                    <div v-for="i in item.score" :key="i">
                      <img
                        alt="image_alt42"
                        :src="`https://api.iconify.design/fluent/star-12-filled.svg?color=%23${primaryColor}`"
                        class="clientvaluemobile-image1"
                      />
                    </div>
                    <div v-for="i in 5 - item.score" :key="i">
                      <img
                        alt="image_alt43"
                        src="https://api.iconify.design/fluent/star-12-filled.svg?color=%23d3d3d3"
                        class="clientvaluemobile-image5"
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <button class="edit-icon" @click="edit(item)">
                    <ion-icon name="create-outline"></ion-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <pagination
        :currentPage="page"
        :totalPerPage="15"
        :total="listaOportunitys.length"
        @update:currentPage="nextPage"
      ></pagination>
    </div>
  </div>
</template>

<style scoped>
.contedo-helpdesk-container1 {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-helpdesk-header {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.contedo-helpdesk-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contedo-helpdesk-display {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contedo-helpdesk-text {
  color: var(--color-theme-primary);
  font-size: 45px;
  font-family: Fivo Sans Medium;
}
.contedo-helpdesk-actions {
  gap: 20px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.contedo-helpdesk-pesquisa {
  flex: 0 0 auto;
  width: 30%;
  height: 40px;
  display: flex;
  align-items: center;
}
.contedo-helpdesk-container4 {
  height: 40px;
  display: flex;
  align-self: center;
  align-items: center;
  border-color: var(--color-theme-primary);
  border-width: 2px;
  border-radius: 10px;
  background-color: var(--dl-color-gray-white);
}
.contedo-helpdesk-container4 :hover {
  cursor: pointer;
}
.contedo-helpdesk-select {
  padding-left: 10px;
  padding-right: 10px;
}
.contedo-helpdesk-boto {
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  align-items: center;
  padding-top: 0px;
  padding-left: 40px;
  border-radius: 10px;
  padding-right: 40px;
  padding-bottom: 0px;
  justify-content: flex-end;
  background-color: var(--color-theme-primary);
}
.contedo-helpdesk-boto :hover {
  cursor: pointer;
}
.contedo-helpdesk-text01 {
  font-size: 15px;
}
.table-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding: 20px 0;
}
.contedo-helpdesk-clientlist {
  flex: 0 0 auto;
  min-height: 400px;
  height: fit-content;
  width: 100%;
  display: flex;
  border-width: 20px;
  border-color: #fff;
  background-color: #ffffff;
  border-radius: 8px;
  align-items: flex-start;
  justify-content: space-between;
  overflow-x: scroll;
  flex-direction: column;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar {
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}
.table {
  width: max-content;
  min-width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th,
td {
  border-bottom: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}
th:first-child,
td:first-child {
  width: 20%;
}
th:nth-child(2),
td:nth-child(2) {
  width: 20%;
}
th:nth-child(5),
td:nth-child(5) {
  width: 40%;
}

.linha-separada td {
  border-bottom: 1px solid #dddddd;
}
.clientvaluemobile-stars {
  display: flex;
  padding-left: 0px;
  flex-direction: row;
}
.edit-icon ion-icon {
  font-size: 25px;
  color: var(--color-theme-primary);
}
.edit-icon :hover {
  cursor: pointer;
}
@media (max-width: 990px) {
  .contedo-helpdesk-container2 {
    flex-wrap: wrap;
  }
  .contedo-helpdesk-pesquisa {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .contedo-helpdesk-container2 {
    gap: 15px;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
  }
  .contedo-helpdesk-text {
    font-size: 35px;
  }
  .contedo-helpdesk-pesquisa {
    width: 100%;
  }
  .contedo-helpdesk-actions {
    width: 100%;
  }
  .contedo-helpdesk-boto {
    width: 60%;
    align-items: center;
    justify-content: center;
  }
  .contedo-helpdesk-container4{
    width: 100%;
  }
  .contedo-helpdesk-container4 select {
    width: 100%;
  }
  .contedo-helpdesk-actions button {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .contedo-helpdesk-actions {
    flex-direction: column;
    align-items: flex-start;
  }
  .contedo-helpdesk-text {
    font-size: 30px;
  }
  .contedo-helpdesk-container4 {
    width: 100%;
    padding: 10px;
  }
  .contedo-helpdesk-boto {
    width: 100%;
  }
  .contedo-helpdesk-select {
    width: 100%;
  }
}
</style>
