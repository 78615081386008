import moment from "moment/moment";
import FirebaseController from "./FirebaseController";
import { arrayUnion } from "firebase/firestore";

const now = Date.now()
function randomChar() {
  const caracteres = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
  return caracteres.charAt(indiceAleatorio);
}

function randomString(tamanho) {
  let s = "";
  for (let i = 0; i < tamanho; i++) {
    s += randomChar();
  }
  return s;
}

const anoMesDia = moment().format().substring(0, 10)

export default class HelpdeskController extends FirebaseController {
  constructor() {
    super();
  }
  // Cria em Helpdesk dentro  do doc com Id do usuário que abriu o chamado
  async createHelpdesk(payload) {
    return super.createWithCustomId("helpdesk", `flash_${anoMesDia}_${randomString(4)}`, payload);
  }

  // Pega todos os docs da collection 'chamados'
  getAllHelpdesk() {
    return super.streamCollection('helpdesk')
  }

  // Pegar os chamados do usuário especificado
  async getHelpdeskByUserId(userId) {
    return await super.readByQuery("helpdesk", "owner_id", userId);
  }

  async updateHelpdesk(docId, payload) {
    await super.update("helpdesk", docId, { ...payload, updated_date: now });
  }

  async addMessages(docId, payload) {
    return await super.update("helpdesk", docId, {
      messages: arrayUnion(payload),
      updated_date: now,
    });
  }
  
}
