<script setup>
import { onMounted, ref, computed } from "vue";
import pagination from "@/components/common/pagination.vue";
import { useStore } from "vuex";
import acompanharChamado from "@/components/dashboard/helpdesk/acompanhar-chamado.vue";
import InputText from "@/components/common/InputText.vue";
import ticketSupport from "@/components/dashboard/helpdesk/ticket-support.vue";
import emptyHelpdesk from "@/components/common/empty-helpdesk.vue";
import spinnerLoading from "@/components/common/spinner-loading.vue";

const store = useStore();
const loading = ref(true);
const isGeneralData = ref(true);
const page = ref(1);
const currentChamado = ref({});
const pesquisa = ref("");
const sortBy = ref("Status");

const currentUser = computed(() => {
  return store.state.userModule.currentUser;
});

const chamados = computed(() => {
  return store.state.helpdeskModule.selectedUserHelpdesk;
});

const listaChamados = computed(() => {
  var final = null;
  if (pesquisa.value == "") {
    final = chamados.value;
  } else {
    final = chamados.value.filter((chamado) => {
      let currentSort = null;
      switch (sortBy.value) {
        case "Numero":
          currentSort = chamado.id
            .toLowerCase()
            .includes(pesquisa.value.toLowerCase());
          break;

        case "Motivo":
          currentSort = chamado.reason
            .toLowerCase()
            .includes(pesquisa.value.toLowerCase());
          break;

        case "Status":
          currentSort = chamado.status
            .toLowerCase()
            .includes(pesquisa.value.toLowerCase());
          break;

        default:
          currentSort = chamado.status
            .toLowerCase()
            .includes(pesquisa.value.toLowerCase());
          break;
      }

      return currentSort;
    });
    page.value = 1;
  }
  let finalChamados = final
    .sort(compararDatas)
    .slice(page.value * 10 - 10, page.value * 10);
  return { result: finalChamados, length: final.length };
});

const isHelpdeskEmpty = computed(() => {
  return chamados.value.length == 0 ? true : false;
});
onMounted(() => {
  loading.value = true;
  try {
    store.dispatch("helpdeskModule/getByOwnerId", currentUser.value.uid);
    loading.value = false;
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
});

const nextPage = (newPage) => {
  page.value = newPage;
};

const getById = (item) => {
  loading.value = true;
  isGeneralData.value = false;
  currentChamado.value = item.id;
  loading.value = false;
};

const setPopup = () => {
  store.commit("SET_POPUP_CHAMADO", true);
};

function voltar() {
  isGeneralData.value = true;
}
function compararDatas(a, b) {
  const dataA = a.updated_date;
  const dataB = b.updated_date;
  return dataB - dataA;
}
</script>

<template>
  <div v-bind:class="String">
    <div v-if="isGeneralData" class="contedo-helpdesk-container1">
      <div class="contedo-helpdesk-header">
        <div class="contedo-helpdesk-container2">
          <div class="contedo-helpdesk-display">
            <span class="contedo-helpdesk-text titulo">Helpdesk</span>
          </div>
          <div v-if="!isHelpdeskEmpty" class="contedo-helpdesk-pesquisa">
            <input-text
              label="Pesquisar"
              icon="search-outline"
              v-model:value="pesquisa"
            ></input-text>
          </div>
          <div class="contedo-helpdesk-actions">
            <div v-if="!isHelpdeskEmpty" class="contedo-helpdesk-container4">
              <select
                v-model="sortBy"
                class="contedo-helpdesk-select Optionmenu"
              >
                <option value="Numero">Número</option>
                <option value="Motivo">Motivo</option>
                <option value="Status">Status</option>
              </select>
            </div>
            <button @click="setPopup" class="contedo-helpdesk-boto">
              <span class="contedo-helpdesk-text01 textbutton"
                >+ Novo chamado</span
              >
            </button>
          </div>
        </div>
      </div>
      <!-- LISTA DE CHAMADOS -->
      <spinnerLoading v-if="loading"></spinnerLoading>
      <emptyHelpdesk v-else-if="isHelpdeskEmpty && !loading"></emptyHelpdesk>
      <div v-else class="contedo-helpdesk-clientlist">
        <ticket-support
          @click="getById(item)"
          v-for="item in listaChamados.result"
          :key="item.id"
          :item="item"
        ></ticket-support>
      </div>
      <pagination
        v-if="!isHelpdeskEmpty"
        :currentPage="page"
        :total="listaChamados.length"
        @update:currentPage="nextPage"
      ></pagination>
    </div>
    <acompanharChamado
      type="user"
      v-else
      :chamadoId="currentChamado"
      :voltar="voltar"
    ></acompanharChamado>
  </div>
</template>

<style scoped>
.contedo-helpdesk-container1 {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-helpdesk-header {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.contedo-helpdesk-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contedo-helpdesk-display {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contedo-helpdesk-text {
  color: var(--color-theme-primary);
  font-size: 45px;
  font-family: Fivo Sans Medium;
}
.contedo-helpdesk-actions {
  gap: 20px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.contedo-helpdesk-pesquisa {
  flex: 0 0 auto;
  width: 30%;
  height: 40px;
  display: flex;
  align-items: center;
}
.contedo-helpdesk-container4 {
  height: 40px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-color: var(--color-theme-primary);
  border-width: 2px;
  border-radius: 10px;
  background-color: var(--dl-color-gray-white);
}
.contedo-helpdesk-container4 :hover {
  cursor: pointer;
}
.contedo-helpdesk-select {
  padding-left: 10px;
  padding-right: 10px;
}
.contedo-helpdesk-boto {
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  align-items: center;
  padding-top: 0px;
  padding-left: 40px;
  border-radius: 10px;
  padding-right: 40px;
  padding-bottom: 0px;
  justify-content: flex-end;
  background-color: var(--color-theme-primary);
}
.contedo-helpdesk-boto :hover {
  cursor: pointer;
}
.contedo-helpdesk-text01 {
  font-size: 15px;
}
.contedo-helpdesk-clientlist {
  gap: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
@media (max-width: 990px) {
  .contedo-helpdesk-container2 {
    flex-wrap: wrap;
  }
  .contedo-helpdesk-pesquisa {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .contedo-helpdesk-container2 {
    gap: 15px;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
  }
  .contedo-helpdesk-text {
    font-size: 35px;
  }
  .contedo-helpdesk-pesquisa {
    width: 100%;
  }
  .contedo-helpdesk-actions {
    width: 100%;
  }
  .contedo-helpdesk-boto {
    width: 60%;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 479px) {
  .contedo-helpdesk-actions {
    flex-direction: column;
    align-items: flex-start;
  }
  .contedo-helpdesk-text {
    font-size: 30px;
  }
  .contedo-helpdesk-container4 {
    width: 100%;
    padding: 10px;
  }
  .contedo-helpdesk-boto {
    width: 100%;
  }
  .contedo-helpdesk-select {
    width: 100%;
  }
}
</style>
