<template>
  <div class="logo-menu-logo">
    <img alt="image" src="/logo_growth.svg" class="logo-menu-image" style="margin-top: 15px; margin-bottom: 40px"/>
    <span class="logo-menu-text">flash [Growth]</span>
  </div>
</template>

<style scoped>
.logo-menu-logo {
  width: 300px;
  height: 50px;
  position: relative;
  padding: 0 0 15px 15px;
  background: var(--color-theme-primary);
  justify-content: center;
  align-items: center;
}
.logo-menu-image {
  z-index: 10;
  position: absolute;
  clip: rect(0px, 40px, 200px, 0px);
}
.logo-menu-text {
  font-family: Fivo Sans Medium;
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding: 25px 0px 0px 55px;
  display: flex;
}
</style>
