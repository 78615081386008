export default class EmpresaPlan {
  constructor({ id, name, description, price, credits, uId } = {}) {
    this.id = id ?? null;
    this.name = name ?? "";
    this.description = description ?? "";
    this.price = price ?? null;
    this.credits = credits ?? null;
    this.uid = uId ?? null;
  }

  static toEmpresaPlan(data) {
    return new EmpresaPlan({ ...data });
  }
}
