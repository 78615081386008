<script setup>
import MenuLateral from "@/components/dashboard/menu/menu-lateral";
import ContedoDashboard2 from "@/components/dashboard/dash/contedo-dashboard2";
import contedoContatos from "@/components/dashboard/contatos/contedo-contatos.vue";
import contedoSettings from "@/components/dashboard/configurações/contedo-settings.vue";
import contedoHelpdesk from "@/components/dashboard/helpdesk/contedo-helpdesk.vue";
import contedoIntegraes from "@/components/dashboard/integrações/contedo-integraes.vue";
import menuSuperior from "@/components/dashboard/menu/menu-superior.vue";
import detalhesContato from "@/components/dashboard/contatos/detalhes-contato.vue";

import popUpChamado from "@/components/popups/pop-up-chamado.vue";
import toastAlert from "@/components/common/toast-alert.vue";
import popUpUsername from "@/components/popups/pop-up-username.vue";
import popUpIntro from "@/components/popups/pop-up-intro.vue";
import popUpEmail from "@/components/popups/pop-up-email.vue";
import popUpConfirmEvaluation from "@/components/popups/pop-up-confirm-evaluation.vue";
import popUpEmpresa from "@/components/popups/pop-up-empresa.vue";

const isVisible = ref(true);
const isChamadoVisible = ref(true);

import { computed, ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const currentURL = computed(() => {
  return route.params.id;
});
</script>

<template>
  <div class="growth-dashboard2-container">
    <toast-alert></toast-alert>
    <pop-up-confirm-evaluation></pop-up-confirm-evaluation>
    <pop-up-username></pop-up-username>
    <pop-up-email></pop-up-email>
    <pop-up-empresa></pop-up-empresa>

    <popUpIntro v-if="isVisible"></popUpIntro>
    <pop-up-chamado v-if="isChamadoVisible"></pop-up-chamado>

    <menu-lateral
      id="menu-lateral"
      rootClassName="menu-lateral-root-class-name6"
    ></menu-lateral>
    <menu-superior id="menu-superior"></menu-superior>
    <div class="content">
      <contedo-contatos
        class="content-view"
        v-if="currentURL == 'contatos'"
      ></contedo-contatos>
      <contedo-settings
        class="content-view"
        v-else-if="currentURL == 'configurações'"
      ></contedo-settings>
      <contedo-helpdesk
        class="content-view"
        v-else-if="currentURL == 'helpdesk'"
      ></contedo-helpdesk>
      <contedo-integraes
        class="content-view"
        v-else-if="currentURL == 'integrações'"
      ></contedo-integraes>
      <detalhes-contato
        v-else-if="route.name == 'detalhes-contato'"
      ></detalhes-contato>
      <contedo-dashboard2 v-else></contedo-dashboard2>
    </div>
  </div>
</template>

<style scoped>
.growth-dashboard2-container {
  width: 100%;
  display: flex;
  max-height: 100vh;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5;
}
#menu-lateral {
  gap: 30px;
  height: 100%;
  width: 300px;
  min-width: 300px;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  padding-left: 10px;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--color-theme-primary);
  box-sizing: border-box;
  transition: all ease 0.3s;
}

@media (max-width: 767px) {
  .growth-dashboard2-container {
    flex-direction: column;
  }
  #menu-lateral {
    visibility: hidden;
    display: none;
  }
}
</style>
