import BaseController from "./BaseController";

export default class CadenceController extends BaseController {
  constructor() {
    super()
  }

  async createCadence(cadence) {
   return await super.create('cadence', cadence).then((res) => {
    return res.data.data;
   })
  }

  async getAllCadences() {
    return await super.getAll('cadence')
  }

  async getCadenceById(id) {
    return await super.getById('cadence', id)
  }

  async updateCadence(id, cadence) {
    await super.update('cadence', id, cadence)
  }

  async deleteCadence(id) {
    console.log('ENTROU NO CONTROLLER ==>>', id)
    await super.delete('cadence', id)
  }

  async getAllTemplates() {
    return await super.getAll('templates').then((res) => {
      return res.data
    })
  }

  async deleteTemplate(id) {
    await super.delete('templates', `?name=${id}`)
  }
}
