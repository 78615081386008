<script setup>
import { ref, inject, defineEmits, onMounted, computed, reactive } from "vue";
import InputText from "@/components/common/InputText.vue";
import { quillEditor } from "vue3-quill";
import Datepicker from "vue3-datepicker";
import moment from "moment";
import { useStore } from "vuex";
import { pt } from "date-fns/locale";
import cardLead from "./card-lead.vue";

const emit = defineEmits(["update:voltar"]);
const primaryColor = inject("primaryColor").replace("#", "");
const store = useStore();
const minDate = ref(getTomorrow());
const selectedDate = ref(null);
const finalDate = ref(null);
const selectedEmpresa = ref({ id: 1, name: "Flashvolve 1" });
const route = ref("cadence");
const newCadence = ref(null);

const companys = computed(() => {
  return store.state.companyModule.companys;
});
const leads = computed(() => {
  var finalLeads = null;
  if (pesquisa.value == "") {
    finalLeads = store.state.leadModule.leads;
  } else {
    finalLeads = store.state.leadModule.leads.filter((lead) => {
      return lead.full_name
        .toLowerCase()
        .includes(pesquisa.value.toLowerCase());
    });
  }
  return finalLeads;
});
const cadencia = ref({
  name: "",
  status: "ativo",
  date_start: "",
  date_end: null,
  step: [],
});
const steps = ref([
  {
    next_time: 0,
    template_id: null,
  },
]);
const templates = ref([
  {
    name: "",
    description: "",
    template: "",
    tag: "",
    empresa_d: selectedEmpresa.value.id,
    time: 0,
  },
]);
const pesquisa = ref("");

onMounted(() => {
  store.dispatch("leadModule/getAll");
  store.dispatch("companyModule/getAllCompanys");
});

const adicionarTemplate = (index) => {
  if (
    templates.value[index].description != "" &&
    templates.value[index].template != "" &&
    cadencia.value.name != ""
  ) {
    if (index != 0 && templates.value[index].time <= 0) {
      store.dispatch("setDialog", {
        message: "Erro: o campo follow-up deve ser um número positivo.",
        type: "warning",
        icon: "alert-circle-outline",
      });
    } else {
      templates.value.push({
        name: "",
        description: "",
        template: "",
        tag: "",
        empresa_id: selectedEmpresa.value.id,
        time: 0,
      });

      steps.value.push({
        next_time: 0,
        template_id: null,
      });
    }
  } else {
    store.dispatch("setDialog", {
      message:
        "Erro: os campos nome da campanha, assunto, conteúdo e data do e-mail são obrigatórios.",
      type: "warning",
      icon: "alert-circle-outline",
    });
  }
};

const salvarCadencia = async () => {
  if (
    selectedDate.value != null &&
    templates.value[0].description != "" &&
    templates.value[0].template != "" &&
    cadencia.value.name != ""
  ) {
    let finalMesAno = `${getMesAno(selectedDate.value).mes}_${
      getMesAno(selectedDate.value).ano
    }`;

    let empresa_name = selectedEmpresa.value.name.replace(/ /g, "_");
    let cadencia_name = cadencia.value.name.replace(/ /g, "_");
    var finalSteps = [];

    //salva as informações referentes ao template atual
    for (var i = 0; i < templates.value.length; i++) {
      if (
        templates.value[i].description != "" &&
        templates.value[i].template != "" &&
        (i == 0 || templates.value[i].time > 0)
      ) {
        let template_name = `${empresa_name}-${cadencia_name}-${i}-${gerarCodigoAleatorio()}`;
        let step = {
          next_time: "",
          template_id: template_name,
          template: {
            name: template_name,
            description: templates.value[i].description,
            template: templates.value[i].template,
            tag: "",
            empresa_id: selectedEmpresa.value.id,
          },
        };

        finalSteps.push(step);
      } else {
        store.dispatch("setDialog", {
          message:
            "Aviso: apenas os templates com TODAS as informações preenchidas serão salvos.",
          type: "warning",
          icon: "alert-circle-outline",
        });
      }
    }

    //salva informações referentes ao próximo template e as tags
    for (var j = 0; j < finalSteps.length; j++) {
      if (j != finalSteps.length - 1) {
        finalSteps[j].next_time = convertTime(j + 1);
      }
      finalSteps[j].template.tag = [
        finalSteps[j].template.name,
        selectedEmpresa.value.name,
        finalMesAno,
      ];
    }

    cadencia.value.step = finalSteps;
    cadencia.value.date_start = finalDate.value;
    //pega o next_time do penultimo template, que armazena quando será enviado o ultimo email
    cadencia.value.date_end = finalSteps[finalSteps.length - 2].next_time;
    cadencia.value.name = `${empresa_name}-${cadencia_name}`;

    // PASSA P/ O SEGUNDO STEP DE ADICIONAR OS LEADS NA CADÊNCIA
    route.value = "leads";
  } else {
    store.dispatch("setDialog", {
      message:
        "Erro: os campos nome da campanha, assunto, conteúdo e data do e-mail são obrigatórios.",
      type: "warning",
      icon: "alert-circle-outline",
    });
  }
};

//falta aadicionar a exclusão do resto, tipo step se ele for mesmo continuar sendo usado
const excluirTemplate = () => {
  templates.value.pop();
  steps.value.pop();
};

// Função para converter a data selecionada de tempo para milissegundos
function convertTime(index) {
  if (index == 1) {
    //passa o index do proximo template, q é onde está guardado o time
    let data_inicio = moment(selectedDate.value).format("x");
    finalDate.value = 28800000 + parseInt(data_inicio, 10); //SelectedDate convertido p/ timestamp às 08:00

    let t = finalDate.value + templates.value[index].time * 24 * 60 * 60 * 1000;
    steps.value[1].next_time = t; //armazena o tempo convertido no step atual
    return t;
  } else {
    let current_time =
      steps.value[index - 1].next_time +
      templates.value[index].time * 24 * 60 * 60 * 1000; //timestamp do próximo email às 08:00
    steps.value[index].next_time = current_time;
    return current_time; // para transformar current_time em data, é só usar new Date(current_time)
  }
}

function getTomorrow() {
  const dataAtual = new Date();
  const dataAmanha = new Date(dataAtual);

  // Ajusta a data para amanhã
  dataAmanha.setDate(dataAtual.getDate() + 1);

  return dataAmanha;
}

// Impede a propagação do evento de clique dentro do calendário
const impedirFechamento = (event) => {
  event.stopPropagation();
};

function getMesAno(data) {
  const mes = data.getMonth() + 1; // Os meses começam do zero, então adicionamos 1
  const ano = data.getFullYear();

  return { mes, ano };
}

function gerarCodigoAleatorio() {
  const caracteresPermitidos =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let codigoAleatorio = "";

  for (let i = 0; i < 4; i++) {
    const indiceAleatorio = Math.floor(
      Math.random() * caracteresPermitidos.length
    );
    codigoAleatorio += caracteresPermitidos.charAt(indiceAleatorio);
  }

  return codigoAleatorio;
}

const voltar = () => {
  route.value == "cadence" ? emit("update:voltar") : (route.value = "cadence");
};

const check = ref(false);
const selectedLeads = ref([]);
const checkAll = () => {
  if (check.value == true) {
    check.value = false;
    leads.value.forEach(() => {
      selectedLeads.value.pop();
    });
  } else {
    check.value = true;
    leads.value.forEach((element) => {
      selectedLeads.value.push(element);
    });
  }
};
const selectLead = (item) => {
  if (selectedLeads.value.includes(item)) {
    let itemPraTirar = selectedLeads.value.indexOf(item);
    selectedLeads.value.splice(itemPraTirar, 1);
  } else {
    selectedLeads.value.push(item);
  }
};

async function addLeads() {
  await store
    .dispatch("cadenceModule/createCadence", cadencia.value)
    .then((res) => {
      newCadence.value = res;
      store.dispatch("leadModule/addLeadCadence", {
        leads: selectedLeads.value,
        cadence: newCadence.value,
      });
      return res;
    })
    .finally(() => {
      store.dispatch("cadenceModule/getAll");
      emit("update:voltar");
    });
}

const getLeadCompany = (lead) => {
  let comp = companys.value.find((comp) => comp.id == lead.company_id);
  return comp.name;
};
</script>

<template>
  <div class="contedo-integraes-group">
    <div class="contedo-integraes-header">
      <span class="contedo-integraes-text titulo">Criar cadência</span>
      <div v-if="route != 'cadence'" class="contedo-helpdesk-pesquisa">
        <input-text
          label="Pesquisar"
          icon="search-outline"
          v-model:value="pesquisa"
        ></input-text>
      </div>
      <div class="acompanhar-chamado-voltar">
        <div class="acompanhar-chamado-container">
          <button
            @click="voltar"
            type="button"
            class="acompanhar-chamado-button"
          >
            <img
              :src="`https://api.iconify.design/material-symbols/arrow-back-rounded.svg?color=%23${primaryColor}`"
              class="acompanhar-chamado-image"
            />
            <span class="acompanhar-chamado-text02">Voltar</span>
          </button>
        </div>
      </div>
    </div>
    <div v-if="route != 'cadence'" class="contedo-contatos-checkbox">
      <div class="contedo-contatos-checkbox">
        <input
          @click="checkAll"
          :checked="check"
          :value="check"
          type="checkbox"
          class="contedo-contatos-checkbox01"
        />
        <span class="contedo-contatos-text01">Selecionar todos</span>
      </div>
      <button
        v-if="selectedLeads.length > 0"
        @click="addLeads"
        class="contedo-helpdesk-boto"
      >
        <span class="contedo-helpdesk-text01 textbutton"
          >Adicionar leads a cadência</span
        >
      </button>
    </div>
    <div class="contedo-integraes-integraes">
      <div v-if="route == 'cadence'" class="contedo">
        <input-text
          style="width: 35%"
          label="Nome da campanha"
          v-model:value="cadencia.name"
        ></input-text>
        <div class="section01">
          <span class="section-titulo">PATH</span>
          <div
            class="container-template-email"
            v-for="(template, index) in templates"
            :key="index"
          >
            <div class="dashed-line"></div>
            <span v-if="index == 0" class="subtitulo"
              >Começar a campanha em:
            </span>
            <span v-else class="subtitulo">Disparar depois de: </span>
            <div v-if="index == 0" class="select-container">
              <Datepicker
                style="
                  --vdp-selected-bg-color: var(--color-theme-primary);
                  --vdp-hover-bg-color: var(--color-theme-primary);
                  --vdp-heading-size: 10px;
                "
                class="date-picker"
                @click.stop="impedirFechamento"
                v-model="selectedDate"
                :weekStartsOn="0"
                :lowerLimit="minDate"
                :startingViewDate="minDate"
                :locale="pt"
                :clearable="true"
              />
            </div>
            <div v-else>
              <span
                >Follow-up no mínimo após
                <input
                  type="number"
                  class="input-followup timer"
                  v-model="template.time"
                />
                dias do último e-mail, a menos que o prospecto responda.</span
              >
            </div>
            <div id="calendar"></div>
            <div class="dashed-line"></div>
            <span class="subtitulo">E-mail</span>
            <div class="template-email">
              <span>Template {{ index + 1 }}</span>
              <input-text
                v-model:value="template.description"
                label="Assunto do e-mail"
              ></input-text>
              <quillEditor v-model:value="template.template"></quillEditor>
              <div
                v-if="index == templates.length - 1 && index != 0"
                class="template-email-buttons"
              >
                <button class="button-excluir" @click="excluirTemplate">
                  <span>Excluir</span>
                </button>
              </div>
            </div>
            <span
              v-if="index == templates.length - 1"
              class="adicionar-template subtitulo"
              @click="adicionarTemplate(index)"
              >Adicionar template de e-mail</span
            >
          </div>
          <button
            v-if="steps.length > 1"
            class="button-salvar"
            @click="salvarCadencia"
          >
            <span>Salvar cadência</span>
          </button>
        </div>
      </div>
      <div v-else class="table-container">
        <div class="contedo-helpdesk-clientlist">
          <table class="table">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Telefone</th>
                <th>Empresa</th>
                <th>Cargo</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(lead, index) in leads"
                :key="index"
                :class="{
                  'linha-separada': index !== leads.length - 1,
                }"
              >
                <td>{{ lead.full_name == "" ? "-" : lead.full_name }}</td>
                <td>
                  {{ lead.email == "" ? "-" : lead.email }}
                </td>
                <td>{{ lead.phone }}</td>
                <td>
                  {{ lead.company_id == null ? "-" : getLeadCompany(lead) }}
                </td>
                <td>{{ lead.position == null ? "-" : lead.position }}</td>
                <td>
                  <button @click="selectLead(lead)">
                    <input
                      :checked="selectedLeads.includes(lead)"
                      type="checkbox"
                      class="contedo-contatos-checkbox03"
                    />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.contedo-integraes-group {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-integraes-header {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.contedo-integraes-text {
  color: var(--color-theme-primary);
  font-size: 45px;
  font-family: Fivo Sans Medium;
}
.acompanhar-chamado-container {
  display: flex;
}
.acompanhar-chamado-button {
  color: #ffffff;
  height: 50px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  font-family: Fivo Sans Medium;
  border-color: var(--color-theme-primary);
  border-width: 0px;
  padding-left: 20px;
  border-radius: 7px;
  padding-right: 20px;
  flex-direction: row;
  background-color: var(--color-theme-primary);
}
.acompanhar-chamado-button:hover {
  color: var(--color-theme-primary);
  border-width: 1px;
  background-color: var(--dl-color-gray-white);
}
.acompanhar-chamado-image {
  filter: brightness(0) invert(1);
  width: 25px;
  height: 30px;
  object-fit: cover;
  transition: 0.3s;
}
.acompanhar-chamado-button:hover .acompanhar-chamado-image {
  filter: none;
}
.acompanhar-chamado-text02 {
  font-size: 18px;
  align-self: center;
  margin-top: 1px;
  transition: 0.3s;
  align-items: center;
  margin-left: 5px;
}
.acompanhar-chamado-text02:hover {
  color: var(--color-theme-primary);
}
.contedo-contatos-checkbox {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.contedo-contatos-text01 {
  color: #737373;
  font-size: 16px;
  align-self: center;
  margin-top: 2px;
  margin-left: 15px;
}
.contedo-helpdesk-boto {
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  align-items: center;
  padding-top: 0px;
  padding-left: 40px;
  border-radius: 10px;
  padding-right: 40px;
  padding-bottom: 0px;
  justify-content: flex-end;
  background-color: var(--color-theme-primary);
  align-self: flex-end;
}
.contedo-helpdesk-boto :hover {
  cursor: pointer;
}
.contedo-helpdesk-text01 {
  font-size: 15px;
}
.acompanhar-chamado-voltar {
  gap: 5px;
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.acompanhar-chamado-container {
  display: flex;
}
.contedo-integraes-integraes {
  margin: 30px 0;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: fit-content;
  background-color: white;
  display: flex;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}
.contedo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}
.section01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  flex-direction: column;
}
.section-titulo {
  color: #000000;
  font-size: 30px;
  font-family: Fivo Sans Medium;
}
.dashed-line {
  height: 70px; /* Ajuste a altura conforme necessário */
  width: 2px; /* Largura da linha */
  border: 3px dashed var(--color-theme-primary);
}
.subtitulo {
  padding: 10px;
  color: #737373;
  font-size: 18px;
  font-family: Fivo Sans Medium;
}
.select-container {
  height: 40px;
  width: fit-content;
  display: flex;
  align-items: center;
  border-color: var(--color-theme-primary);
  border-width: 1px;
  border-radius: 10px;
  padding: 3px;
}
.date-picker {
  position: bottom;
  z-index: 99999999999999;
}
.input-followup {
  border-bottom: 2px solid var(--color-theme-primary);
}
.timer {
  width: 50px;
  text-align: center;
  background-color: rgba(227, 227, 227, 0.815);
}
.container-template-email {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
}
.template-email {
  border: 1px solid #7f7f7f;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}
.template-email-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.template-email-buttons button span {
  font-size: 18px;
  color: white;
  font-family: Fivo Sans Medium;
}
.button-salvar {
  align-self: flex-end;
  padding: 15px 50px;
  border-radius: 8px;
  background-color: #00a89c;
  color: white;
}
.contedo-leads-list {
  gap: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.contedo-helpdesk-clientlist {
  flex: 0 0 auto;
  min-height: 400px;
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  overflow-x: scroll;
  flex-direction: column;
}
.table {
  width: max-content;
  min-width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}
th,
td {
  border-bottom: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.linha-separada td {
  border-bottom: 1px solid #dddddd;
}
.button-excluir {
  padding: 15px;
  border-radius: 8px;
  background-color: var(--color-theme-primary);
}
@media (max-width: 767px) {
  .button-salvar {
    align-self: center;
    width: 100%;
    padding: 15px;
  }
}
@media (max-width: 650px) {
  .contedo-integraes-header {
    width: 100%;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
}
</style>