<script setup>
import router from "@/router";
import { signOut } from "firebase/auth";
import { auth } from "@/firebase/index";
import { ref, computed } from "vue";
import botaoMenuSuperior from "@/components/dashboard/menu/botao-menu-superior.vue";
import contedoMenuAdmin from "./contedo-menu-admin.vue";
import { useStore } from "vuex";

const store = useStore();
const currentUser = computed(() => {
  return store.state.userModule.currentUser;
});

const logout = () => {
  signOut(auth);
  localStorage.clear();
  router.push("/");
};

const minifiyed = ref(true);

const toggleMenu = () => {
  const menu = document.querySelector(".menu-superior-links");
  if (menu.style.visibility == "hidden") {
    menu.style = "visibility: visible; display: flex";
    minifiyed.value = false;
  } else {
    menu.style = "visibility: hidden; display: none";
    minifiyed.value = true;
  }
};
</script>

<template>
  <div class="menu-superior-menu-superior">
    <div class="menu-superior-fixo">
      <div class="menu-superior-logo">
        <img
          alt="image_alt"
          src="/logo_growth.svg"
          class="menu-superior-image"
        />
      </div>
      <div @click="toggleMenu" class="menu-superior-menu-hambuguer">
        <img
          alt="image_alt1"
          :src="
            minifiyed
              ? 'https://api.iconify.design/ic/round-menu.svg?color=white'
              : 'https://api.iconify.design/ic/close.svg?color=white'
          "
          class="menu-superior-image1"
        />
      </div>
    </div>
    <div class="menu-superior-links" style="visibility: hidden; display: none">
      <botao-menu-superior
      @toggleMenu="toggleMenu"
        rootClassName="botao-menu-root-class-name"
      ></botao-menu-superior>
      <botao-menu-superior
      @toggleMenu="toggleMenu"
        text="Contatos"
        image_src="/colaboradores_icon.svg"
        rootClassName="botao-menu-root-class-name3"
      ></botao-menu-superior>
      <botao-menu-superior
      @toggleMenu="toggleMenu"
        text="Integrações"
        image_src="/integracoes.svg"
        rootClassName="botao-menu-root-class-name2"
      ></botao-menu-superior>
      <botao-menu-superior
      @toggleMenu="toggleMenu"
        text="Helpdesk"
        image_src="/helpdesk.svg"
        rootClassName="botao-menu-root-class-name1"
      ></botao-menu-superior>
      <botao-menu-superior
      @toggleMenu="toggleMenu"
        text="Configurações"
        image_src="/settings.svg"
        rootClassName="botao-menu-root-class-name4"
      ></botao-menu-superior>
      <contedo-menu-admin v-if="currentUser.status == 'ADMIN'" button_class="botao-menu-superior-text"></contedo-menu-admin>
      <div @click="logout" class="menu-superior-dashboard5">
        <img
          alt="image_alt7"
          src="https://api.iconify.design/ci/log-out.svg?color=white"
          class="menu-superior-image7"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.menu-superior-menu-superior {
  width: 100%;
  height: fit-content;
  display: none;
  visibility: hidden;
  position: relative;
  align-self: flex-start;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  align-items: flex-start;
  padding: 30px 15px 15px;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--color-theme-primary);
}
.menu-superior-fixo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.menu-superior-logo {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
}
.menu-superior-image {
  width: fit-content;
  height: auto;
  margin-bottom: 8px;
}
.menu-superior-menu-hambuguer {
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  object-fit: contain;
  align-items: flex-start;
}
.menu-superior-image1 {
  width: 100%;
}
.menu-superior-links {
  gap: 10px;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.menu-superior-links .botao-menu-text {
  display: flex;
}
.menu-superior-dashboard {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.menu-superior-image2 {
  width: 25px;
  object-fit: cover;
}
.menu-superior-dashboard1 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.menu-superior-image3 {
  width: 35px;
  object-fit: cover;
}
.menu-superior-dashboard2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.menu-superior-image4 {
  width: 35px;
  object-fit: cover;
}
.menu-superior-dashboard3 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.menu-superior-image5 {
  width: 35px;
  object-fit: cover;
}
.menu-superior-dashboard4 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.menu-superior-image6 {
  width: 35px;
  object-fit: cover;
}
.menu-superior-dashboard5 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.menu-superior-image7 {
  width: 35px;
  object-fit: cover;
}
@media (max-width: 767px) {
  .menu-superior-menu-superior {
    visibility: visible;
    display: flex;
    align-self: flex-start;
    align-items: center;
  }
  .menu-superior-fixo {
    align-items: center;
  }
  .menu-superior-logo {
    width: auto;
    align-self: center;
    align-items: center;
    padding-top: 0px;
  }
  .menu-superior-image {
    height: 35px;
  }
  .menu-superior-image1 {
    width: 100%;
  }
  .menu-superior-links {
    gap: 10px;
    height: 100%;
    width: 100%;
    margin-top: 50px;
    position: fixed;
    display: flex;
    padding-top: 30px;
    justify-content: flex-start;
    align-items: center;
    z-index: 9000;
    flex-direction: column;
    background-color: var(--color-theme-primary);
    animation-duration: 0.3s;
    animation-name: slide-show;
  }
  .menu-superior-links .botao-menu-text {
    display: flex;
  }
  .menu-superior-dashboard {
    width: auto;
    align-items: center;
    flex-direction: column;
  }
  .menu-superior-image2 {
    width: 30px;
    margin-top: 1px;
  }
  .menu-superior-dashboard1 {
    width: auto;
    align-items: center;
    flex-direction: column;
  }
  .menu-superior-dashboard2 {
    width: auto;
    align-items: center;
    flex-direction: column;
  }
  .menu-superior-dashboard3 {
    width: auto;
    align-items: center;
    flex-direction: column;
  }
  .menu-superior-dashboard4 {
    width: auto;
    position: relative;
    align-items: center;
    flex-direction: column;
  }
  .menu-superior-dashboard5 {
    width: 100%;
    position: relative;
    align-items: flex-start;
    padding: 10px;
    flex-direction: column;
    justify-content: flex-start;
  }
}
@keyframes slide-show {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}
</style>
