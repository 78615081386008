import BaseController from "./BaseController";

export default class CompanyController extends BaseController {
  constructor() {
    super()
  }

  async createCompany(company) {
    await super.create('company', company)
  }

  async getAllCompany() {
    return await super.getAll('company')
  }

  async getCompanyById(id) {
    return await super.getById('company', id)
  }

  async updateCompany(id, company) {
    await super.update('company', id, company)
  }
}
