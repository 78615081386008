import { createStore } from "vuex";
import HelpdeskStore from "./helpdeskStore";
import userStore from "./userStore";
import oportunityStore from "./oportunityStore"
import empresaStore from "./empresaStore";
import cadenceStore from "./cadenceStore";
import leadStore from "./leadStore";
import companyStore from "./companyStore";

const store = createStore({
  state: {
    url: "login",
    dashboardURL: 'dashboard',
    contatos: [],
    popupChamado: false,
    dialog: {
      message: '',
      type: '',
      isVisible: false,
      icon: ''
    },
    popupUser: false,
    popupEmail: false,
    loading: false
  },
  mutations: {
    SET_LOADING(state, payload ) {
      state.loading = payload;
    },
    SET_URL(state, payload) {
      state.url = payload;
    },
    SET_DASHBOARD_URL(state, payload) {
      state.dashboardURL = payload;
    },
    SET_CONTATOS(state, payload) {
      state.contatos = payload;
    },
    SET_POPUP_CHAMADO(state, payload) {
      state.popupChamado = payload;
    },
    SET_DIALOG(state, payload) {
      state.dialog = payload
    },
    SET_POPUP_USER(state, payload) {
      state.popupUser = payload;
    },
    SET_POPUP_EMAIL(state, payload) {
      state.popupEmail = payload;
    }
  },
  actions: {
    setDialog({commit}, payload) {
      commit('SET_DIALOG', { message: payload.message, type: payload.type, isVisible: true, icon: payload.icon})
      setTimeout(() => {
        commit('SET_DIALOG', { message: '', type: '', isVisible: false, icon: ''})
      }, "3000");
    },
  },
  modules: {
    helpdeskModule: HelpdeskStore,
    userModule: userStore,
    oportunityModule: oportunityStore,
    empresaModule: empresaStore,
    cadenceModule: cadenceStore,
    leadModule: leadStore,
    companyModule: companyStore
  }
});

export default store
