import LeadController from "@/controllers/LeadController";

const lController = new LeadController();

const leadStore = {
  namespaced: true,
  state: () => ({
    leads: [],
  }),
  mutations: {
    SET_LEADS(state, payload) {
      state.leads = payload;
    },
  },
  actions: {
    async getAll({ commit, dispatch }) {
      try {
        const leads = await lController.getAllLeads();
        commit("SET_LEADS", leads);
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message: "Erro ao pegar todos os leads",
            icon: "alert-circle-outline",
            type: "danger",
          },
          { root: true }
        );
      }
    },
    async addLeadCadence({ dispatch }, payload) {
      try {
        for (var i = 0; i < payload.leads.length; i++) {
          await lController.addLeadCadence({
            step_actual: 0,
            status: "ativo",
            cadence_id: payload.cadence.cadence_id,
            lead_id: payload.leads[i].id,
          });
        }
        dispatch(
          "setDialog",
          {
            message: "Leads adicionados com sucesso",
            icon: "mail-outline",
            type: "success",
          },
          { root: true }
        );
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message: "Erro ao pegar todos os leads",
            icon: "alert-circle-outline",
            type: "danger",
          },
          { root: true }
        );
      }
    },
  },
};

export default leadStore;
