import BaseController from "./BaseController";

export default class OportunityController extends BaseController {
  constructor() {
    super()
  }

  async createOportunity(oportunity) {
    await super.create('oportunity', oportunity)
  }

  async getAllOportunitys() {
    return await super.getAll('oportunity').then((res) => {
      return res
    })
  }

  async getOportunityById(id) {
    return await super.getById('oportunity', id)
  }

  async getOportunityByEmpresaId(id) {
    return await super.getById('oportunity/empresa', id)
  }

  async updateOportunity(id, Oportunity) {
    await super.update('oportunity', id, Oportunity)
  }
}
