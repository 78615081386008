<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import InputText from "@/components/common/InputText.vue";

const store = useStore();

const email = ref("");

const resetPassword = () => {
  if (email.value != "") {
    try {
      store.dispatch('userModule/updateUserPassword', email.value)
    } catch (err) {
      console.log(err);
    }
    email.value = '';
  } else {
    store.dispatch("setDialog", {
      message:
        "Erro ao enviar link de autenticação, por favor tente novamente mais tarde",
      type: "danger",
      icon: "alert-circle-outline",
    });
  }
};
</script>


<template>
  <div class="recuperar-senha-container" v-bind:class="String">
    <div class="recuperar-senha-logo">
      <img
        alt="image"
        src="https://res.cloudinary.com/dd9puomgu/image/upload/v1695736645/dzhofokf4nj4kbtplv3x.svg"
        class="recuperar-senha-image"
      />
    </div>
    <div class="recuperar-senha-recuperacao">
      <span class="recuperar-senha-text">Recuperação de senha</span>
      <span class="recuperar-senha-text1"
        >Para recuperar seu acesso, por gentileza informe seu endereço de email
        cadastrado na nossa plataforma para enviarmos o link de alteração da
        senha.</span
      >
    </div>
    <div class="recuperar-senha-senha">
      <input-text v-model:value="email" label="E-mail"></input-text>
    </div>
    <div @click="resetPassword" class="recuperar-senha-botao">
      <span class="recuperar-senha-text3">Enviar link</span>
    </div>
    <div class="login-cadastrar" @click="store.commit('SET_URL', 'login')">
      <span class="login-text4"> Voltar para a página de login. </span>
    </div>
  </div>
</template>

<style scoped>
.recuperar-senha-container {
  gap: 20px;
  width: 100%;
  display: flex;
  padding: 40px;
  position: relative;
  max-width: 450px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.recuperar-senha-logo {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.recuperar-senha-image {
  width: 100%;
  object-fit: cover;
  margin-left: -10px;
}
.recuperar-senha-recuperacao {
  gap: 30px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.recuperar-senha-text {
  color: var(--dl-color-gray-black);
  font-size: 30px;
  font-family: Fivo Sans Bold;
}
.recuperar-senha-text1 {
  color: #737373;
  font-family: Fivo Sans Medium;
}
.recuperar-senha-senha {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  flex-direction: column;
}
.recuperar-senha-text2 {
  color: rgb(220, 15, 55);
  font-family: Fivo Sans Bold;
}
.recuperar-senha-textinput {
  width: 100%;
  display: flex;
  align-self: flex-start;
  border-color: #737373;
  justify-content: flex-start;
}
.login-text4 {
  color: #737373;
  font-size: 16px;
  font-family: Fivo Sans Bold;
  margin-right: 10px;
}
.recuperar-senha-botao {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: var(--color-theme-primary);
}
.recuperar-senha-botao:active {
  background-color: #3aaa9b;
}
.recuperar-senha-botao:hover {
  cursor: pointer;
}
.login-cadastrar :hover {
  cursor: pointer;
}
.recuperar-senha-text3 {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  align-self: center;
  text-align: center;
  transition: 0.3s;
  font-family: Fivo Sans Medium;
}
.recuperar-senha-text3:active {
  color: var(--dl-color-gray-white);
}
.recuperar-senha-root-class-name {
  display: none;
}
@media (max-width: 957px) {
  .recuperar-senha-root-class-name {
    display: none;
  }
  .login-cadastrar {
    padding-top: 10px;
  }
}
@media (max-width: 479px) {
  .recuperar-senha-text {
    font-size: 27px;
  }
  .login-cadastrar {
    padding-top: 10px;
  }
}
</style>
