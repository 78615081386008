import { createRouter, createWebHistory } from "vue-router";
import Landing from "../views/public/landing";
import NotFound from "../views/public/not-found";
import GrowthHelpdesk from "../views/admin/growth-helpdesk";
import GrowthIntegraes from "../views/admin/growth-integraes";
import GrowthDashboard2 from "../views/admin/growth-dashboard2";
import GrowthContatos from "../views/admin/growth-contatos";
import GrowthSettings from "../views/admin/growth-settings";
import FlashGrowthLogin from "../views/auth/flash-growth-login";
import FlashGrowthReset from "../views/auth/flash-growth-reset";
import FlashGrowthCadastro from "@/views/auth/flash-growth-cadastro";
import TemplatesFlashvolve from "../views/templates-flashvolve";
import store from "@/store";
import ResetSenha from "@/views/auth/reset-senha.vue";
import AdminOportunity from "@/views/admin/admin-oportunity.vue";
import AdminEmpresas from "@/views/admin/admin-empresas.vue";
import AdminUsuarios from "@/views/admin/admin-usuarios.vue";
import AdminCadencia from "@/views/admin/admin-cadencia.vue";
import AdminHelpdesk from "@/views/admin/admin-helpdesk.vue";

const routes = [
  {
    name: "Landing",
    path: "/landing",
    component: Landing,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "Growth-Integraes",
    path: "/growth-integraes",
    component: GrowthIntegraes,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "Growth-Dashboard2",
    path: "/dashboard/:id",
    component: GrowthDashboard2,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "admin-oportunity",
    path: "/admin/oportunity",
    component: AdminOportunity,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "admin-empresa",
    path: "/admin/empresa",
    component: AdminEmpresas,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "admin-cadencia",
    path: "/admin/cadencia",
    component: AdminCadencia,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "admin-usuario",
    path: "/admin/usuario",
    component: AdminUsuarios,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "admin-helpdesk",
    path: "/admin/helpdesk",
    component: AdminHelpdesk,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "detalhes-contato",
    path: "/dashboard/contatos/:id",
    component: GrowthDashboard2,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "Growth-Contatos",
    path: "/growth-contatos",
    component: GrowthContatos,
    meta: {
      requiresAuth: true,
    },
  },
  /* {
    name: "Landing-Growth",
    path: "/",
    component: LandingGrowth,
    meta: {
      requiresAuth: false,
    },
  }, */
  {
    name: "Growth-Settings",
    path: "/growth-settings",
    component: GrowthSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "Flash-Growth-Login",
    path: "/",
    component: FlashGrowthLogin,
    meta: {
      requiresAuth: false,
      url: "login",
    },
  },
  {
    name: "reset-password",
    path: "/resetPassword",
    component: ResetSenha,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "Flash-Growth-Reset",
    path: "/flash-growth-reset",
    component: FlashGrowthReset,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "Growth-Helpdesk",
    path: "/growth-helpdesk",
    component: GrowthHelpdesk,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "Templates-Flashvolve",
    path: "/templates-flashvolve",
    component: TemplatesFlashvolve,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "404 - Not Found",
    path: "/**",
    component: NotFound,
    fallback: true,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "Flash-Growth-Cadastro",
    path: "/flash-growth-cadastro",
    component: FlashGrowthCadastro,
    meta: {
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  let userId = localStorage.getItem("uid");
  const user = store.state.userModule.currentUser;

  // Verifies if user is authenticated
  if (user === null) {
    await store.dispatch("userModule/getCurrentUser", userId);
    let user_after_autoLogin = store.state.userModule.currentUser;

    if (user_after_autoLogin == null) {
      if (!to.meta.requiresAuth) {
        next();
        return;
      } else {
        next("/");
        return;
      }
    }
  }

  const u = store.state.userModule.currentUser;
  if (u != null) {
    // Verify if user has acccess to route
    if (to.meta.requiresAuth == false) {
      next();
      return;
    } else if (to.meta.requiresAuth == true && (u.status == "ATIVO" || u.status == "ADMIN")) {
      next();
      return;
    } else {
      next("/");
      return;
    }
  }

  next();
});
export default router;
