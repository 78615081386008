<script setup>
import router from "@/router";
import { defineProps } from "vue";

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
  },
  src: {
    type: String,
  },
  class_name: {
    type: String,
    required: true,
  },
});

const goTo = (text) => {
  var route = "";
  switch (text) {
    case "Empresas":
      route = "empresa";
      break;
    case "Oportunitys":
      route = "oportunity";
      break;
    case "Usuários":
      route = "usuario";
      break;
    case "Cadência":
      route = "cadencia";
      break;
    case "Helpdesk":
      route = "helpdesk";
      break;
    default:
      route = "empresa";
      break;
  }
  router.push("/admin/" + route);
};
</script>

<template>
  <div
    id="teste123"
    class="botao-menu-container hover-menu-button"
    v-bind:class="rootClassName"
  >
    <button type="button" class="botao-menu-botao-menu" @click="goTo(text)">
      <ion-icon v-if="icon" class="button-icon" :name="icon"></ion-icon>
      <img v-else :src="src" class="botao-menu-image" />
      <span :class="class_name">{{ text }}</span>
    </button>
  </div>
</template>

<style scoped>
.button-icon {
  font-size: 35px;
  color: white;
}
.botao-menu-container {
  width: 100%;
  border: none;
  display: flex;
  padding: 10px;
  position: relative;
}
#teste123 :hover {
  cursor: pointer;
}
.botao-menu-botao-menu {
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(0, 0, 0, 0);
}
.botao-menu-botao-menu :hover {
  cursor: pointer;
}
.botao-menu-image {
  width: 35px;
  height: 35px;
  object-fit: cover;
}
.botao-menu-text {
  color: rgb(255, 255, 255);
  font-size: 20px;
  align-items: center;
  font-family: Fivo Sans Medium;
  margin-left: 30px;
}
.botao-menu-superior-text {
  color: rgb(255, 255, 255);
  font-size: 20px;
  align-items: center;
  font-family: Fivo Sans Medium;
  margin-left: 30px;
}

@media (max-width: 767px) {
  .botao-menu-text {
    display: none;
  }
}
</style>
