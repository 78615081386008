<script setup>
import MenuLateral from "@/components/dashboard/menu/menu-lateral";
import menuSuperior from "@/components/dashboard/menu/menu-superior.vue";
import toastAlert from "@/components/common/toast-alert.vue";
import listaEmpresas from "@/components/dashboard/admin/empresas/lista-empresas.vue";
import InputText from "@/components/common/InputText.vue";
import Empresa from "@/models/Empresa";
import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";

const store = useStore();
const isVisible = computed(() => {
  return store.state.empresaModule.popupCriarEmpresa;
});
const selectedEmpresa = ref({
  id: null,
  name: "",
  phone: "",
  cnpj: "",
  plan_id: "",
  empresa_plan: null,
});
const selectedPlan = ref({});

const plans = computed(() => {
  return store.state.empresaModule.plans;
});
onMounted(() => {
  store.dispatch("empresaModule/getAllPlans");
});

const salvar = () => {
  if (
    selectedEmpresa.value.id != null &&
    selectedEmpresa.value.id != undefined
  ) {
    const empresa = new Empresa({
      id: selectedEmpresa.value.id,
      name: selectedEmpresa.value.name,
      phone: selectedEmpresa.value.phone,
      cnpj: selectedEmpresa.value.cnpj,
      plan_id: selectedEmpresa.value.plan_id,
      empresa_plan: selectedPlan.value,
    });
    store.dispatch("empresaModule/editEmpresa", {
      empresa: empresa,
      id: empresa.id,
    });
  } else {
    const empresa = new Empresa({
      name: selectedEmpresa.value.name,
      phone: selectedEmpresa.value.phone,
      cnpj: selectedEmpresa.value.cnpj,
      plan_id: selectedPlan.value.id,
      empresa_plan: selectedPlan.value,
    });
    store.dispatch("empresaModule/createEmpresa", empresa);
  }
  clearData();
};
const clearData = () => {
  selectedEmpresa.value = {
    id: null,
    name: "",
    phone: "",
    cnpj: "",
    plan_id: "",
    empresa_plan: null,
  };
  selectedPlan.value = null;
  store.commit("empresaModule/SET_POPUP_CRIAR_EMPRESA", false);
};

function updateEmpresa(value) {
  selectedEmpresa.value = value;
  selectedPlan.value = value.empresa_plan;
}
</script>

<template>
  <div class="growth-dashboard2-container">
    <toast-alert></toast-alert>
    <menu-lateral
      id="menu-lateral"
      rootClassName="menu-lateral-root-class-name6"
    ></menu-lateral>
    <menu-superior id="menu-superior"></menu-superior>
    <div class="content">
      <lista-empresas @update:selectedEmpresa="updateEmpresa"></lista-empresas>
    </div>

    <div class="modal" v-if="isVisible">
      <div class="novo-chamado-pop-upintro">
        <div class="novo-chamado-barra">
          <span class="novo-chamado-novo-chamado">EMPRESA</span>
          <button @click="clearData">
            <img
              alt="image"
              src="https://api.iconify.design/icon-park-outline/close-one.svg?color=white"
              class="novo-chamado-image"
            />
          </button>
        </div>
        <div class="novo-chamado-entrada">
          <div class="contedo-settings-box">
            <div class="contedo-settings-container02">
              <InputText
                label="Nome"
                v-model:value="selectedEmpresa.name"
              ></InputText>
              <InputText
                label="Telefone"
                v-model:value="selectedEmpresa.phone"
              ></InputText>
              <InputText
                label="CNPJ"
                v-model:value="selectedEmpresa.cnpj"
              ></InputText>
              <span class="contedo-settings-text1">Escolha o plano: </span>
              <div class="select-container">
                <select id="opcoes" v-model="selectedPlan">
                  <option v-for="plan in plans" :key="plan.id" :value="plan">
                    {{ plan.name }}
                  </option>
                </select>
              </div>
              <div class="contedo-settings-button">
                <div class="contedo-settings-buttonset">
                  <div @click="salvar" class="contedo-settings-buttonaction">
                    <span class="contedo-settings-text12">{{
                      selectedEmpresa.id != null &&
                      selectedEmpresa.id != undefined
                        ? "Salvar alterações"
                        : "Criar empresa"
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.growth-dashboard2-container {
  width: 100%;
  display: flex;
  max-height: 100vh;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5;
}
#menu-lateral {
  gap: 30px;
  height: 100%;
  width: 300px;
  min-width: 300px;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  padding-left: 10px;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--color-theme-primary);
  box-sizing: border-box;
  transition: all ease 0.3s;
}
.modal {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 900;
}
.novo-chamado-pop-upintro {
  width: 100%;
  height: 650px;
  margin: 0px;
  display: flex;
  position: relative;
  max-width: 800px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  max-height: 600px;
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.novo-chamado-barra {
  flex: 0 0 auto;
  width: 100%;
  height: 40px;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: center;
  background-color: var(--color-theme-primary);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.novo-chamado-novo-chamado {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  text-align: center;
  font-family: "Fivo Sans Medium";
}
.novo-chamado-image {
  top: 8px;
  right: 0px;
  width: 25px;
  position: absolute;
  justify-self: flex-end;
  margin-right: 10px;
}
.novo-chamado-entrada {
  flex: 0 0 auto;
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  flex-direction: column;
  padding-bottom: 30px;
  justify-content: flex-start;
}
.contedo-settings-box {
  gap: 10px;
  width: 100%;
  height: 80%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.contedo-settings-container02 {
  gap: 10px;
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-settings-text1 {
  color: #737373;
  font-size: 18px;
  text-align: flex-start;
  font-family: Fivo Sans Medium;
}
.select-container {
  height: 40px;
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-color: var(--color-theme-primary);
  border-width: 2px;
  border-radius: 10px;
}
.select-container select {
  width: 100%;
  padding: 3px;
}
.contedo-settings-button {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
}
.contedo-settings-buttonset {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.contedo-settings-buttonaction {
  flex: 0 0 auto;
  width: 70%;
  height: 50px;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: var(--color-theme-primary);
}
.contedo-settings-buttonaction :hover {
  cursor: pointer;
}
.contedo-settings-text12 {
  color: #ffffff;
  font-size: 18px;
  align-self: center;
  text-align: center;
  font-family: Fivo Sans Medium;
}
@media (max-width: 1440px) {
  .contedo-settings-box {
    position: relative;
  }
}
@media (max-width: 1040px) {
  .contedo-settings-box {
    width: 80%;
  }
}
@media (max-width: 957px) {
  .contedo-settings-box {
    width: 100%;
  }
  .novo-chamado-pop-upintro {
    margin: 0px;
    display: flex;
  }
}
@media (max-width: 767px) {
  .growth-dashboard2-container {
    flex-direction: column;
  }
  #menu-lateral {
    visibility: hidden;
    display: none;
  }
}
@media (max-width: 479px) {
  .novo-chamado-pop-upintro {
    display: flex;
    max-height: 800px;
  }
  .novo-chamado-entrada {
    padding: 20px;
  }
  .contedo-settings-button {
    width: 100%;
  }
  .contedo-settings-buttonset {
    width: 100%;
  }
  .contedo-settings-buttonaction {
    width: 100%;
  }
}
</style>
