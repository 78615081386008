<script setup>
import { defineProps, defineEmits, inject } from "vue";
import statusBadge from "@/components/common/status-badge.vue";
import moment from "moment";

const primaryColor = inject('primaryColor').replace('#', '')
const emit = defineEmits(["functionContato", "functionCheck"]);
const props = defineProps({
  contato: {
    type: Object,
    required: true,
  },
  checked: {
    type: Boolean,
    required: true,
  },
});

const emitCheck = () => {
  emit("functionCheck");
};
const emitContato = () => {
  emit("functionContato");
};

const getName = (string) => {
  let s = string.split(" ");
  return s[0] + " " + s[s.length - 1];
};
</script>

<template>
  <div class="clientvaluemobile-clientvalue">
    <div class="clientvaluemobile-cliente">
      <div class="clientvaluemobile-user">
        <div @click="emitContato" class="clientvaluemobile-top">
          <!-- <div class="clientvaluemobile-foto">
            <img
              alt="image_alt92"
              src="https://play.teleporthq.io/static/svg/default-img.svg"
              class="clientvaluemobile-image"
            />
          </div> -->
          <div class="clientvaluemobile-user1">
            <div class="clientvaluemobile-name">
              <span class="clientvaluemobile-text clientdisplayname">
                {{ getName(props.contato.nome) }}
              </span>
            </div>
            <div class="clientvaluemobile-acesso">
              <!-- <span class="clientdisplayacess"
                >Último acesso: {{ props.contato.horario }}</span
              > -->
            </div>
            <div
              v-if="props.contato.score != null"
              class="clientvaluemobile-stars"
            >
              <div v-for="i in props.contato.score" :key="i">
                <img
                  alt="image_alt42"
                  :src="`https://api.iconify.design/fluent/star-12-filled.svg?color=%23${primaryColor}`"
                  class="clientvaluemobile-image1"
                />
              </div>
              <div v-for="i in 5 - props.contato.score" :key="i">
                <img
                  alt="image_alt43"
                  src="https://api.iconify.design/fluent/star-12-filled.svg?color=%23d3d3d3"
                  class="clientvaluemobile-image5"
                />
              </div>
            </div>
          </div>
        </div>
        <button @click="emitCheck">
          <input
            :checked="checked"
            type="checkbox"
            class="contedo-contatos-checkbox03"
          />
        </button>
      </div>
    </div>
    <div class="click-contato" @click="emitContato">
      <div class="clientvaluemobile-contato">
        <div class="clientvaluemobile-label">
          <span class="clientvaluemobile-text6">Contato</span>
        </div>
        <div class="clientvaluemobile-infos">
          <div class="clientvaluemobile-container">
            <div class="clientvaluemobile-email">
              <span class="clientvaluemobile-text3">{{
                props.contato.email
              }}</span>
            </div>
            <div class="clientvaluemobile-telefone">
              <span class="clientvaluemobile-text4">{{
                props.contato.telefone
              }}</span>
            </div>
            <div class="clientvaluemobile-email1">
              <!-- <span class="clientorigin">Origem: {{ props.contato.origem }}</span> -->
            </div>
          </div>
        </div>
      </div>
      <div class="clientvaluemobile-contato1">
        <!-- <div class="clientvaluemobile-label1">
          <span class="clientvaluemobile-text6 clientlabel">Reunião</span>
        </div> -->
        <div class="clientvaluemobile-agendamento">
          <div class="clientvaluemobile-container1">
            <div class="clientvaluemobile-email2">
              <span class="clientmeeting">{{
                `${moment(props.contato.created_date)
                  .locale("pt")
                  .format("dddd")}, ${moment(props.contato.created_date)
                  .locale("pt")
                  .format("LL")}`
              }}</span>
            </div>
            <div class="clientvaluemobile-telefone1">
              <div class="clientvaluemobile-status">
                <status-badge :status="props.contato.status"></status-badge>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.clientvaluemobile-clientvalue {
  gap: 10px;
  width: 32%;
  height: auto;
  min-height: 180px;
  max-height: 180px;
  display: flex;
  padding: 20px;
  position: relative;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.clientvaluemobile-cliente {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
}
.clientvaluemobile-user {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
}
.clientvaluemobile-top {
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.clientvaluemobile-top:hover {
  cursor: pointer;
}
.clientvaluemobile-foto {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
}
.clientvaluemobile-image {
  width: 70px;
  object-fit: cover;
  border-radius: 100%;
}
.clientvaluemobile-user1 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  flex-direction: column;
}
.clientvaluemobile-name {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 0px;
  flex-direction: column;
}
.clientvaluemobile-text {
  color: var(--color-theme-primary);
  font-family: "Fivo Sans regular";
  font-weight: 600;
}
.clientvaluemobile-acesso {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 0px;
  flex-direction: row;
}
.clientvaluemobile-stars {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  padding-left: 0px;
  flex-direction: row;
  justify-content: flex-end;
}
.clientvaluemobile-image1 {
  width: 14px;
  object-fit: cover;
}
.clientvaluemobile-image2 {
  width: 14px;
  object-fit: cover;
}
.clientvaluemobile-image3 {
  width: 14px;
  object-fit: cover;
}
.clientvaluemobile-image4 {
  width: 14px;
  object-fit: cover;
}
.clientvaluemobile-image5 {
  width: 14px;
  object-fit: cover;
}
.clientvaluemobile-contato {
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: row;
  justify-content: space-between;
}
.click-contato:hover {
  cursor: pointer;
}
.clientvaluemobile-label {
  flex: 0 0 auto;
  width: 70px;
  display: flex;
  align-items: flex-start;
  padding-right: 10px;
  justify-content: flex-start;
  padding-left: 10px;
}
.clientvaluemobile-infos {
  gap: 5px;
  width: calc(100% - 70px);
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  flex-direction: row;
  justify-content: space-between;
}
.clientvaluemobile-container {
  gap: 2px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}
.clientvaluemobile-email {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.clientvaluemobile-text3 {
  color: #737373;
  font-size: 12px;
  font-family: "Fivo Sans regular";
  font-weight: 700;
}
.clientvaluemobile-telefone {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.clientvaluemobile-text4 {
  font-size: 12px;
  font-family: "Russo One";
}
.clientvaluemobile-email1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.clientvaluemobile-contato1 {
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: row;
  justify-content: space-between;
}
.clientvaluemobile-label1 {
  flex: 0 0 auto;
  width: 70px;
  display: flex;
  align-items: flex-start;
  padding-right: 10px;
  justify-content: flex-end;
}
.clientvaluemobile-text6 {
  color: var(--color-theme-primary);
  font-size: 12px;
  font-family: "Fivo Sans regular";
  font-weight: 700;
}
.clientvaluemobile-agendamento {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 10px;
  flex-direction: row;
  justify-content: space-between;
}
.clientvaluemobile-container1 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}
.clientvaluemobile-email2 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.clientvaluemobile-telefone1 {
  gap: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.clientvaluemobile-status {
  width: 80%;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
}
@media (max-width: 1388px) {
  .clientvaluemobile-clientvalue {
    width: 48%;
    min-height: 220px;
    max-height: 220px;
  }
}
@media (max-width: 980px) {
  .clientvaluemobile-clientvalue {
    width: 100%;
  }
  .clientvaluemobile-telefone1 {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .clientvaluemobile-user {
    align-items: center;
  }
  .clientvaluemobile-cliente {
    flex-wrap: wrap;
  }
  .clientvaluemobile-contato1 {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .clientvaluemobile-top {
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .clientvaluemobile-foto {
    width: 70px;
  }
  .clientvaluemobile-user1 {
    padding: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .clientvaluemobile-stars {
    align-self: flex-start;
    margin-top: 0px;
    align-items: center;
    margin-bottom: 0px;
  }
  .clientvaluemobile-contato {
    width: auto;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding-left: 33px;
  }
  .clientvaluemobile-label {
    width: 70px;
    height: 100%;
    margin-bottom: 0px;
    justify-content: flex-start;
    padding: 0;
  }
  .clientvaluemobile-infos {
    width: 100%;
    justify-content: flex-start;
    padding: 0;
  }
  .clientvaluemobile-text4 {
    font-family: Russo One;
    padding-left: 0px;
  }
  .clientvaluemobile-email1 {
    justify-content: flex-start;
  }
  .clientvaluemobile-contato1 {
    width: auto;
    padding-left: 33px;
    justify-content: flex-start;
  }
  .clientvaluemobile-label1 {
    width: 70px;
    height: 100%;
    margin-bottom: 0px;
    justify-content: flex-start;
  }
  .clientvaluemobile-agendamento {
    width: 100%;
    justify-content: flex-start;
    padding: 0;
  }
  .clientvaluemobile-text6 {
    text-align: left;
    align-self: flex-start;
  }
  .clientvaluemobile-image {
    margin-left: -10px;
    margin-bottom: 20px;
  }
  .contedo-contatos-checkbox03 {
    margin-top: 25px;
  }
  .clientvaluemobile-status {
    justify-content: flex-start;
  }
  .clientvaluemobile-telefone1 {
    justify-content: flex-start;
  }
}
</style>
