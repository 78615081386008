<script setup>
import { defineProps, inject } from "vue";
import { useStore } from "vuex";

const primaryColor = inject('primaryColor').replace('#', '')
const store = useStore();

const props = defineProps({
  empresa: {
    type: Object,
    required: true,
  },
});

function getPhone(tel) {
  if (tel.length >= 8) {
    const ddd = tel.slice(0, 2);

    const numeroRestante = tel.slice(2);

    const telefoneFormatado = `(${ddd}) ${numeroRestante.slice(
      0,
      4
    )}-${numeroRestante.slice(4)}`;

    return telefoneFormatado;
  } else {
    return tel;
  }
}

const setPopupEmpresa = () => {
  store.commit('empresaModule/SET_POPUP_EMPRESA', true)
}
</script>

<template>
  <div class="clientvaluemobile-clientvalue">
    <div class="clientvaluemobile-cliente">
      <div class="clientvaluemobile-user">
        <div class="clientvaluemobile-top">
          <!-- ADICIONAR AQUI O EDIT DA EMPRESA -->
          <div class="clientvaluemobile-user1">
            <div class="clientvaluemobile-name">
              <span class="clientvaluemobile-text clientdisplayname">
                {{ empresa.name }}
              </span>
            </div>
          </div>
          <img
          @click="setPopupEmpresa"
            alt="image"
            :src="`https://api.iconify.design/tabler/edit.svg?color=%23${primaryColor}&width=30`"
            class="boto-image"
          />
        </div>
      </div>
    </div>
    <div class="click-contato">
      <div class="clientvaluemobile-contato">
        <div class="clientvaluemobile-label">
          <span class="clientvaluemobile-text6">Contato</span>
        </div>
        <div class="clientvaluemobile-infos">
          <div class="clientvaluemobile-container">
            <div class="clientvaluemobile-telefone">
              <span class="clientvaluemobile-text4">{{
                getPhone(empresa.phone)
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="clientvaluemobile-contato">
        <div class="clientvaluemobile-label">
          <span class="clientvaluemobile-text6">CNPJ</span>
        </div>
        <div class="clientvaluemobile-infos">
          <div class="clientvaluemobile-container">
            <div class="clientvaluemobile-telefone">
              <span class="clientvaluemobile-text4">{{ empresa.cnpj }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.clientvaluemobile-clientvalue {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 20px;
  position: relative;
  border-radius: 10px;
  border-color: var(--color-theme-primary);
  border-width: 1px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.clientvaluemobile-cliente {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
}
.clientvaluemobile-user {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
}
.clientvaluemobile-top {
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.clientvaluemobile-top:hover {
  cursor: pointer;
}
.clientvaluemobile-user1 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  flex-direction: column;
}
.clientvaluemobile-name {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 0px;
  flex-direction: column;
}
.clientvaluemobile-text {
  color: var(--color-theme-primary);
  font-family: "Fivo Sans regular";
  font-weight: 600;
}
.clientvaluemobile-contato {
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: row;
  justify-content: space-between;
}
.click-contato:hover {
  cursor: pointer;
}
.clientvaluemobile-label {
  flex: 0 0 auto;
  width: 70px;
  display: flex;
  align-items: flex-start;
  padding-right: 10px;
  justify-content: flex-start;
  padding-left: 10px;
}
.clientvaluemobile-infos {
  gap: 5px;
  width: calc(100% - 70px);
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  flex-direction: row;
  justify-content: space-between;
}
.clientvaluemobile-container {
  gap: 2px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}
.clientvaluemobile-telefone {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.clientvaluemobile-text4 {
  color: #737373;
  font-size: 12px;
  font-family: "Russo One";
}
.clientvaluemobile-contato1 {
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: row;
  justify-content: space-between;
}
.clientvaluemobile-text6 {
  color: var(--color-theme-primary);
  font-size: 12px;
  font-family: "Fivo Sans regular";
  font-weight: 700;
}
.clientvaluemobile-agendamento {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 10px;
  flex-direction: row;
  justify-content: space-between;
}
.clientvaluemobile-container1 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}
.clientvaluemobile-email2 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
@media (max-width: 479px) {
  .clientvaluemobile-user {
    align-items: center;
  }
  .clientvaluemobile-cliente {
    flex-wrap: wrap;
  }
  .clientvaluemobile-contato1 {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .clientvaluemobile-top {
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .clientvaluemobile-user1 {
    padding: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .clientvaluemobile-contato {
    width: auto;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding-left: 33px;
  }
  .clientvaluemobile-label {
    width: 70px;
    height: 100%;
    margin-bottom: 0px;
    justify-content: flex-start;
    padding: 0;
  }
  .clientvaluemobile-infos {
    width: 100%;
    justify-content: flex-start;
    padding: 0;
  }
  .clientvaluemobile-text4 {
    font-family: Russo One;
    padding-left: 0px;
  }
  .clientvaluemobile-contato1 {
    width: auto;
    padding-left: 33px;
    justify-content: flex-start;
  }
  .clientvaluemobile-agendamento {
    width: 100%;
    justify-content: flex-start;
    padding: 0;
  }
  .clientvaluemobile-text6 {
    text-align: left;
    align-self: flex-start;
  }
}
</style>
