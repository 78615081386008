import EmpresaController from "@/controllers/EmpresaController";
import Empresa from "@/models/Empresa";
import EmpresaPlan from "@/models/EmpresaPlan";

const empController = new EmpresaController();

const empresaStore = {
  namespaced: true,
  state: () => ({
    empresas: [],
    selectedEmpresa: null,
    currentUserEmpresa: {},
    popupEmpresa: false,
    plans: null,
    popupCriarEmpresa: false
  }),
  mutations: {
    SET_EMPRESAS(state, payload) {
      state.empresas = payload;
    },
    SET_PLANS(state, payload) {
      state.plans = payload;
    },
    SET_SELECTED_EMPRESA(state, payload) {
      state.selectedEmpresa = payload;
    },
    SET_CURRENT_USER_EMPRESA(state, payload) {
      state.currentUserEmpresa = payload;
    },
    SET_POPUP_EMPRESA(state, payload) {
      state.popupEmpresa = payload;
    },
    SET_POPUP_CRIAR_EMPRESA(state, payload) {
      state.popupCriarEmpresa = payload;
    },
  },
  actions: {
    async editEmpresa({ commit, dispatch }, payload) {
      try {
        await empController.updateEmpresa(payload.id, payload.empresa);
        dispatch("getCurrentUserEmpresa", payload.id);
        dispatch(
          "setDialog",
          {
            message: "Os dados da empresa foram atualizados com sucesso!",
            type: "success",
            icon: "checkmark-circle-outline",
          },
          { root: true }
        );
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message:
              "Não foi possível alterar os dados da empresa, por favor tente novamente mais tarde",
            type: "danger",
            icon: "alert-circle-outline",
          },
          { root: true }
        );
      } finally {
        commit("SET_POPUP_EMPRESA", false);
      }
    },
    async createEmpresa({ dispatch }, payload) {
      try {
        await empController.createEmpresa(payload);
        dispatch(
          "setDialog",
          {
            message:
              "Empresa criada com sucesso!",
            type: "success",
            icon: "business-outline",
          },
          { root: true }
        );
        dispatch('getAllEmpresas')
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message:
              "Não foi possível criar uma nova empresa, por favor tente novamente mais tarde",
            type: "danger",
            icon: "alert-circle-outline",
          },
          { root: true }
        );
      }
    },
    async getAllEmpresas({ commit }) {
      try {
        const emps = await empController.getAllEmpresas();
        let finalEmps = emps.data.map((e) => {
          return new Empresa(e);
        });
        commit("SET_EMPRESAS", finalEmps);
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message:
              "Naõ foi possível pegar os dados de empresas, por favor tente novamente mais tarde",
            type: "danger",
            icon: "alert-circle-outline",
          },
          { root: true }
        );
      }
    },
    async getEmpresaById({ commit }, payload) {
      try {
        const emps = await empController.getEmpresaById(payload);
        let finalEmps = new Empresa(emps.data);
        commit("SET_SELECTED_EMPRESA", finalEmps);
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message:
              "Naõ foi possível pegar os dados de empresas, por favor tente novamente mais tarde",
            type: "danger",
            icon: "alert-circle-outline",
          },
          { root: true }
        );
      }
    },
    async getCurrentUserEmpresa({ dispatch, commit }, payload) {
      try {
        const emps = await empController.getEmpresaById(payload);
        let finalEmps = new Empresa(emps.data);
        commit("SET_CURRENT_USER_EMPRESA", finalEmps);
        dispatch("oportunityModule/getOportunitysByEmpresaId", finalEmps.id, {
          root: true,
        });
        dispatch("helpdeskModule/getByEmpresa", finalEmps.id, {
          root: true,
        });
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message:
              "Naõ foi possível pegar os dados de empresas, por favor tente novamente mais tarde",
            type: "danger",
            icon: "alert-circle-outline",
          },
          { root: true }
        );
      }
    },
    async getAllPlans({ dispatch, commit }) {
      try {
        const plans = await empController.getAllPlans();
        let finalPlans = plans.data.map((plan) => new EmpresaPlan(plan));
        commit('SET_PLANS', finalPlans)
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message:
              "Naõ foi possível pegar os dados de planos, por favor tente novamente mais tarde",
            type: "danger",
            icon: "alert-circle-outline",
          },
          { root: true }
        );
      }
    },
  },
};

export default empresaStore;
