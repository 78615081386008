import BaseController from "./BaseController";

export default class EmpresaController extends BaseController {
  constructor() {
    super()
  }

  async createEmpresa(empresa) {
    await super.create('empresas', empresa)
  }

  async getAllEmpresas() {
    return await super.getAll('empresas')
  }

  async getEmpresaById(id) {
    return await super.getById('empresas', id)
  }

  async updateEmpresa(id, empresa) {
    await super.update('empresas', id, empresa)
  }

  async getAllPlans() {
    return await super.getAll('plan')
  }
}
