export default class Company {
  constructor({ id, name, country, industry, createdAt, updatedAt } = {}) {
    this.id = id ?? null;
    this.name = name ?? "";
    this.country = country ?? "";
    this.industry = industry ?? "";
    this.updatedAt = updatedAt ?? null;
    this.createdAt = createdAt ?? "";
  }
}
