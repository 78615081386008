<script setup>
import { defineProps, defineEmits } from "vue";
import statusBadge from "@/components/common/status-badge.vue";
import moment from "moment";

const emit = defineEmits(['update:getById', 'update:confirmeDelete'])

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const getById = (() => {
  emit('update:getById')
})

const openPopup = (() => {
  emit('update:confirmeDelete')
})
</script>

<template>
  <div class="ticket-support-ticket-support">
    <div @click="getById" class="ticket-cadencia-conteudo">
      <div class="ticket-support-numero-hamado">
        <span class="ticket-support-labelchamado">Nome da cadência</span>
        <span class="Clientname ticket-support-text">{{ item.name }}</span>
      </div>
      <div class="ticket-support-data-abertura">
        <span class="ticket-support-label">Início da cadência</span>
        <span class="reuniao ticket-support-text1">{{
          moment(item.date_start).locale("pt").format("LLLL")
        }}</span>
      </div>
      <div class="ticket-support-motivo">
        <span class="ticket-support-labelmotivo">Nº de templates</span>
        <span class="ticket-support-text2 Clientemail">{{
          item.step.length
        }}</span>
      </div>
      <div class="ticket-support-status">
        <span class="ticket-support-label1">Status</span>
        <div class="ticket-support-statusset">
          <status-badge :status="item.status"></status-badge>
        </div>
      </div>
    </div>
      <button class="delete-button" @click="openPopup">
        <ion-icon name="trash-outline" class="button-icon"></ion-icon>
      </button>
  </div>
</template>

<style scoped>
.ticket-support-ticket-support {
  gap: 10px;
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: row;
  position: relative;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  padding-top: 20px;
  padding-left: 40px;
  border-radius: 15px;
  padding-right: 40px;
  flex-direction: row;
  padding-bottom: 20px;
  background-color: var(--dl-color-gray-white);
}
.ticket-cadencia-conteudo {
  width: 95%;
  display: flex;
  gap: 1px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.delete-button {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: #dc0f37;
  border-radius: 8px;
}
.button-icon {
  color: white;
  font-size: 25px;
  padding: 10px 5px 5px 5px;
}
.ticket-support-numero-hamado {
  gap: 5px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.ticket-support-labelchamado {
  color: #737373;
  font-size: 12px;
  font-family: "Fivo Sans regular";
}
.ticket-support-data-abertura {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  padding-left: 0px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
}
.ticket-support-label {
  color: #737373;
  font-size: 12px;
  text-align: left;
  font-family: "Fivo Sans regular";
}
.ticket-support-motivo {
  gap: 5px;
  width: 15%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.ticket-support-labelmotivo {
  color: #737373;
  font-size: 12px;
  text-align: left;
  font-family: "Fivo Sans regular";
}
.ticket-support-text2 {
  padding-left: 0px;
}
.ticket-support-status {
  width: 30%;
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
}
.ticket-support-label1 {
  color: #737373;
  font-size: 12px;
  text-align: left;
  font-family: "Fivo Sans regular";
}
.ticket-support-statusset {
  flex: 0 0 auto;
  width: fit-content;
  height: 25px;
  display: flex;
  min-width: 100px;
  align-items: center;
  border-radius: 25px;
  background-color: 0DC5FF;
}
.ticket-support-text3 {
  padding-left: 10px;
  padding-right: 10px;
}
@media (max-width: 1440px) {
  .ticket-support-text1 {
    padding-left: 0px;
  }
  .ticket-support-status {
    align-items: flex-start;
  }
}
@media (max-width: 767px) {
  .ticket-support-data-abertura {
    width: auto;
  }
  .ticket-support-motivo {
    width: auto;
  }
  .ticket-support-status {
    width: auto;
  }
}
@media (max-width: 479px) {
  .ticket-support-ticket-support {
    gap: 10px;
    padding: 15px;
    align-items: flex-start;
    flex-direction: column;
  }
  .ticket-support-statusset {
    height: auto;
  }
}
@media (max-width: 320px) {
  .ticket-support-ticket-support {
    gap: 10px;
    align-items: flex-start;
    flex-direction: column;
  }
  .ticket-support-numero-hamado {
    width: 100%;
    padding: unset;
  }
  .ticket-support-text {
    font-size: 17px;
  }
  .ticket-support-data-abertura {
    width: 100%;
  }
  .ticket-support-motivo {
    width: 100%;
  }
  .ticket-support-status {
    width: 100%;
  }
}
</style>
